import { generateCreateActionCreator, generateDeleteActionCreator, generateReadActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from 'actions/crudActionCreators';
import routes from 'actions/routes';
import ACTION_NAMES from 'actions/ACTION_NAMES';

const getRecordings = generateReadActionCreator(routes.API_PBX_CONFIG_RECORDINGS, ACTION_NAMES.PBX_CONFIG_RECORDINGS);
const updateRecordings = generateUpdateActionCreator(routes.API_PBX_CONFIG_RECORDINGS, ACTION_NAMES.PBX_CONFIG_RECORDINGS);

const getDestinationGroups = generateReadActionCreator(routes.API_PBX_CONFIG_DESTINATION_GROUPS, ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS);
const getDestinationGroup = generateReadOneActionCreator(routes.API_PBX_CONFIG_DESTINATION_GROUP, ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP);
const addDestinationGroups = generateCreateActionCreator(routes.API_PBX_CONFIG_DESTINATION_GROUPS, ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS);
const editDestinationGroup = generateUpdateActionCreator(routes.API_PBX_CONFIG_DESTINATION_GROUP, ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP);
const deleteDestinationGroup = generateDeleteActionCreator(routes.API_PBX_CONFIG_DESTINATION_GROUP, ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP);
const viewDestinations = generateReadActionCreator(routes.API_PBX_CONFIG_VIEW_DESTINATIONS, ACTION_NAMES.PBX_CONFIG_VIEW_DESTINATIONS);
const setPinned = generateReadActionCreator(routes.API_PBX_CONFIG_SET_PINNED, ACTION_NAMES.PBX_CONFIG_SET_PINNED);

const getDylNumbers = generateReadActionCreator(routes.API_PBX_CONFIG_DYLNUMBERS, ACTION_NAMES.PBX_CONFIG_DYLNUMBERS);
const getDylNumber = generateReadOneActionCreator(routes.API_PBX_CONFIG_DYLNUMBERS, ACTION_NAMES.PBX_CONFIG_DYLNUMBERS);
const updateDylNumber = generateUpdateActionCreator(routes.API_PBX_CONFIG_DYLNUMBERS, ACTION_NAMES.PBX_CONFIG_DYLNUMBERS);
const getListOfExtensions = generateReadActionCreator(routes.API_PBX_CONFIG_LIST_EXTENSIONS, ACTION_NAMES.PBX_CONFIG_LIST_EXTENSIONS);
const getUnusedExtensions = generateReadActionCreator(routes.API_PBX_CONFIG_UNUSED_EXTENSION, ACTION_NAMES.PBX_CONFIG_UNUSED_EXTENSIONS);
const getUsersWithExtensions = generateReadActionCreator(routes.API_PBX_CONFIG_WITH_EXTENSION, ACTION_NAMES.PBX_CONFIG_WITH_EXTENSIONS);

const getRoutingRules = generateReadActionCreator(routes.API_PBX_CONFIG_ROUTING_RULES, ACTION_NAMES.PBX_CONFIG_ROUTING_RULES);
const getRoutingRule = generateReadOneActionCreator(routes.API_PBX_CONFIG_ROUTING_RULE, ACTION_NAMES.PBX_CONFIG_ROUTING_RULES);
const updateRoutingRule = generateUpdateActionCreator(routes.API_PBX_CONFIG_ROUTING_RULE, ACTION_NAMES.PBX_CONFIG_ROUTING_RULES);
const deleteRoutingRule = generateDeleteActionCreator(routes.API_PBX_CONFIG_ROUTING_RULE, ACTION_NAMES.PBX_CONFIG_ROUTING_RULES);
const createRoutingRules = generateCreateActionCreator(routes.API_PBX_CONFIG_ROUTING_RULES, ACTION_NAMES.PBX_CONFIG_ROUTING_RULES);

const createCompanyVoicemails = generateCreateActionCreator(routes.API_PBX_CONFIG_VOICEMAIL_BOX, ACTION_NAMES.PBX_CONFIG_COMPANY_VOICEMAIL);
const getCompanyVoicemail = generateReadOneActionCreator(routes.API_PBX_CONFIG_VOICEMAIL_BOX, ACTION_NAMES.PBX_CONFIG_COMPANY_VOICEMAIL);
const updateCompanyVoicemail = generateUpdateActionCreator(routes.API_PBX_CONFIG_VOICEMAIL_BOX, ACTION_NAMES.PBX_CONFIG_COMPANY_VOICEMAIL);
const deleteCompanyVoicemail = generateDeleteActionCreator(routes.API_PBX_CONFIG_VOICEMAIL_BOX, ACTION_NAMES.PBX_CONFIG_COMPANY_VOICEMAIL);

const getConferenceRoom = generateReadOneActionCreator(routes.API_PBX_CONFIG_CONFERENCE_ROOM, ACTION_NAMES.PBX_CONFIG_CONFERENCE_ROOM); 
const updateConferenceRoom = generateUpdateActionCreator(routes.API_PBX_CONFIG_CONFERENCE_ROOM, ACTION_NAMES.PBX_CONFIG_CONFERENCE_ROOM);
const createConferenceRoom = generateCreateActionCreator(routes.API_PBX_CONFIG_CONFERENCE_ROOM, ACTION_NAMES.PBX_CONFIG_CONFERENCE_ROOM);
const deleteConferenceRoom = generateDeleteActionCreator(routes.API_PBX_CONFIG_CONFERENCE_ROOM, ACTION_NAMES.PBX_CONFIG_CONFERENCE_ROOM);

const createParkingLot = generateCreateActionCreator(routes.API_PBX_CONFIG_PARK, ACTION_NAMES.PBX_CONFIG_PARK);
const updateParkingLot = generateUpdateActionCreator(routes.API_PBX_CONFIG_PARK, ACTION_NAMES.PBX_CONFIG_PARK);
const getParkingLot = generateReadOneActionCreator(routes.API_PBX_CONFIG_PARK, ACTION_NAMES.PBX_CONFIG_PARK);
const deleteParkingLot = generateDeleteActionCreator(routes.API_PBX_CONFIG_PARK, ACTION_NAMES.PBX_CONFIG_PARK);

const getDestinations = generateReadActionCreator(routes.API_PBX_CONFIG_DESTINATIONS, ACTION_NAMES.PBX_CONFIG_DESTINATIONS);
const getPreferences = generateReadActionCreator(routes.API_PBX_CONFIG_PREFERENCES, ACTION_NAMES.PBX_CONFIG_PREFERENCES);
const updatePreferences = generateUpdateActionCreator(routes.API_PBX_CONFIG_PREFERENCES, ACTION_NAMES.PBX_CONFIG_PREFERENCES);
const readCompanyExtensions = generateReadActionCreator(routes.API_PBX_CONFIG_COMPANY_EXTENSION, ACTION_NAMES.PBX_CONFIG_COMPANY_EXTENSION);
const listPhones = generateReadActionCreator(routes.API_PBX_LIST_PHONES, ACTION_NAMES.PBX_LIST_PHONES);
const isDuplicatedName = generateReadActionCreator(routes.API_PBX_CONFIG_UNIQUE_NAME, ACTION_NAMES.PBX_CONFIG_UNIQUE_NAME);
const unusedExtensions = generateReadActionCreator(routes.API_PBX_CONFIG_UNUSED_EXTENSIONS, ACTION_NAMES.PBX_CONFIG_UNUSED_EXTENSIONS);

const getSounds = generateReadActionCreator(routes.API_PBX_CONFIG_SOUND, ACTION_NAMES.PBX_CONFIG_SOUNDS); 
const getSound = generateReadOneActionCreator(routes.API_PBX_CONFIG_SOUND, ACTION_NAMES.PBX_CONFIG_SOUND); 
const createSound = generateCreateActionCreator(routes.API_PBX_CONFIG_SOUND, ACTION_NAMES.PBX_CONFIG_SOUND);
const updateSound = generateUpdateActionCreator(routes.API_PBX_CONFIG_SOUND, ACTION_NAMES.PBX_CONFIG_SOUND);
const deleteSound = generateDeleteActionCreator(routes.API_PBX_CONFIG_SOUND, ACTION_NAMES.PBX_CONFIG_SOUND);
const getSoundOptions = generateReadActionCreator(routes.API_PBX_CONFIG_SOUND_OPTIONS, ACTION_NAMES.PBX_CONFIG_SOUND_OPTIONS); 

const getUserGroups = generateReadActionCreator(routes.API_PBX_CONFIG_USER_GROUPS, ACTION_NAMES.PBX_CONFIG_USER_GROUPS)
const updateUserGroups = generateUpdateActionCreator(routes.API_PBX_CONFIG_USER_GROUPS, ACTION_NAMES.PBX_CONFIG_USER_GROUPS, () => {}, () => {}, '', true)

const getUserExtensions = generateReadActionCreator(routes.API_PBX_CONFIG_USER_EXTENSION, ACTION_NAMES.PBX_CONFIG_USER_EXTENSION)
const getExtension = generateReadOneActionCreator(routes.API_PBX_CONFIG_EXTENSION, ACTION_NAMES.PBX_CONFIG_EXTENSION);
const updateExtension = generateUpdateActionCreator(routes.API_PBX_CONFIG_USER_EXTENSION, ACTION_NAMES.PBX_CONFIG_USER_EXTENSION)

const getUsers = generateReadActionCreator(routes.API_PBX_CONFIG_USERS, ACTION_NAMES.PBX_CONFIG_USERS)

const readCallerIdOptions = generateReadActionCreator(routes.API_PBX_CONFIG_CID_OPTIONS, ACTION_NAMES.CID_OPTIONS, 'cid');
const readCallerIdPrefs = generateReadActionCreator(routes.API_PBX_CONFIG_CID_PREFS, ACTION_NAMES.CID_PREFS, 'cid_prefs');
const updateCallerIdPrefs = generateUpdateActionCreator(routes.API_PBX_CONFIG_UPDATE_CID_PREFS, ACTION_NAMES.UPDATE_CID_PREFS, () => {}, () => {}, undefined, false);
const removeSound = () => {
    return {
        type: 'REMOVE_SOUND',
    }
}

const readIvrs = generateReadActionCreator(routes.API_PBX_CONFIG_IVR, ACTION_NAMES.PBX_CONFIG_IVR);
const getIvr = generateReadOneActionCreator(routes.API_PBX_CONFIG_IVR, ACTION_NAMES.PBX_CONFIG_IVR);
const createIvr = generateCreateActionCreator(routes.API_PBX_CONFIG_IVR, ACTION_NAMES.PBX_CONFIG_IVR);
const updateIvr = generateUpdateActionCreator(routes.API_PBX_CONFIG_IVR, ACTION_NAMES.PBX_CONFIG_IVR);
const deleteIvr = generateDeleteActionCreator(routes.API_PBX_CONFIG_IVR, ACTION_NAMES.PBX_CONFIG_IVR)

export default {
    getRecordings,
    updateRecordings,
    getPreferences,
    updatePreferences,
    getDylNumbers,
    getDylNumber,
    getDestinations,
    updateDylNumber,
    readCompanyExtensions,
    getDestinationGroups,
    addDestinationGroups,
    deleteDestinationGroup,
    getDestinationGroup,
    editDestinationGroup,
    listPhones,
    getListOfExtensions,
    getUnusedExtensions,
    getRoutingRules,
    isDuplicatedName,
    createRoutingRules,
    getRoutingRule,
    updateRoutingRule,
    deleteRoutingRule,
    createCompanyVoicemails,
    getCompanyVoicemail,
    updateCompanyVoicemail,
    createConferenceRoom,
    createParkingLot,
    getConferenceRoom,
    getParkingLot,
    updateParkingLot,
    updateConferenceRoom,
    unusedExtensions,
    deleteCompanyVoicemail,
    deleteConferenceRoom,
    deleteParkingLot,
    getSounds,
    getSound,
    createSound,
    updateSound,
    deleteSound,
    getSoundOptions,
    getUserExtensions,
    getUserGroups,
    updateUserGroups,
    getExtension,
    getUsers,
    readCallerIdOptions,
    readCallerIdPrefs,
    updateCallerIdPrefs,
    updateExtension,
    removeSound,
    createIvr,
    getUsersWithExtensions,
    readIvrs,
    getIvr,
    updateIvr,
    deleteIvr,
    viewDestinations,
    setPinned
}
