import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function campaignsReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCampaigns: true, campaigns: [], campaigns_count: 0 };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isReadingCampaigns: false,
                campaigns: action.data.data || [],
                campaigns_count: action.data.count || 0
            };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCampaigns: false, campaigns: [], campaigns_count: 0 };

        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCampaign: true };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCampaign: false };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCampaign: false };

        case getNamedAction(ACTION_NAMES.CHECK_CAMPAIGN_DUPLICATE, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isCheckingDuplicate: true, duplicate: null };
        case getNamedAction(ACTION_NAMES.CHECK_CAMPAIGN_DUPLICATE, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isCheckingDuplicate: false,
                duplicate: action.data
            };
        case getNamedAction(ACTION_NAMES.CHECK_CAMPAIGN_DUPLICATE, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isCheckingDuplicate: false, duplicate: null };

        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCampaign: true, campaign: {}, campaignError: false };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return {
                ...state,
                isReadingCampaign: false,
                campaign: action.data
            };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCampaign: false, campaign: null, campaignError: true };

        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, campaignBeingUpdated: true };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, campaignBeingUpdated: false };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, campaignBeingUpdated: false };

        case getNamedAction(ACTION_NAMES.REACTIVATE_CAMPAIGN, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, campaignBeingReactivated: true };
        case getNamedAction(ACTION_NAMES.REACTIVATE_CAMPAIGN, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, campaignBeingReactivated: false };
        case getNamedAction(ACTION_NAMES.REACTIVATE_CAMPAIGN, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, campaignBeingReactivated: false };

        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, campaignBeingDeleted: true };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, campaignBeingDeleted: false };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, campaignBeingDeleted: false };

        case getNamedAction(ACTION_NAMES.CAMPAIGN_USER, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, campaignUserBeingDeleted: true };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_USER, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, campaignUserBeingDeleted: false };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_USER, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, campaignUserBeingDeleted: false };

        case getNamedAction(ACTION_NAMES.CAMPAIGN_USER_REPORTS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isAddingUserReport: true };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_USER_REPORTS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isAddingUserReport: false };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_USER_REPORTS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isAddingUserReport: false };

        case getNamedAction(ACTION_NAMES.CAMPAIGN_USER_REPORTS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCampaignUserIds: true, campaignUserIds: [], campaignUserIdsCount: 0 };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_USER_REPORTS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCampaignUserIds: false, campaignUserIds: action.data, campaignUserIdsCount: (action.data || []).length };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_USER_REPORTS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCampaignUserIds: false, campaignUserIds: [], campaignUserIdsCount: 0 };

        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONVERTED, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCampaignConverted: true };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONVERTED, CRUD_ACTION_TYPES.READ_SUCCESS):
            return {
                ...state,
                isReadingCampaignConverted: false,
                campaignConvertedOptions: action.data.data || [],
            };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONVERTED, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCampaignConverted: false };

        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONVERTED, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, customCampaignConvertedBeingUpdated: action.queryParameters.campaign_id };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONVERTED, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return {
                ...state,
                customCampaignConvertedBeingUpdated: null,
            };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_CONVERTED, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, customCampaignConvertedBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.CAMPAIGN_BULK_ACTION, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isApplyingBulkAction: true };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_BULK_ACTION, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isApplyingBulkAction: false };
        case getNamedAction(ACTION_NAMES.CAMPAIGN_BULK_ACTION, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isApplyingBulkAction: false };

        case getNamedAction(ACTION_NAMES.CAMPAIGNS_LIST, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCampaignList: true, campaignList: [] };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS_LIST, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCampaignList: false, campaignList: action.data };
        case getNamedAction(ACTION_NAMES.CAMPAIGNS_LIST, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCampaignList: false, campaignList: [] };
            
        default:
            return state;
    }
}


export default campaignsReducer;

