import { Modal, generateResolver } from "dyl-components";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Icon, Popup, Segment } from "semantic-ui-react";
import EditQuote from "./EditQuote";
import AddProducts from "./AddProducts";
import ProposalPreview from "./ProposalPreview";
import { useContext, useEffect, useState } from "react";
import { CURRENT_STEPS, QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import { Error500 } from "pages";
import Order from "shared/forms/Order";
import Payment from "shared/Payment";
import { quoteSchema } from "shared/schemas/quote/quoteSchema";
import { orderSchema } from "shared/schemas/order/orderSchema";
import { checkoutSchema } from "shared/schemas/checkout/checkoutSchema";
import { getOrderDefaultValues, getQuoteDefaultValues } from "./helper";
import subscriptionActions from "actions/subscription";
import InvoicePreview from "./InvoicePreview";
import { upsellSchema } from "shared/schemas/upsell/upsellSchema";
import { cartSchema } from "shared/schemas/cart/cartSchema";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";

const ModalContent = ({
    opportunity_id,
    account_id,
    contact_id,
    isLinkedToCustomer,
    account_primary_contact_id,
    onCloseModal,
    header,
}) => {
    const quote = useSelector((state) => state.quote.quote);
    const order = useSelector((state) => state.order.order);
    const account_term = useSelector((state) => state.subscription.term);

    const { quoteBuilderConfig } = useContext(QuoteBuilderContext);

    const { currentStep, id, isUpsell } = quoteBuilderConfig;

    const defaultValues = (() => {
        if (
            currentStep === CURRENT_STEPS.BUILD_QUOTE ||
            (currentStep === CURRENT_STEPS.ADD_PRODUCTS && id)
        ) {
            return getQuoteDefaultValues({ quote, account_id });
        }
        if ((currentStep === CURRENT_STEPS.ORDER || currentStep === CURRENT_STEPS.EDIT_ORDER) && id) {
            return getOrderDefaultValues({ order, account_id, account_term });
        }
        if (currentStep === CURRENT_STEPS.CHECKOUT) {
            return {
                invoice_name: "",
            };
        }
        return {
            pricing_schedule:
                account_term !== "semi-annually"
                    ? account_term
                    : "semi_annually",
            cart: [],
        };
    })();

    const currentStepSchema = (() => {
        if (currentStep === CURRENT_STEPS.BUILD_QUOTE) {
            return quoteSchema;
        }
        if (currentStep === CURRENT_STEPS.ORDER || currentStep === CURRENT_STEPS.EDIT_ORDER) {
            return orderSchema;
        }
        if (currentStep === CURRENT_STEPS.CHECKOUT) {
            return checkoutSchema;
        }
        if (isUpsell) {
            return upsellSchema;
        }
        return cartSchema;
    })();

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: generateResolver(currentStepSchema),
    });

    const { trigger } = methods;

    useEffect(() => {
        if (currentStep === CURRENT_STEPS.BUILD_QUOTE || (currentStep === CURRENT_STEPS.ORDER || currentStep === CURRENT_STEPS.EDIT_ORDER)) {
            trigger(`cart`);
        }
    }, [currentStep, trigger]);

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    return (
        <>
            <Modal
                size="big"
                open
                onClose={() => {
                    methods.formState.isDirty
                        ? setIsConfirmationOpen(true)
                        : onCloseModal();
                }}
            >
                {header}
                <FormProvider {...methods}>
                    {currentStep === CURRENT_STEPS.BUILD_QUOTE && (
                        <EditQuote
                            account_id={account_id}
                            opportunity_id={opportunity_id}
                            isLinkedToCustomer={isLinkedToCustomer}
                            contact_id={contact_id}
                            account_primary_contact_id={
                                account_primary_contact_id
                            }
                        />
                    )}
                    {currentStep === CURRENT_STEPS.ADD_PRODUCTS && (
                        <AddProducts
                            opportunity_id={opportunity_id}
                            account_id={account_id}
                            contact_id={contact_id}
                        />
                    )}
                    {(currentStep === CURRENT_STEPS.ORDER || currentStep === CURRENT_STEPS.EDIT_ORDER) && (
                        <Order
                            account_id={account_id}
                            contact_id={contact_id}
                        />
                    )}
                    {currentStep === CURRENT_STEPS.CHECKOUT && (
                        <Payment account_id={account_id} />
                    )}
                </FormProvider>
            </Modal>
            <FullScreenModalPopUp
                header={"Changes not saved"}
                subheader={"Are you sure you want to exit?"}
                isOpen={isConfirmationOpen}
                onConfirm={() => {
                    onCloseModal();
                    setIsConfirmationOpen(false);
                    methods.reset();
                }}
                onFormClose={() => setIsConfirmationOpen(false)}
                closeIcon={false}
            />
        </>
    );
};

const QuoteBuilderModal = ({
    opportunity_id,
    account_id,
    contact_id,
    isLinkedToCustomer,
    account_primary_contact_id,
}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (account_id) {
            dispatch(subscriptionActions.getAccountTerm(account_id));
        }
    }, [account_id, dispatch]);

    const {
        isReading,
        quote,
        quoteVersion,
        quoteVersions,
        isReadingQuoteVersions,
        quoteVersionError,
        orderError,
        order,
        invoice,
        invoiceError,
        opportunityQuoteOptions,
        isReadingOpportunityQuoteOptions,
    } = useSelector((state) => ({
        isReading:
            state.quote.quoteBeingRead ||
            state.quote.quoteVersionBeingRead ||
            state.order.orderBeingRead ||
            state.subscription.isReadingAccountTerm ||
            state.invoice.invoiceBeingRead,
        isReadingQuoteVersions: state.quote.quoteVersionsBeingRead,
        quote: state.quote.quote,
        quoteVersion: state.quote.quoteVersion,
        quoteVersions: state.quote.quoteVersions,
        quoteVersionError: state.quote.quoteVersionError,
        order: state.order.order,
        orderError: state.order.orderError,
        invoice: state.invoice.invoice,
        invoiceError: state.invoice.invoiceError,
        opportunityQuoteOptions: state.quote.opportunityQuoteOptions,
        isReadingOpportunityQuoteOptions:
            state.quote.isReadingOpportunityQuoteOptions,
    }));

    const { quoteBuilderConfig, onCloseModal, onViewProposal, onEditQuote } =
        useContext(QuoteBuilderContext);

    const { currentStep, id, isUpsell, quote_action } = quoteBuilderConfig || {
        currentStep: null,
        id: null,
        isUpsell: false,
        quote_action: null,
    };

    const getModalHeader = () => {
        if (currentStep === CURRENT_STEPS.ADD_PRODUCTS) {
            return opportunity_id ? (
                "Add Products"
            ) : (
                <>
                    Product Catalog
                    {!isUpsell && (
                        <Popup
                            trigger={
                                <Icon
                                    size="small"
                                    style={{
                                        float: "right",
                                        marginRight: "1em",
                                        marginTop: "0.2em",
                                    }}
                                    className="fas fa-circle-info"
                                    color="primary"
                                />
                            }
                            content={
                                "Quotes can only be added to an opportunity"
                            }
                            inverted
                            position="bottom right"
                            size="mini"
                        />
                    )}
                </>
            );
        }
        if (currentStep === CURRENT_STEPS.BUILD_QUOTE) {
            return (
                <>
                    Quote Builder
                    {!isReadingOpportunityQuoteOptions ?
                        opportunityQuoteOptions.length > 1 && (
                            <Dropdown
                                selection
                                options={opportunityQuoteOptions.map(
                                    (quote_id) => ({
                                        key: quote_id,
                                        value: quote_id,
                                        text: `Quote ID: ${quote_id}`,
                                    })
                                )}
                                value={id}
                                onChange={(_, { value }) => {
                                    onEditQuote(value, quote_action);
                                }}
                                selectOnBlur={false}
                                style={{ float: "right", marginRight: "1em" }}
                            />
                        ) : (
                            <Icon size='small' color="black" style={{ float: "right", marginRight: "1em" }} className="fas fa-circle-notch" loading />
                        )}
                </>
            );
        }
        if (currentStep === CURRENT_STEPS.ORDER) {
            return "Create Order";
        }
        if (currentStep === CURRENT_STEPS.CHECKOUT) {
            return "Check Out";
        }
        if (currentStep === CURRENT_STEPS.EDIT_ORDER) {
            const order_status = order?.order_status;
            if (order_status === "completed" || order_status === "deleted") {
                return "View Order";
            }
            return "Edit Order";
        }
        if (currentStep === CURRENT_STEPS.PROPOSAL_PREVIEW) {
            if (quoteVersionError) {
                return "Proposal";
            }
            return (
                <>
                    {quoteVersion?.quote_information?.name}
                    {!isReadingQuoteVersions && quoteVersions.length > 1 && (
                        <Dropdown
                            selection
                            options={quoteVersions.map((version) => ({
                                key: version.id,
                                value: version.id,
                                text: version.name,
                            }))}
                            value={id}
                            onChange={(_, { value }) => {
                                onViewProposal(value);
                            }}
                            selectOnBlur={false}
                            style={{ float: "right", marginRight: "1em" }}
                        />
                    )}
                </>
            );
        }
        if (currentStep === CURRENT_STEPS.INVOICE) {
            if (invoiceError) {
                return "Invoice";
            }
            return invoice?.name || "Invoice";
        }
        return null;
    };

    const isOpen = Boolean(currentStep);

    if (!isOpen) {
        return null;
    }

    if (isReading || (currentStep === CURRENT_STEPS.BUILD_QUOTE && !Boolean(quote))) {
        return (
            <Modal
                size="big"
                open={isOpen}
                onClose={onCloseModal}
            >
                <Modal.Header>{getModalHeader()}</Modal.Header>
                <Modal.Content>
                    <Segment basic loading />
                </Modal.Content>
            </Modal>
        );
    }
    if (
        (currentStep === CURRENT_STEPS.PROPOSAL_PREVIEW && quoteVersionError) ||
        ((currentStep === CURRENT_STEPS.ORDER || currentStep === CURRENT_STEPS.EDIT_ORDER) && orderError) || 
        (currentStep === CURRENT_STEPS.INVOICE && invoiceError)
    ) {
        return (
            <Modal
                size="big"
                open={isOpen}
                onClose={onCloseModal}
            >
                <Modal.Header>{getModalHeader()}</Modal.Header>
                <Modal.Content>
                    <Error500 height="50vh" message={"Something went wrong"} />
                </Modal.Content>
            </Modal>
        );
    }
    if (currentStep === CURRENT_STEPS.PROPOSAL_PREVIEW) {
        return (
            <Modal
                size="big"
                open={isOpen}
                onClose={onCloseModal}
            >
                <Modal.Header>{getModalHeader()}</Modal.Header>
                <ProposalPreview opportunity_id={opportunity_id} />
            </Modal>
        );
    }
    if (currentStep === CURRENT_STEPS.INVOICE) {
        return (
            <Modal
                size="big"
                open={isOpen}
                onClose={onCloseModal}
            >
                <Modal.Header>{getModalHeader()}</Modal.Header>
                <InvoicePreview />
            </Modal>
        );
    }
    return (
        <ModalContent
            account_id={account_id}
            opportunity_id={opportunity_id}
            contact_id={contact_id}
            isLinkedToCustomer={isLinkedToCustomer}
            account_primary_contact_id={account_primary_contact_id}
            header={<Modal.Header>{getModalHeader()}</Modal.Header>}
            onCloseModal={onCloseModal}
        />
    );
};

export default QuoteBuilderModal;
