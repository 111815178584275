import React from 'react';

import './index.scss';
import { ButtonLink, Icon } from 'dyl-components';
import { Header, Loader } from 'semantic-ui-react';
import OfficeViewCompanyDestination from 'dyl-components/atoms/OfficeViewCompanyDestination';

const OfficeViewCompanyDestinations = ({ 
    isExpanded,
    toggleIsExpanded,
    companyDestinations = [],
    togglePinned,
    isReadingViewDestinations,
    isPinnedDestinationsMaxed 
}) => {
    return (
        <div className='OfficeView__companyDestinationContainer'>
            <div className={`OfficeView__companyDestinationHeader ${(isExpanded || companyDestinations.length > 0 || isReadingViewDestinations) && "OfficeView__companyDestinationHeader--bordered"}`}>
                <Header className='OfficeView__companyDestinationTitle' as={"h2"}>Company Destination</Header>
                <ButtonLink onClick={toggleIsExpanded}>
                    <Icon size="big" className={`fa-solid fa-circle-chevron-${isExpanded ? "up" : "down"}`} />
                </ButtonLink>
            </div>
            {(isExpanded || companyDestinations.length > 0 || isReadingViewDestinations) && (
                isReadingViewDestinations ? (
                    <div style={{ padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader active inline />
                    </div>
                ) : (
                    <div style={{ display:'grid', overflowY: 'auto', gridTemplateColumns: "repeat(3, 1fr)", gap: "30px 30px", padding: 20 }}>
                        {companyDestinations.map((destination) => {
                            return <OfficeViewCompanyDestination 
                                destination={destination} 
                                togglePinned={togglePinned} 
                                pinButtonDisabled={!destination.pinned && isPinnedDestinationsMaxed} 
                            />
                        })}
                    </div>
                )
            )}
        </div>
    )
}

export default OfficeViewCompanyDestinations;

