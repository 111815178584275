import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';
import { Header, Icon, Popup } from 'semantic-ui-react';
import { StringUtils } from 'utils';
import "./index.scss"
import ButtonLink from 'dyl-components/atoms/ButtonLink';

const STAGE_ICONS = {
    opportunity: "fa-solid fa-funnel-dollar",
    lead: "fa-solid fa-id-card",
}

const OfficeViewMiddleHeader = ({ person, onRemovePerson, isDialDisabled, onEmailClick, onTaskClick, onEventClick, onHotlistClick, isInHotlist, hasIntegration = false, logActivityForm, isLogActivityModalOpen, setIsLogActivityModalOpen }) => {
    const [isEmailDisabled, setIsEmailDisabled] = useState(false);

    useEffect(() => {
        const isEmailDisabledAux = person.contact_id ? (
            !person.contact_details?.email
        ) : (
            !hasIntegration || !person.account_details?.email
        )
        setIsEmailDisabled(isEmailDisabledAux);
    }, [hasIntegration, person])

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <Avatar
                    size='5em'
                    round
                    maxInitials={2}
                    name={person.name}
                    style={{ marginRight: 15 }}
                />
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                            <div style={{width: 'fit-content'}}>
                                <Link to={person.contact_id ? `/contact/${person.contact_id}` : `/account/${person.account_id}`} target='_blank' rel='noopener noreferrer'>
                                    <Header as={"h1"} className='OfficeView__headerName'>
                                        {person.name}
                                    </Header>
                                </Link>
                            </div>
                        </div>
                        <ButtonLink onClick={onRemovePerson}>
                            <Icon name='x' size='big' className='OfficeView__closeHeaderIcon' />
                        </ButtonLink>
                    </div>
                    <div style={{ display: 'flex', gap: 15 }}>
                        <div>
                            <Icon
                                className={`fa-solid ${person.contact_id ?
                                    ("fa-user") :
                                    (person.account_details?.account_type === "business" ? "fa-buildings" : "fa-house-chimney")
                                }`}
                                style={{ marginRight: 6 }} 
                            />
                            <strong>{person.contact_id ? "Contact" : "Account"}</strong>
                        </div>
                        {person.contact_id ? (
                            <>
                                {person.contact_details?.job_title && <span>{person.contact_details?.job_title}</span>}
                                <div>
                                    <Icon className={STAGE_ICONS[person.contact_details?.pipeline_stage]} style={{ marginRight: 6 }} />
                                    <strong>{StringUtils.capitalize(person.contact_details?.pipeline_stage || "") || ""}</strong>
                                </div>
                            </>
                        ) : (person.account_details?.customer && (
                            <div>
                                <Icon className='fa-solid fa-user-crown' style={{ marginRight: 6 }} />
                                <strong>Customer</strong>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', gap: 15, marginTop: 15, marginLeft: 10 }}>
                <ButtonLink disabled={isDialDisabled}>
                    <Icon className='fa-solid fa-phone OfficeView__icons' size={'large'} disabled={isDialDisabled} primary />
                </ButtonLink>
                {hasIntegration ? (
                    <ButtonLink onClick={onEmailClick} disabled={isEmailDisabled}>
                        <Icon className='fa-solid fa-envelope OfficeView__icons' size={'large'} />
                    </ButtonLink>
                ) : (
                    <Popup
                        className="AddForm"
                        trigger={
                            <ButtonLink onClick={() => setIsLogActivityModalOpen(true)} disabled={isEmailDisabled}>
                                <Icon className='fa-solid fa-envelope OfficeView__icons' size={'large'} />
                            </ButtonLink>
                        }
                        content={() => logActivityForm({
                            id: person.contact_id,
                            email: {
                                email: person.contact_details.email,
                                main: true
                            }
                        })}
                        open={isLogActivityModalOpen}
                        on="click"
                        position="bottom left"
                        offset={[25, -125]}
                        pinned
                        basic
                        wide
                    />
                )}
                {person?.contact_id && (
                    <>
                        <ButtonLink onClick={onTaskClick}>
                            <Icon className='fa-solid fa-clipboard-check OfficeView__icons' size={'large'} />
                        </ButtonLink>
                        <ButtonLink onClick={onEventClick}>
                            <Icon className='fa-solid fa-calendar-day OfficeView__icons' size={'large'} />
                        </ButtonLink>
                        <ButtonLink onClick={onHotlistClick}>
                            <Icon className={`${isInHotlist ? "fa-solid fa-fire-flame-curved OfficeView__icons--red" : "fa-solid fa-fire-flame OfficeView__icons"}`} size={'large'} />
                        </ButtonLink>
                    </>
                )}
            </div>
        </div>
    )
}

export default OfficeViewMiddleHeader;