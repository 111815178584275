import { Grid, Header, Segment } from "semantic-ui-react";
import QuoteItemsTable from "shared/forms/Quote/QuoteItemsTable";
import OrderInformation from "./OrderInformation";
import RecurringProducts from "./RecurringProducts";
import QuoteTotals from "../Quote/QuoteTotals";
import { useController, useFieldArray, useFormContext } from "react-hook-form";
import SubscriptionTotals from "pages/SubscriptionProducts/SubscriptionTotals";
import { useSelector } from "react-redux";

const OrderSummary = ({ quote, account_id, disabled }) => {
    const { control } = useFormContext();

    const { field: cartField } = useController({
        name: "cart",
        control
    })

    const { fields: cart } = useFieldArray({
        control,
        name: "cart",
        keyName: "key",
    });

    const { field: orderStatusField } = useController({
        name: "order_status",
        control,
    });

    const isDeletingOrder = orderStatusField.value === "deleted";

    const {
        subtotal,
        total,
        tax,
        discount,
        fee,
        next_invoice_date
    } = useSelector(state => {
        const order_status = state.order.order?.order_status;
        if (order_status && (order_status === "draft" || order_status === "open")) {
            return state.subscription.next_invoice;
        }
        return state.order.order?.next_invoice || {
            subtotal: 0,
            total: 0,
            tax: 0,
            discount: 0,
            fee: 0,
            next_invoice_date: null
        }
    });

    const isReadingProducts = useSelector(
        (state) => state.subscription.isReadingCurrentProducts
    );

    const isReadingNextInvoice = useSelector(state => state.subscription.isReadingNextInvoice);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header color="primary">Order Information</Header>
                    <OrderInformation disabled={disabled} account_id={account_id} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Header color="primary">Order Summary</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <QuoteItemsTable disabled={disabled || isDeletingOrder} quote={quote} cart={cart} isOrder />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Header color="primary">Recurring Products</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment loading={isReadingProducts} basic style={{ padding: 0 }}>
                        <RecurringProducts />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
                <Grid.Column />
                <Grid.Column floated="right">
                    <Segment>
                        <QuoteTotals isInvoice quote={quote} cart={cartField.value} />
                    </Segment>
                </Grid.Column>
                <Grid.Column floated="right">
                    <Segment loading={isReadingNextInvoice}>
                        <SubscriptionTotals
                            subtotal={subtotal || 0}
                            total={total || 0}
                            totalDiscount={discount || 0}
                            totalFee={fee || 0}
                            totalTax={tax || 0}
                            nextInvoiceDate={next_invoice_date}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default OrderSummary;
