import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Table, STATUS_TYPES, Notification, EllipsisDropdown, ControlledPopup, UserAvatarList } from 'dyl-components';
import { Loader, Segment, Header, Dropdown } from 'semantic-ui-react';
import { StringUtils } from 'utils';
import roleActions from 'actions/role';
import rolesActions from 'actions/roles';


import './PermissionsRow.scss';

const PermissionsRow = ({
    permission,
    onDelete,
    isDeletingRole,
    user_id
}) => {
    const name = (!permission.editable) ? StringUtils.capitalize(permission.name) : permission.name;
    const navigate = useNavigate();
    const showPermission = (permission, type='view') => {
        const id = permission.id
        const paths = {
            view: `/settings/view-permission-profile/${id}`,
            edit: `/settings/edit-permission-profile/${id}`,
            duplicate: `/settings/add-permission-profile`
        }

        navigate(paths[type], { state: {incomingPermission: {type, permission, permid:permission?.id}} });
    }
    console.log("|+| permission", permission);

    return(
    <Table.Row loading={isDeletingRole}>
        <Table.Cell width={4}>
            <Header as='h3' color='blue'  className={"PermissionLink"} onClick={()=>{ showPermission(permission, (permission.editable) ? "edit" : 'view') } }>
                {name}{permission.editable ? '' : ' (Default)'}
            </Header>
            {permission.description}
        </Table.Cell>
        <Table.Cell>
            <UserAvatarList
                user_id={user_id}
                usersList={permission.assignees}
                usersLimit={10}
            />
        </Table.Cell>
        <Table.Cell>
            {!permission.owner &&           
                <EllipsisDropdown>
                    {[ 
                        !permission.editable ? '' : 
                        <Dropdown.Item icon='pencil' text='Edit' onClick={()=>{ showPermission(permission, "edit") }}/>,
                        <Dropdown.Item icon='copy' text='Duplicate' onClick={()=>{ showPermission(permission, "duplicate") }} />,
                        !permission.editable || permission.assignees.length !== 0 ? '' :
                        <ControlledPopup
                            trigger={<Dropdown.Item icon='trash' text='Delete' />}
                            onConfirm={() => { onDelete(permission.id) }}
                            content={'Delete'}
                            inverted
                        />
                    ]}
                </EllipsisDropdown>
            }   
        </Table.Cell>
    </Table.Row>
    )
};

class PermissionsRowContainer extends React.Component {

    render() {
        if (this.props.isDeletingRole) {
            return <Table.Row>
                <Table.Cell colSpan={3}>
                    <Segment basic><Loader active /></Segment>
                </Table.Cell>
            </Table.Row>
        }

        return (           
            <React.Fragment>
                <PermissionsRow 
                    {...this.props}
                    user_id={this.props.user_id}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    isDeletingRole: state.role.roleBeingDeleted === ownProps.permission.id,
    user_id: state.users.userProfile.user_id ? state.users.userProfile.user_id : null
})

const mapDispatchToProps = dispatch => ({
    onDelete: (id) => {
        dispatch(roleActions.deleteUserRole(id))
            .then(() => {
                Notification.alert('Successfully removed permission profile!', STATUS_TYPES.SUCCESS, true);
                dispatch(rolesActions.readRoles());
            })
            .catch((error) => {
                if (error.message === 'method delete_role - error :Access role not empty.') {
                    Notification.alert('Permission Profile has assigned user(s).', STATUS_TYPES.ERROR, true);
                } else {
                    Notification.alert('Failed to remove Permisssion Profile', STATUS_TYPES.ERROR, true);
                }
            })
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(PermissionsRowContainer);
