import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DateTimeUtils, Person, Table, TableLoader } from "dyl-components";

import orderActions from 'actions/order';
import { StringUtils } from "utils";

import Subrows from "./Subrows";
import QuoteID from "shared/QuoteID";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import { useParams } from "react-router-dom";

const Row = ({ order }) => {
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(true);
    const { account_id } = useParams();

    const { isReading: isReadingInvoices } = useSelector(state => ({
        ...(state.order.invoiceSummary[order.order_id] || {
            isReading: false
        })
    }));

    const { isReading: isReadingCancellations } = useSelector(state => ({
        ...(state.order.cancellations[order.order_id] || {
            isReading: false
        })
    }));

    const onToggle = () => {
        setCollapsed(!collapsed);
        if (collapsed) {
            refreshVersions();
        }
    };

    const refreshVersions = async () => {
        dispatch(orderActions.readOrderInvoices(order.order_id, { account_id }));
        dispatch(orderActions.readCancellations(order.order_id, { account_id }));
    };

    const { onEditOrder, onViewInvoice } = useContext(QuoteBuilderContext);

    const formatStatus = (status) => {
        return StringUtils.capitalize(status);
    }

    return (
        <Table.CollapsibleRow
            onToggle={onToggle}
            collapsed={collapsed}
            toggleDisabled={!Boolean(order.latest_invoice)}
            subrowContent={
                (isReadingInvoices || isReadingCancellations) ? (
                    <TableLoader colspan={10} isLoading />
                ) : (
                    <Subrows order_id={order.order_id} />
                )
            }
        >
            <Table.Cell>
                    <div>
                        <b>
                            {DateTimeUtils.formatEpoch(
                                order.created,
                                DateTimeUtils.WORD_DATE_FORMAT
                            )}
                        </b>
                    </div>
                </Table.Cell>
                <Table.Cell>
                    {order.latest_invoice && (
                        <div>
                            <b>
                                {DateTimeUtils.formatEpoch(
                                    order.latest_invoice,
                                    DateTimeUtils.WORD_DATE_FORMAT
                                )}
                            </b>
                        </div>
                    )}
                </Table.Cell>
                <Table.Cell>
                    <Person
                        username={order.created_by}
                        email={order.created_by_email}
                    />
                </Table.Cell>
                <Table.Cell>
                    <QuoteID
                        hasPdf={order.latest_invoice_id}
                        onEditQuote={() => {
                            onEditOrder(order.order_id, account_id);
                        }}
                        id={order.order_id}
                        onViewProposal={() => {
                            onViewInvoice(order.latest_invoice_id)
                        }}
                    />
                </Table.Cell>
                <Table.Cell>
                    {formatStatus(
                        order.status
                    )}
                </Table.Cell>
                <Table.Cell>
                    {order.subtotal &&
                        `$${StringUtils.formatDecimal(String(order.subtotal), true)}`}
                </Table.Cell>
        </Table.CollapsibleRow>
    );
}

export default Row;
