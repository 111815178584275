import { Table } from "dyl-components";
import { MathUtils, StringUtils } from "utils";
import React from "react";

const RecurringProductRow = ({
    index,
    product_name,
    pricing_schedule,
    price,
    variant_name,
    variant_value,
    product_description,
    quantity,
    discount,
    subtotal,
    additional_price,
}) => {
    return (
        <Table.Row>
            <Table.Cell>
                <span>
                    {index + 1}. <b>{product_name}</b>
                </span>
                {variant_name && (
                    <div style={{ marginLeft: "1em" }}>
                        <small>
                            ({variant_name}/{variant_value})
                        </small>
                    </div>
                )}
            </Table.Cell>
            <Table.Cell>{product_description}</Table.Cell>
            <Table.Cell>{StringUtils.capitalize(pricing_schedule)}</Table.Cell>
            <Table.Cell>
                <div>${StringUtils.formatDecimal(`${price}`, true)}</div>
                {Boolean(additional_price) && (
                    <small>
                        (+$
                        {StringUtils.formatDecimal(
                            `${additional_price}`,
                            true
                        )}
                        )
                    </small>
                )}
            </Table.Cell>
            <Table.Cell>{quantity}</Table.Cell>
            <Table.Cell>
                $
                {StringUtils.formatDecimal(
                    `${MathUtils.roundDecimals(
                        (price + (additional_price || 0)) * quantity
                    )}`,
                    true
                )}
            </Table.Cell>
            <Table.Cell>{discount && `${discount}%`}</Table.Cell>
            <Table.Cell>
                $
                {StringUtils.formatDecimal(
                    `${MathUtils.roundDecimals(subtotal)}`,
                    true
                )}
            </Table.Cell>
        </Table.Row>
    );
};

export default RecurringProductRow;
