import { ClippedContent, MessagingIcon } from 'dyl-components';
import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import { Icon, Popup } from 'semantic-ui-react';
import SendEmailModal from 'shared/modals/SendEmailModal';
import NotesModal from 'shared/modals/NotesModal';

import './index.scss';
import NewActivityForm from 'shared/forms/NewActivity';

const Contact = ({ contact, name, linkToContact, jobTitle, isSimplified, hideId, isModalOutside = false, onExteriorModalOpen, hideContact }) => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    const [modal, setModal] = useState("");

    const emailIntegrations = useSelector(state => state.oauth_integrations.integrations);

    const canEmail = Boolean(contact.email?.id);
    const hasEmailIntegrations = emailIntegrations.length > 0;

    const getModal = () => {
        switch (modal) {
            case "email":
                return <SendEmailModal
                    open={modal === "email"}
                    onClose={() => { setModal("") }}
                    contact_id={contact.id}
                />;
            case "note":
                return <NotesModal
                    open={modal === "note"}
                    onClose={() => { setModal("") }}
                    contact={contact}
                />;
            default:
                <React.Fragment />
        }
    }

    return (
        <React.Fragment>
            <div className='Contact'>
                {name && !hideContact && <Avatar name={name} maxInitials={2} round size='3em' />}
                <div className="Contact__information">
                    <span className='Contact__link'>
                        <ClippedContent popupContent={name}>
                            {linkToContact}
                        </ClippedContent>
                        {(!isSimplified && !hideId) && <div>
                            {(jobTitle !== undefined ? jobTitle : `ID: ${contact?.id}`)}
                        </div>}
                    </span>
                    {!isSimplified && <div className='Contact__actions'>
                        <Icon className='fa-solid fa-phone' link />
                        <Icon className='fa-solid fa-messages' link />

                        {canEmail ?
                            hasEmailIntegrations ? <Icon className='fa-solid fa-envelope' link onClick={() => {
                                if (isModalOutside) {
                                    onExteriorModalOpen('email', contact.id);
                                } else {
                                    setModal("email")
                                }
                            }} /> :
                                <Popup
                                    className="AddForm"
                                    trigger={
                                        <span style={{ marginRight: 10 }}>
                                            <MessagingIcon size='' icon={'mail'} onClick={() => {
                                                setIsAddModalOpen(true);
                                            }} />
                                        </span>
                                    }
                                    content={
                                        <NewActivityForm readTimeline={() => { }} emailOnly={true} contact={contact} onClose={() => {
                                            setIsAddModalOpen(false);
                                        }} />
                                    }
                                    open={isAddModalOpen}
                                    on="click"
                                    position="bottom left"
                                    pinned
                                    basic
                                    wide
                                />
                            : <Icon className='fa-solid fa-envelope' link disabled />
                        }

                        <Icon className='fa-sharp fa-solid fa-pen-clip' link onClick={() => {
                            if (isModalOutside) {
                                onExteriorModalOpen('note', contact);
                            } else {
                                setModal("note")
                            }
                        }} />
                    </div>}
                </div>
            </div>
            {!isModalOutside && getModal()}
        </React.Fragment>

    )
}

export default Contact;
