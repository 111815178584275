import React, { useEffect, useState } from "react";
import { Notification, STATUS_TYPES, DateTimeUtils } from "dyl-components";
import { useDispatch, useSelector } from "react-redux";
import { ValidationUtils, StringUtils } from "utils";
import userActions from "actions/user";
import rolesActions from "actions/roles";

import ChangePasswordModal from "shared/modals/ChangePasswordModal";

import { ProfileForm } from "./ProfileForm";
import usersActions from "actions/users";
import { useParams } from "react-router-dom";

const TIMEZONE_OPTIONS = DateTimeUtils.generateTimezoneOptions();

const General = () => {
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
        useState(false);

    const {
        first_name,
        last_name,
        email,
        profile_type,
        timezone,
        street,
        additional_street,
        city,
        state: location_state,
        zip,
        ext,
        work_number,
        mobile_number,
        facebook,
        twitter,
        linkedin,
        job_title,
        userProfile: profile,

        location_id,
        location_label,

        loggedInUser,
        isOwner,
        company,
        industry,
        profile_types,
    } = useSelector((state) => {
        const {
            first_name,
            last_name,
            email,
            cell_phone,
            job_title,
            location,
            company,
            social_media,
            timezone,
            access_role,
            report_to,
            work_phone,
            work_phone_ext,
        } = state.user.userProfile;
        const {
            street,
            additional_street,
            city,
            state: address_state,
            zip,
            id: location_id,
            label: location_label,
        } = location;
        const { industry, company_name } = company;
        const {
            isUpdatingUser,
            isCreatingSocialMedia,
            isUpdatingSocialMedia,
            locationBeingUpdated,
            isCreatingLocations,
        } = state.user;
        const userRole = state.roles.roles.find((f) => f.id === access_role);
        const isOwner = userRole ? userRole.owner : true; // DISABLED_PERMISSION // adjust

        const {
            id: superior_id,
            first_name: superior_first_name,
            last_name: superior_last_name,
        } = report_to || { id: 0 };
        const reportToSearchValue =
            superior_first_name || superior_last_name
                ? `${superior_first_name || ""} ${superior_last_name || ""}`
                : "Unknown Name";
        const profileName = userRole ? userRole.name : "";

        return {
            userProfile: state.user.userProfile,
            loggedInUser: state.auth.user_id,
            first_name,
            last_name,
            email,
            timezone,

            street,
            additional_street,
            city,
            state: address_state,
            zip,

            mobile_number: cell_phone,
            facebook: social_media.find(
                (site) => site.type.toLowerCase() === "facebook"
            ) || { id: null, url: "", type: "facebook" },
            linkedin: social_media.find(
                (site) => site.type.toLowerCase() === "linkedin"
            ) || { id: null, url: "", type: "linkedin" },
            twitter: social_media.find(
                (site) => site.type.toLowerCase() === "twitter"
            ) || { id: null, url: "", type: "twitter" },

            company: company_name ? company_name : "Not Available",
            industry: industry ? industry.name : "Not Available",
            job_title,
            profile_type: access_role,
            profileName: profileName,
            reports_to: superior_id,
            reportToSearchValue,

            work_number: work_phone,
            ext: work_phone_ext,

            profile_types: state.roles.roles
                .filter(({ owner }) => {
                    return !owner;
                })
                .map(({ id, name }) => ({
                    key: id,
                    value: id,
                    text: StringUtils.capitalize(name),
                })),
            location_id,
            location_label,
            isFindingUserByEmail: state.user.isFindingUserByEmail,
            isOwner,
            isUpdating:
                isUpdatingUser ||
                isCreatingSocialMedia ||
                isUpdatingSocialMedia ||
                locationBeingUpdated ||
                isCreatingLocations ||
                state.auth.isUpdatingRole,
        };
    });

    const userProfile = profile?.report_to;
    const reportsTo = {
        email: userProfile?.email || "",
        username: `${userProfile?.first_name || ""} ${
            userProfile?.last_name || ""
        }`,
        value: userProfile?.id || "",
    };

    const params = useParams();
    const user_id = params.id;

    const dispatch = useDispatch();

    const onReadProfile = (user_id) => {
        return dispatch(userActions.viewUserProfile(user_id));
    };

    useEffect(() => {
        dispatch(
            usersActions.readUserOptions({ limit: 500, page: 1, user_id })
        );
        dispatch(rolesActions.readRoles({ limit: 1000 }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id]);

    const onOpenChangePasswordModal = () => {
        setIsChangePasswordModalOpen(true);
    };

    const onCloseChangePasswordModal = () => {
        setIsChangePasswordModalOpen(false);
    };

    const onCheckIfDuplicateExists = async (email) => {
        const { customer_id, user_id } = await dispatch(
            userActions.findByEmail("", { email })
        );
        const result = customer_id || user_id;
        return result;
    };

    const addSocialMedia = (data = {}) => {
        const { facebook, linkedin, twitter } = data;
        const social_media_to_add = [];

        if (facebook.id === null && facebook) {
            social_media_to_add.push({
                type: "facebook",
                url: StringUtils.formatLink(data.facebook),
            });
        }
        if (linkedin.id === null && linkedin) {
            social_media_to_add.push({
                type: "linkedin",
                url: StringUtils.formatLink(data.linkedin),
            });
        }
        if (twitter.id === null && twitter) {
            social_media_to_add.push({
                type: "twitter",
                url: StringUtils.formatLink(data.twitter),
            });
        }
        if (social_media_to_add.length > 0) {
            return dispatch(
                userActions.addSocialMedia(social_media_to_add, null, user_id)
            );
        }

        return {};
    };

    const updateSocialMedia = (new_url, social) => {
        if (new_url.length === 0 && social.id) {
            return dispatch(
                userActions.deleteSocialMedia(user_id, null, social.id)
            );
        }
        if (social.id) {
            return dispatch(
                userActions.updateSocialMedia(
                    user_id,
                    { type: social.type, url: StringUtils.formatLink(new_url) },
                    null,
                    social.id
                )
            );
        }
        return {};
    };

    const modifyLocation = (data = {}) => {
        const location = {
            additional_street: data.additional_street,
            city: data.city,
            label: location_label,
            main: true,
            state: data.state,
            street: data.street,
            zip: data.zip,
        };

        if (location_id) {
            return dispatch(
                userActions.updateLocation(user_id, location, null, location_id)
            );
        }
        return dispatch(
            userActions.createLocations(
                [
                    {
                        ...location,
                        label: "Office Location",
                    },
                ],
                null,
                user_id
            )
        );
    };

    const [isSaving, setIsSaving] = useState(false);

    const onUpdate = async (data = {}) => {
        setIsSaving(true);
        try {
            await Promise.all([
                dispatch(
                    userActions.updateUser(user_id, {
                        ...profile,
                        id: data.user_id,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        cell_phone: data.mobile_number,
                        email: data.email,
                        job_title: data.job_title,
                        report_to: parseInt(data.reports_to),
                        timezone: data.timezone,
                        work_phone: data.work_number,
                        work_phone_ext: data.ext,
                        role_id: data.profile_type,
                    })
                ),
                // updateProfileType(data.profile_type),
                // TODO MOVE THE FOLLOWING INTO THE ABOVE REQUEST
                addSocialMedia(data),
                updateSocialMedia(data.facebook, facebook),
                updateSocialMedia(data.linkedin, linkedin),
                updateSocialMedia(data.twitter, twitter),
                modifyLocation(data),
            ]);

            Notification.alert(
                "Successfully updated user information!",
                STATUS_TYPES.SUCCESS,
                true
            );
            onReadProfile(user_id);
        } catch (e) {
            console.log(`|+|+ There was an error:`, e);
            Notification.alert(
                "There was a problem in updating user information. Try resubmitting later.",
                STATUS_TYPES.ERROR,
                true
            );
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            <ProfileForm
                isOwner={isOwner}
                company={company}
                industry={industry}
                profile_types={profile_types}
                defaultValues={{
                    id: user_id || "",
                    first_name: first_name || "",
                    last_name: last_name || "",
                    email: email || "",
                    profile_type: profile_type,
                    timezone:
                        ValidationUtils.notEmpty(timezone) &&
                        ValidationUtils.isValidOption(
                            timezone,
                            TIMEZONE_OPTIONS
                        )
                            ? timezone
                            : "America/New_York",

                    street: street || "",
                    additional_street: additional_street || "",
                    city: city || "",
                    state: location_state || "CA",
                    zip: zip || "",

                    work_number: work_number || "",
                    ext: ext || "",
                    mobile_number: mobile_number || "",

                    facebook: facebook.url || "",
                    linkedin: linkedin.url || "",
                    twitter: twitter.url || "",

                    job_title: job_title || "",
                    reports_to: reportsTo || "",

                    company: userProfile?.company?.company_name || "N/A",
                    industry: userProfile?.company?.industry?.name || "N/A",
                }}
                onCheckIfDuplicateExists={onCheckIfDuplicateExists}
                onOpenChangePasswordModal={onOpenChangePasswordModal}
                onUpdate={onUpdate}
                isChangePasswordModalOpen={isChangePasswordModalOpen}
                isSaving={isSaving}
            />
            <ChangePasswordModal
                open={isChangePasswordModalOpen}
                onClose={onCloseChangePasswordModal}
                selected_user_id={user_id}
                user_id={loggedInUser}
            />
        </>
    );
};

export default General;
