const initialState = {
    isReadingUser: false,
    user: {},
    e911: { valid: false },
    notifications: { attachments: false, fax: false, sms: false, voicemail: false },
    phone_system: false,
    sms_enabled: false,
    isUpdatingUser: false,
    phones: [],

    locations: [],
    locationBeingDeleted: null,
    locationBeingUpdated: null,
    isCreatingLocations: false,

    company: {
        name: '',
        industry: '',
        website: '',
        email: '',
        fiscal_year_start: '',
        timezone: '',
        ein: '',
        locations: [],
        social_medias: [],
        phones: []
    },
    isReadingCompany: false,
    isUpdatingCompany: false,
    isCreatingCompany: false,
    
    userProfile: {
        company: {
            industry: '',
            name: ''
        },
        location: {},
        schedule: [],
        social_media: [],
        teams: [],
        access_role: null
    },

    isDeletingSocialMedia: false,
    isUpdatingSocialMedia: false,
    isCreatingSocialMedia: false,

    isFindingUserByEmail: false,
    userSearchResult: {},

    userPasswordBeingUpdated: null,

    signatures: [],
    signature_count: 0,
    isCreatingSignature: false,
    signatureBeingUpdated: null,
    signatureBeingDeleted: null,

    signature_preferences: {
        new_email: 0,
        reply_forward_email: 0
    },
    isReadingSignaturePreferences: false,
    isUpdatingSignaturePreferences: false,
    firstNavigation: true,

    image: null,
    thumbnail: null,
    isReadingUserProfileImage: false,
    
    logged_in_user_image: null,
    isReadingLoggedInUserProfileImage: false,
}

export default initialState;
