import React, { useState, useEffect, useCallback } from "react";
import {
    SearchBar,
    Button,
    RecordsTable,
    Notification,
    STATUS_TYPES,
} from "dyl-components";
import { Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddRingGroupModal from "shared/modals/AddRingGroupOrCallQModal";
import ringGroupActions from "actions/ring_groups";
import callQueueActions from "actions/call_queues";
import BulkActionsProvider from "shared/context/BulkActionsProvider";
import Header from "./subcomponents/Header";
import Row from "./subcomponents/Row";
import "./index.scss";

const RING_GROUP_OR_CALL_QUEUE_URL = "/settings/phone-management/routing/ring-group-call-queues";

const RingGroupCallQueues = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const {
        ringGroupsData,
        ringGroupsCount,
        isReadingGroups,
        ring_group,
        isReadingRingGroup,
    } = useSelector((state) => state.ring_group);

    const { call_queue, isReadingCallQueue } = useSelector(
        (state) => state.call_queue
    );

    const searchQuery = params.get("search") || "";
    const [search, setSearch] = useState(searchQuery);

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${RING_GROUP_OR_CALL_QUEUE_URL}${query_string ? `?${query_string}` : ''}`);
    }

    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`${RING_GROUP_OR_CALL_QUEUE_URL}${query_string ? `?${query_string}` : ''}`,);
    }

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }

    const [filters, setFilters] = useState({
        type: params.get("type")?.split(","),
        status: params.get("order")?.split(","),
    });
    const [openAddModal, setOpenAddModal] = useState(false);
    const [editData, setEditData] = useState({});

    const onFilter = async (_, { name, value }) => {
        if(name === 'type' && value.length === 2){
            value = ['all'];
        }
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${RING_GROUP_OR_CALL_QUEUE_URL}${query_string ? `?${query_string}` : ''}`);
    };

    useEffect(() => {
        const query = new URLSearchParams(params);
        const { type, order } = filters;

        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }

        if (type?.length > 0) {
            query.set("type", type.join(","));
        } else {
            query.delete("type");
        }

        if (order) {
            query.set("order", order);
        } else {
            query.delete("order");
        }

        const query_string = query.toString();
        navigate(
            `${RING_GROUP_OR_CALL_QUEUE_URL}${
                query_string ? `?${query_string}` : ""
            }`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const onReload = useCallback(() => {
        dispatch(ringGroupActions.getRingGroups(params))
    }, [dispatch, params])

    useEffect(() => {
        onReload();
    }, [onReload]);

    useEffect(() => {
        const updatedRingGroupValues = {...ring_group};
        updatedRingGroupValues.after_destination_id = updatedRingGroupValues?.after_destination?.destination_id;
        updatedRingGroupValues.view = !!updatedRingGroupValues?.view;
        const type = editData.type;
        let alias = ring_group.alias;
        if (editData.mode === "duplicate") {
            alias = "";
        }
        setEditData({ ...updatedRingGroupValues, type, alias, mode: editData.mode });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ring_group]);

    useEffect(() => {
        const updatedCallQueueValues = {...call_queue};
        updatedCallQueueValues.internal_extensions = !!updatedCallQueueValues?.internal_extensions;
        updatedCallQueueValues.view = !!updatedCallQueueValues?.view;
        updatedCallQueueValues.after_destination_id = updatedCallQueueValues?.after_destination?.destination_id;
        updatedCallQueueValues.announce_delay = updatedCallQueueValues?.announce_sound?.delay;

        const type = editData.type;
        let alias = call_queue.alias;
        if (editData.mode === "duplicate") {
            alias = "";
        }
        setEditData({ ...updatedCallQueueValues, alias, type, mode: editData.mode });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [call_queue]);

    const onEditRingGroupCallQueue = (group, mode) => {
        if (group.group_type === "call_queue") {
            dispatch(callQueueActions.getCallQueue(group.id));
        } else {
            dispatch(ringGroupActions.getRingGroup(group.id));
        }
        const type = group?.group_type;
        setEditData({ ...editData, type, mode });
        setOpenAddModal(true);
    };

    const onRemove = async (id, type) => {
        let typeText;
        try {
            if (type === "ring_group") {
                typeText = "Ring Group";
                await dispatch(ringGroupActions.deleteRingGrp(id));
            } else {
                typeText = "Call Queue";
                await dispatch(callQueueActions.deleteCallQueueItem(id));
            }
            onReload();
            Notification.alert(
                `${typeText} deleted successfully!`,
                STATUS_TYPES.SUCCESS
            );
        } catch (error) {
            console.log(error);
            Notification.alert(
                `Failed to delete ${typeText}`,
                STATUS_TYPES.ERROR
            );
        }
    };

    return (
        <div className="RingGroupOrCallQueue">
            <div className="RingGroupHeader">
                <SearchBar
                    isSearching={false}
                    search={searchQuery}
                    searchFcn={onSearchSubmit}
                    cancelFcn={cancelFunction}
                    placeholder={
                        "Search by ring group, call queue name, or internal extension"
                    }
                    onChange={onChangeSearch}
                />
                <div className="RingGroupHeader__ButtonGroup">
                    <Button
                        className="RingGroup__NewUserButton"
                        onClick={() => {
                            setEditData({});
                            setOpenAddModal((openAddModal) => !openAddModal);
                        }}
                    >
                        <Icon name="plus" /> New
                    </Button>
                </div>
            </div>

            <RecordsTable
                tableBodyClassName="RingGroupCallQueuesTable"
                isTableLoading={isReadingGroups}
                isSearchResultEmpty={!ringGroupsCount && params.get("search")}
                isTableEmpty={ringGroupsData?.length === 0 && !params.get('search')}
                TableHeader={
                    <Header
                        ringGroups={ringGroupsData}
                        count={ringGroupsCount}
                        onFilter={onFilter}
                    />
                }
                TableRows={ringGroupsData?.map((group) => (
                    <Row
                        group={group}
                        onEditRingGroupCallQueue={onEditRingGroupCallQueue}
                        onRemove={onRemove}
                    />
                ))}
                recordsCount={ringGroupsCount}
                activePage={params.get("page")}
                onPageChange={onPageChange}
                emptySubtitle=" "
                recordsName="Ring Groups/Call Queues"
                emptyListTitle={"No Ring Groups/Call Queues Created"}
            />

            <AddRingGroupModal
                open={openAddModal}
                loading={isReadingRingGroup || isReadingCallQueue}
                setOpen={(state) => {
                    setOpenAddModal(state);
                }}
                close={setOpenAddModal}
                data={editData || {}}
                getGroups={onReload}
            />
        </div>
    );
};

export default () => (
    <BulkActionsProvider>
        <RingGroupCallQueues />
    </BulkActionsProvider>
);
