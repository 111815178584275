import React from 'react';
import UserExtensionsHeader from './UserExtensionsHeader';
import UserExtensionsRow from './UserExtensionsRow';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyListPlaceholder, Notification, STATUS_TYPES, Table, TableLoader } from 'dyl-components';
import pbxConfigActions from 'actions/pbx_config';

const UserExtensionsTable = ({ isFiltering, onReload, onOpenModal }) => {
    const { userExtensionsCount: count, isReadingUserExtensions, userExtensions, isReadingUserExtension } = useSelector((state) => state.pbx_config);
    const dispatch = useDispatch();

    const formatData = (data) => data?.extensions?.filter((group) => group.active).map((group) => group.id);

    const onSave = async (data) => {
        try {
            const { id } = data;
            const payload = formatData(data);
            await dispatch(pbxConfigActions.updateUserGroups(id, payload))
            onReload();
            Notification.alert('Group updated successfully!', STATUS_TYPES.SUCCESS);
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to update group', STATUS_TYPES.ERROR);
        }
    }

    return <Table striped={false} className="CustomFieldsTable" fixed >
        <UserExtensionsHeader />
        <Table.Body>
            {!count && !isReadingUserExtensions && !isReadingUserExtension &&
                <Table.Row>
                    <Table.Cell colspan={10} style={{padding: "30px 60px 30px 60px"}}>
                        <EmptyListPlaceholder 
                            wrapped={false}
                            name="User Extension"
                            subtitleHidden 
                            {...(isFiltering ? { title: "No results found" } : {})} 
                        />
                    </Table.Cell>
                </Table.Row>
            }
            {!isReadingUserExtensions && !isReadingUserExtension && !!count && userExtensions?.map((userExtension, index) => 
                <UserExtensionsRow
                    key={index}
                    userExtension={userExtension}
                    onSave={onSave}
                    onOpenModal={onOpenModal}
                />
            )}
            {(isReadingUserExtensions || isReadingUserExtension) && (
                <TableLoader isLoading colspan={10} />
            )}
        </Table.Body>
    </Table>
}

export default UserExtensionsTable;