import React, { useContext } from "react";
import { Icon, Popup, Table as SemanticTable } from "semantic-ui-react";
import { DateTimeUtils, DYLAvatar, Table } from "dyl-components";

import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import { StringUtils } from "utils";
import QuoteID from "shared/QuoteID";
import { PAYMENT_NETWORK_ICONS } from "shared/constants/PAYMENT_NETWORK_ICONS";
import { useSelector } from "react-redux";

const PaymentInfo = ({ info }) => {
    const { payment_type: type, payment_account } = info;
    if (type === "eCheck") {
        return (
            <div>
                <Icon className={`fas fa-money-check-dollar`} />{" "}
                {payment_account.replace("XXXX", "****")}
            </div>
        );
    }
    if (type === "credit_card") {
        return (
            <div>
                <Icon className={PAYMENT_NETWORK_ICONS[info.payment_network]} />{" "}
                {payment_account.replace("XXXX", "****")}
            </div>
        );
    }
    return null;
};

const Cancellations = ({ order_id }) => {
    const { cancellations, cancellation_date, cancelled_by_email, cancelled_by_name, cancellation_id } = useSelector((state) => ({
        ...(state.order.cancellations[order_id] || {
            cancellations: [],
            cancellation_date: null,
            cancellation_id: null,
            cancelled_by_name: null,
            cancelled_by_email: null
        }),
    }));
    if (!cancellations?.length) {
        return null;
    }
    return (
        <SemanticTable color='red' striped={false}>
            <SemanticTable.Header>
                <SemanticTable.Row>
                    <SemanticTable.HeaderCell width={3}>
                        Cancellation Date
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell width={2}>
                        Cancellation ID
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell width={4}>
                        Cancelled By
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Products
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Price
                    </SemanticTable.HeaderCell>
                </SemanticTable.Row>
            </SemanticTable.Header>
            <Table.Body>
                {cancellations.map((cancellation) => (
                    <Table.Row key={cancellation.subscription_id}>
                        <SemanticTable.Cell disabled={cancellation.reverted}>
                            <div>
                                <b>
                                    {DateTimeUtils.formatEpoch(
                                        cancellation_date,
                                        DateTimeUtils.WORD_DATE_FORMAT
                                    )}
                                </b>
                            </div>
                        </SemanticTable.Cell>
                        <SemanticTable.Cell disabled={cancellation.reverted}>
                            {cancellation_id}
                        </SemanticTable.Cell>
                        <SemanticTable.Cell>
                            <DYLAvatar
                                name={cancelled_by_name}
                                email={cancelled_by_email}
                            />
                        </SemanticTable.Cell>
                        <SemanticTable.Cell>
                        </SemanticTable.Cell>
                        <SemanticTable.Cell disabled={cancellation.reverted}>
                            <div>{cancellation.product_name}</div>
                            <div>
                                ID: {cancellation.subscription_id}
                            </div>
                        </SemanticTable.Cell>
                        <SemanticTable.Cell>
                            {cancellation.price &&
                                `$${StringUtils.formatDecimal(
                                    `${cancellation.price}`,
                                    true
                                )}`}
                            {cancellation.reverted && (
                                <Popup 
                                    trigger={(
                                        <Icon
                                            className="fa-sharp fa-solid fa-arrow-rotate-left Pipeline__stage-undo-icon disabled"
                                            size="large"
                                            link
                                        />
                                    )}
                                    content="Reversed"
                                    inverted
                                    position="top left"
                                />
                            )}
                        </SemanticTable.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </SemanticTable>
    );
};

const Invoices = ({ order_id }) => {
    const { onViewInvoice } = useContext(QuoteBuilderContext);
    const { invoices } = useSelector((state) => ({
        ...(state.order.invoiceSummary[order_id] || {
            cancellations: [],
        }),
    }));
    return (
        <SemanticTable color='primary' striped={false}>
            <SemanticTable.Header>
                <SemanticTable.Row>
                    <SemanticTable.HeaderCell width={3}>
                        Invoice Date
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell width={2}>
                        Due On
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell className="OrderInvoicesTable__InvoiceIDColumn">
                        Invoice ID
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Billed Amount
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Payment Method
                    </SemanticTable.HeaderCell>
                    <SemanticTable.HeaderCell>
                        Invoice Status
                    </SemanticTable.HeaderCell>
                </SemanticTable.Row>
            </SemanticTable.Header>
            <Table.Body>
                {invoices.map((invoice) => (
                    <Table.Row>
                        <SemanticTable.Cell>
                            <div>
                                <b>
                                    {DateTimeUtils.formatEpoch(
                                        invoice.created,
                                        DateTimeUtils.WORD_DATE_FORMAT
                                    )}
                                </b>
                            </div>
                        </SemanticTable.Cell>
                        <SemanticTable.Cell>
                            {invoice.due_on > 0 && (
                                <div>
                                    <b>
                                        {DateTimeUtils.formatEpoch(
                                            invoice.due_on,
                                            DateTimeUtils.WORD_DATE_FORMAT
                                        )}
                                    </b>
                                </div>
                            )}
                        </SemanticTable.Cell>
                        <SemanticTable.Cell>
                            <QuoteID
                                hasPdf
                                id={invoice.id}
                                onEditQuote={() => {
                                    onViewInvoice(invoice.id);
                                }}
                                onViewProposal={() => {
                                    onViewInvoice(invoice.id);
                                }}
                            />
                        </SemanticTable.Cell>
                        <SemanticTable.Cell>
                            {invoice.amount &&
                                `$${StringUtils.formatDecimal(
                                    `${invoice.amount}`,
                                    true
                                )}`}
                        </SemanticTable.Cell>
                        <SemanticTable.Cell>
                            <PaymentInfo info={invoice.payment_data} />
                        </SemanticTable.Cell>
                        <SemanticTable.Cell>
                            {StringUtils.capitalize(invoice.invoice_status)}
                        </SemanticTable.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </SemanticTable>
    );
};

const Subrows = ({ order_id }) => {
    return (
        <Table.CollapsibleRowContent>
            <Table.Cell width={1} />
            <Table.Cell colSpan={7}>
                <Cancellations order_id={order_id} />
                <Invoices order_id={order_id} />
            </Table.Cell>
        </Table.CollapsibleRowContent>
    );
};

export default Subrows;
