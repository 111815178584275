import { ButtonLink, NestedDropdown } from "dyl-components";
import React from "react";
import { Controller } from "react-hook-form";
import { Form, Icon } from "semantic-ui-react";
import { PhoneUtil } from "utils";

const Destination = ({ control, isReadingDestinations, destinationOptions, index, isDeleteVisible, setHasEndOfLine, isEndOfLine, setValue, onRemove, onMove, destinationCount, provided, hasEndOfLine }) => {
    const PARENT_NAME = `items[${index}]`;
    return (
        <div
            {...(!isEndOfLine && {ref: provided.innerRef, ...provided.draggableProps})}
            className="DestinationGroup__destinationContainer"
        >
            <div
                {...(!isEndOfLine && {...provided.dragHandleProps})}
                className="DestinationGroup___elementContainer"
            >
                    <Icon className={`fa-solid fa-arrows-up-down-left-right ${isEndOfLine && "DestinationGroup__invisibleIcon"}`} />
            </div>
            <div className="DestinationGroup___elementContainer">
                <span>{index + 1}</span>
            </div>
            <Controller
                name={`${PARENT_NAME}.destination_parent`}
                control={control}
                render={({ field: { name: parentName, value: parentValue, onChange: onParentChange } }) => (
                    <Controller 
                        name={`${PARENT_NAME}.destination_id`}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                            const { name: childName, value: childValue, onChange: onChildChange } = field;
                            return (
                                <Form.Field
                                    control={NestedDropdown}
                                    child_value={childValue}
                                    parent_value={parentValue}
                                    loading={isReadingDestinations}
                                    nested_options={destinationOptions}
                                    onChange={(_, { parent_value, child_value }) => {
                                        onParentChange({ target: { name: parentName, value: parent_value } });
                                        onChildChange({ target: { name: childName, value: child_value } });
                                        if (PhoneUtil.END_OF_LINE_TYPES.includes(parent_value)) {
                                            setHasEndOfLine(true);
                                            setValue(`${PARENT_NAME}.ring_time`, null)
                                            onMove(index, destinationCount - 1);
                                        } else if (PhoneUtil.END_OF_LINE_TYPES.includes(parentValue)) {
                                            setHasEndOfLine(false);
                                        }
                                    }}
                                    placeholder="Select destination"
                                    display_parent
                                    selection
                                    label="Destination"
                                    pointing='top'
                                    required={index === 0}
                                    error={error?.message}
                                    className="DestinationGroups__destinationField"
                                    excluded_parents={hasEndOfLine ? PhoneUtil.END_OF_LINE_TYPES : PhoneUtil.DESTINATION_GROUPS}
                                />
                            )
                        }}
                    />
                )}
            />
            {!isEndOfLine && (
                <Controller
                    name={`${PARENT_NAME}.ring_time`}
                    control={control}
                    render={({ field: { name, value, onChange } }) => (
                        <Form.Select
                            name={name}
                            value={value}
                            onChange={(_, { value: newValue }) => {
                                if (!newValue) {
                                    onChange({ target: { name, value: 30 } }) 
                                } else {
                                    onChange({ target: { name, value: newValue } }) 
                                }
                            }}
                            placeholder='Select ring duration'
                            selectOnBlur={false}
                            options={PhoneUtil.RING_DURATION_OPTIONS}
                            label="Ring Duration"
                            className="DestinationGroups__destinationField"
                            clearable
                        />
                    )}
                />
            )}
            {isDeleteVisible && (
                <ButtonLink onClick={() => onRemove(index)} className="DestinationGroup___elementContainer">
                    <Icon className="fa-solid fa-trash DestinationGroupModal__redIcon" />
                </ButtonLink>
            )}
        </div>
    )
}

export default Destination;
