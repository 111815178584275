import { Modal, ControlledPopup, Button } from 'dyl-components';
import React from 'react';
import TaskForm from 'shared/NewTasksTab/subcomponents/TaskForm';
import { Icon } from 'semantic-ui-react';

const AddTaskModal = ({ open,
    onClose,
    taskBeingEdited,
    isAllowedToModify = true,
    onToggleFormTab,
    control,
    watch,
    isValid,
    isDirty,
    trigger,
    setValue,
    getValues,
    reset,
    state,
    addTask,
    onDelete,
    onUpdate,
    onRefresh,
    isDeletingTask,
    isReadingTask,
    task_labels,
    isReadingTaskLabels,
    record_type,
    organizer,
    organizer_email,
    organizer_id,
    clearErrors,
    setError,
    handleSubmit,
    email,
    hiddenContactSelector = false
}) => {
    const onSave = (values) => addTask(values, onRefresh);
    const onEdit = (values) => onUpdate(values, onRefresh)
    const onDeleteTask = () => onDelete(taskBeingEdited.id, onRefresh)

    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>{taskBeingEdited?.name ? taskBeingEdited.name : "New task"}</Modal.Header>
            <Modal.Content scrolling>
                <TaskForm
                    isModal
                    hiddenContactSelector={hiddenContactSelector}
                    relatedTo={state.relatedTo}
                    isAllowedToModify={isAllowedToModify}
                    isTaskTypeDisabled={taskBeingEdited?.name}
                    onClose={onToggleFormTab}
                    refresh={() => {}}
                    {...taskBeingEdited}
                    isSaving={state.saving}
                    isDeleting={isDeletingTask}
                    isReading={isReadingTask}
                    taskTypes={[
                        {
                            key: 'To-Do', value: 'To-Do', text: (
                                <div>
                                    <Icon className='fad fa-check-circle' color='blue' /> To Do
                                </div>
                            )
                        },
                        {
                            key: 'Call', value: 'Call', text: (
                                <div>
                                    <Icon flipped className='fas fa-circle-phone' color='blue' /> Call
                                </div>
                            )
                        },
                        {
                            key: 'Text', value: 'Text', text: (
                                <div>
                                    <Icon className='fas fa-comment' color='blue' /> Text
                                </div>
                            )
                        },
                        {
                            key: 'Email', value: 'Email', text: (
                                <div>
                                    <Icon className='fas fa-circle-envelope' color='blue' /> Email
                                </div>
                            )
                        }
                    ]}
                    task_labels={task_labels}
                    isReadingLabels={isReadingTaskLabels}
                    searchValue={organizer}
                    recordType={record_type}
                    organizer={organizer}
                    organizer_email={organizer_email}
                    organizer_id={organizer_id}
                    control={control}
                    watch={watch}
                    isValid={isValid}
                    isDirty={isDirty}
                    trigger={trigger}
                    setValue={setValue}
                    getValues={getValues}
                    clearErrors={clearErrors}
                    setError={setError}
                    reset={reset}
                    onUpdate={onEdit}
                    email={email}
                />
            </Modal.Content>
            <Modal.Actions hasSaveButton onSave={taskBeingEdited?.id ? handleSubmit(onEdit) : handleSubmit(onSave)} saveDisabled={!isValid || !isDirty} >
                {taskBeingEdited?.id && (
                    <ControlledPopup
                        trigger={<Button status={"cancel"} basic disabled={!isAllowedToModify}>Delete</Button>}
                        onConfirm={onDeleteTask}
                    />
                )}
            </Modal.Actions>
        </Modal>
    )
}

export default AddTaskModal;