import React from 'react';
import { TableWithHeader } from 'dyl-components';
import TaskTypeHeader from './subcomponents/TaskTypeHeader';
import TaskTypesTable from './subcomponents/TaskTypesTable';

const TaskTypes = () => (
    <TableWithHeader 
        header={
            <TaskTypeHeader />
        }
        table={
            <TaskTypesTable />
        }
    />
)

class TaskTypeContainer extends React.Component {
    state = {
        isAddTaskTypeSectionOpen: false
    }

    onOpenAddTaskTypeSection = () => {
        this.setState({ isAddTaskTypeSectionOpen: true });
    }

    onCloseAddTaskTypeSection = () => {
        this.setState({ isAddTaskTypeSectionOpen: false });
    }

    render() {
        return (
            <TaskTypes />
        )
    }
}

export default TaskTypeContainer;
