import React, { useCallback, useEffect, useState } from 'react';

import './index.scss';
import { Header, Icon } from 'semantic-ui-react';
import { OfficeViewContainer, OfficeViewHeader, OfficeViewLeftPanel, OfficeViewDialPad, generateResolver, yup, OfficeViewContent, OfficeViewPhoneMiddle, OfficeViewCompanyDestinations, Notification, STATUS_TYPES } from 'dyl-components';
import { useForm } from 'react-hook-form';
import contactsActions from 'actions/contacts';
import { useDispatch, useSelector } from 'react-redux';
import pbxConfigActions from 'actions/pbx_config';
import SendEmailModal from 'shared/modals/SendEmailModal';
import AddTaskModal from 'shared/modals/AddTaskModal';
import useTaskForm from 'utils/useTaskForm';
import oauthActions from 'actions/oauth';
import eventActions from "actions/event";
import groupsActions from "actions/groups";
import eventAttendeeActions from "actions/event_attendee";
import eventAttachmentActions from "actions/event_attachment";
import accountActions from 'actions/account';
import uploadActions from "actions/upload";
import AddEventModal from 'shared/modals/AddEventModal';
import useEventForm from 'utils/useEventForm';
import Utils from 'shared/EventForm/Utils';
import { FILE_CATEGORIES } from "utils/FileUtils";
import NewActivityForm from 'shared/forms/NewActivity';

const LIMIT = 15;
const PINNED_COMPANY_DESTINATIONS_LIMIT = 3;

const OfficeView = ({ isActive, onClose }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [search, setSearch] = useState("");
    const [searchMade, setSearchMade] = useState("");
    const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);
    const [page, setPage] = useState(1);
    const [isDialDisabled, setIsDialDisabled] = useState(false);
    const [isCompanyExtensionExpanded, setIsCompanyExtensionExpanded] = useState(false);
    const [displayedCompanyDestinations, setDisplayedCompanyDestinations] = useState(false);
    const [isPinnedDestinationsMaxed, setIsPinnedDestinationsMaxed] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
    const [isEventModalOpen, setIsEventModalOpen] = useState(false);
    const [isLogActivityModalOpen, setIsLogActivityModalOpen] = useState(false);

    const current_user = useSelector((state) => state.auth);
    const { isSearchingContacts, contacts_searched_count, contact_lookup } = useSelector((state) => state.contacts);
    const { viewDestinations, isReadingViewDestinations } = useSelector((state) => state.pbx_config);
    const activeIntegration = useSelector(state => state.oauth_integrations.activeIntegration);
    const task_labels = useSelector((state) =>
        state.task.task_type_labels.map(({ name, id }) => ({
            key: id,
            value: id,
            text: name,
        }))
    );
    const { organizer_id, organizer, organizer_email } = useSelector(
        (state) => {
            const {
                first_name = "",
                last_name = "",
                email: organizer_email,
            } = state.users.userProfile;
            return {
                organizer_id: state.auth.user_id,
                organizer: `${first_name} ${last_name}`,
                organizer_email,
            };
        }
    );
    
    const accountContacts = useSelector((state) => {
        return state.account.contactIds.map(({ first_name, last_name, email, suffix, id }) => ({
            text: `${first_name || ''} ${last_name || ''}${suffix ? `, ${suffix}` : ''}`,
            email,
            key: id,
            value: id
        }))
    })
    const contactsInHotlist  = useSelector((state) => {
        return state.groups.contactsInHotlist;
    })

    const {
        control: eventControl,
        watch: eventWatch,
        isValid: eventIsValid,
        isDirty,
        trigger: eventTrigger,
        setValue: eventSetValue,
        getValues,
        reset,
        isAllowedToModify,
        eventBeingEdited,
        loadEvent,
        handleSubmit: handleSubmitEvent,
        resetField,
    } = useEventForm({
        ...(contact_lookup?.contact_id && { contact_id: contact_lookup.contact_id })
    });

    const {
        control: taskControl,
        watch: taskWatch,
        isValid: taskIsValid,
        isDirty: taskIsDirty,
        trigger: taskTrigger,
        setValue: taskSetValue,
        getValues: taskGetValues,
        clearErrors: taskClearErrors,
        setError: taskSetError,
        reset: taskReset,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDelete: onDeleteTask,
        onUpdate: onUpdateTask,
        handleSubmit,
        isAllowedToModify: isTaskAllowedToModify
    } = useTaskForm({
        isContact: false,
        isModal: true,
        open: isTaskModalOpen,
    });

    const pages = [
            { id: 0, icon: "fa-solid fa-phone", component: OfficeViewPhoneMiddle },
            { id: 1, icon: "fa-solid fa-user-clock", component: () => <></> },
            { id: 2, icon: "fa-solid fa-comments", component: () => <></> },
            { id: 3, icon: "fa-solid fa-gear", component: () => <></> }
    ];

    const { formState: { isValid }, control, watch, setValue, trigger } = useForm({
        mode: 'onChange',
        defaultValues: {
            dial: "",
        },
        resolver: generateResolver({
            dial: yup.string().minlength(3).required("")
        })
    });

    const dispatch = useDispatch();

    const dialedNumber = watch("dial");

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
        if (!value) {
            setIsSearchResultsVisible(false);
        }
    }

    const searchFunction = async () => {
        try {
            setPage(1);
            setSearchMade(search);
            setIsSearchResultsVisible(true);
            await dispatch(contactsActions.contactSearch({ page: 1, search, limit: LIMIT }));
        } catch (e) {
            console.log(e)
        }
    }

    const onPersonSelect = async (id) => {
        try {
            await dispatch(contactsActions.viewLookup(id));
            setPage(1);
            setSearch('');
            setIsSearchResultsVisible(false);
            setSearchMade("");
        } catch (error) {
            console.log(error)
        }
    }

    const handleNumberClick = (number) => {
        setValue('dial', number);
        trigger('dial');
    }

    const cancelFunction = async () => {
        setSearch("");
        setPage(1);
        setIsSearchResultsVisible(false);
        setSearchMade("");
    }

    const onPageChange = async (_, { activePage }) => {
        await dispatch(contactsActions.contactSearch({ page: activePage, search, limit: LIMIT }));
        setIsSearchResultsVisible(true);
        setPage(activePage);
    }

    const onRemovePerson = () => {
        dispatch(contactsActions.removeViewLookup());
    }

    const handleClose = useCallback(() => {
        cancelFunction();
        onClose();
    }, [onClose])

    const toggleIsExpanded = async () => {
        setIsCompanyExtensionExpanded((prevValue) => !prevValue)
        await dispatch(pbxConfigActions.viewDestinations());
    }

    const togglePinned = async (destination_id, pinned) => {
        try {
            await dispatch(pbxConfigActions.setPinned({ destination_id, pinned }));
            await dispatch(pbxConfigActions.viewDestinations());
            Notification.alert(`Company destination ${pinned ? "pinned" : "unpinned"} successfully`, STATUS_TYPES.SUCCESS);
        } catch (error) {
            Notification.alert(`Failed to ${pinned ? "pin" : "unpin"} company destination`, STATUS_TYPES.ERROR);
            console.log(error)
        }
    }

    const onEmailClick = () => {
        setIsEmailModalOpen(true);
    }

    const onTaskClick = () => {
        loadTask({
            contact: contact_lookup.contact_id
        });
        setIsTaskModalOpen(true);
    }

    const onEventClick = () => {
        loadEvent(null);
        setIsEventModalOpen(true);
    }

    const onAddEvent = async (event) => {
        const id = await dispatch(eventActions.addEvent(Utils.toPayload(event)));
        return id;
    };
    const onAddAttendees = (event_id, params) => {
        return dispatch(
            eventAttendeeActions.addAttendees(params, null, event_id)
        );
    };

    const addAttendees = (event_id, usersToAdd) => {
        let params = {
            payload: usersToAdd,
            integration_id: null,
            access_token: null,
        };
        if (usersToAdd.length > 0) {
            return onAddAttendees(event_id, params);
        }
        return Promise.resolve();
    };
    
    const onUpload = (file, file_type) => {
        return dispatch(uploadActions.uploadFiles(file, file_type));
    };

    const addAttachments = async (files, event_id) => {
        if (files && files.length > 0) {
            const toUpload = files.filter((file) => file.id === undefined);
            const toCopy = files.filter(file => file.id).map(file => ({
                file_id: file.file_id,
                name: file.name,
                size: file.size
            }));
            const uploadedFiles = await onUpload(toUpload, FILE_CATEGORIES.EVENT);
            const newFiles = toUpload.map((_, idx) => {    
                return {
                    name: files[idx].name,
                    file_id: uploadedFiles[idx],
                    size: files[idx].size
                }})
            return onAddAttachments(
                [
                    ...newFiles,
                    ...toCopy,
                ],
                current_user.user_id,
                event_id
            );
        }
        return Promise.resolve();
    };

    const onAddAttachments = (attachments, user_id, event_id) => {
        return dispatch(
            eventAttachmentActions.addAttachments(
                attachments,
                { user_id },
                event_id
            )
        );
    };

    const addEvent = async () => {
        const values = getValues();
        const {
            all_day,
            start_date,
            start_time,
            end_date,
            end_time,
            users,
            attachments,
            contacts,
            timezone,
            related_to
        } = values;

        const attendees = [
            ...contacts.map((contact_id) => ({
                accepted: "no",
                contact_id,
                emailed: false
            })),
            ...users.map((user_id) => ({
                accepted: "no",
                user_id,
                emailed: false
            })),
        ];

        // TODO: add recurring event properties
        const payload = {
            complete: false,
            conference_phone: values.phone_number,
            conference_pin: values.pin,
            conference_url: values.conference_line,
            content: values.content,
            content_type: "text/html",
            label: values.label ? values.label : 0,
            location: values.location,
            name: values.name,
            all_day,
            start_date,
            start_time,
            end_date,
            end_time,
            // "recurring_event_id": 0,
            organizer_id,
            organizer,
            organizer_email,
            users: values.users,
            contact_id: values.contacts,
            timezone,
            related_to
        };

        try {
            const { id } = await onAddEvent(payload);
            await addAttendees(id, attendees);
            await addAttachments(attachments, id, "pdf");
            setIsEventModalOpen(false);
            Notification.alert(
                "Successfully created event!",
                STATUS_TYPES.SUCCESS
            );
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to create event", STATUS_TYPES.ERROR);
        }
    };

    const onHotlistClick = async () => {
        try {
            if (contactsInHotlist.includes(contact_lookup?.contact_id)) {
                await dispatch(groupsActions.removeHotlist(contact_lookup?.contact_id));
                await dispatch(groupsActions.checkHotlist({ contacts: [contact_lookup?.contact_id] }));
                Notification.alert('Successfully removed contact from hotlist!', STATUS_TYPES.SUCCESS, true);
            } else {
                await dispatch(groupsActions.addToHotlist({ contact_id: [contact_lookup?.contact_id] }));
                await dispatch(groupsActions.checkHotlist({ contacts: [contact_lookup?.contact_id] }));
                Notification.alert('Successfully added contact to hotlist!', STATUS_TYPES.SUCCESS, true);
            }
        } catch (error) {
            console.log(error)
            if (contactsInHotlist.includes(contact_lookup?.contact_id)) {
                Notification.alert('Problem removing contact from hotlist!', STATUS_TYPES.ERROR, true);
            } else {
                Notification.alert('Problem adding contact to hotlist!', STATUS_TYPES.ERROR, true);
            }
        }
    }
    
    useEffect(() => {
        if (contact_lookup) {
            if (contact_lookup?.contact_id) {
                dispatch(groupsActions.checkHotlist({ contacts: [contact_lookup?.contact_id] }));
                if (contact_lookup.account_id) {
                    dispatch(accountActions.readContactIds(contact_lookup.account_id));
                }
            }
            const number = contact_lookup.contact_id ? (
                contact_lookup.contact_details?.phone?.[0]?.phone || null
            ) : (
                contact_lookup.account_details?.phone?.[0]?.phone || null
            )
            if (number) {
                setValue('dial', number);
                setIsDialDisabled(false);
                trigger('dial');
            } else {
                setValue('dial', "");
                setIsDialDisabled(true);
            }
        } else {
            setValue('dial', "");
            setIsDialDisabled(false);
        }
    }, [contact_lookup, setValue, trigger, dispatch])

    useEffect(() => {
        if (!isActive) {
            handleClose();
        }
    }, [isActive, handleClose])

    useEffect(() => {
        dispatch(pbxConfigActions.viewDestinations());
        dispatch(oauthActions.getIntegrations({ scopes: 'email', include_disabled: false }));
    }, [dispatch])

    useEffect(() => {
        const pinnedDestinations = viewDestinations.filter(({ pinned }) => !!pinned);
        const isPinnedDestinationsFull = pinnedDestinations.length >= PINNED_COMPANY_DESTINATIONS_LIMIT;
        setIsPinnedDestinationsMaxed(isPinnedDestinationsFull);
        if (isCompanyExtensionExpanded) {
            setDisplayedCompanyDestinations(viewDestinations)
        } else {
            setDisplayedCompanyDestinations(pinnedDestinations);
        }
    }, [viewDestinations, isCompanyExtensionExpanded])

    return (
        <div style={{padding: 30}}>
            <div style={{display: 'flex', marginBottom: 10}}>
                <Header style={{flex: 1}} as={"h1"}>Office View</Header>
                <Icon className='fa-solid fa-xmark closeIcon' size="big" onClick={handleClose} />
            </div>
            <div style={{ marginBottom: 25 }}>
                <OfficeViewContainer
                    header={ <OfficeViewHeader /> }
                    leftPanel={ <OfficeViewLeftPanel pages={pages} selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> }
                    rightPanel={ <OfficeViewDialPad control={control} dialedNumber={dialedNumber} dialButtonDisabled={!isValid} onDial={() => {}} /> }
                >
                    <OfficeViewContent 
                        pages={pages} 
                        selectedTab={selectedTab} 
                        onNumberClick={handleNumberClick}
                        search={searchMade}
                        isSearchResultsVisible={isSearchResultsVisible}
                        page={page}
                        isSearchingContacts={isSearchingContacts}
                        contacts_searched_count={contacts_searched_count}
                        contact_lookup={contact_lookup}
                        cancelFunction={cancelFunction}
                        searchFunction={searchFunction}
                        onChangeSearch={onChangeSearch}
                        onPersonSelect={onPersonSelect}
                        onPageChange={onPageChange}
                        onRemovePerson={onRemovePerson}
                        isDialDisabled={isDialDisabled}
                        onEmailClick={onEmailClick}
                        onTaskClick={onTaskClick}
                        onEventClick={onEventClick}
                        onHotlistClick={onHotlistClick}
                        hasIntegration={Object.keys(activeIntegration || {}).length > 0}
                        isInHotlist={contactsInHotlist.includes(contact_lookup?.contact_id)}
                        isLogActivityModalOpen={isLogActivityModalOpen}
                        setIsLogActivityModalOpen={setIsLogActivityModalOpen}
                        logActivityForm={(contact) => {
                            return <NewActivityForm readTimeline={() => {}} emailOnly={true} contact={contact} onClose={() => setIsLogActivityModalOpen(false)} />
                        }}
                    />
                </OfficeViewContainer>
            </div>
            <div>
                <OfficeViewCompanyDestinations
                    isExpanded={isCompanyExtensionExpanded}
                    toggleIsExpanded={toggleIsExpanded}
                    companyDestinations={displayedCompanyDestinations}
                    togglePinned={togglePinned}
                    isReadingViewDestinations={isReadingViewDestinations}
                    isPinnedDestinationsMaxed={isPinnedDestinationsMaxed}
                />
            </div>
            <SendEmailModal
                open={isEmailModalOpen}
                onClose={() => { setIsEmailModalOpen(false); }}
                contact_id={ contact_lookup?.contact_id || contact_lookup?.account_id }
            />
            <AddTaskModal
                open={isTaskModalOpen}
                onClose={() => setIsTaskModalOpen(false)}
                state={state}
                control={taskControl}
                watch={taskWatch}
                isValid={taskIsValid}
                isDirty={taskIsDirty}
                trigger={taskTrigger}
                setValue={taskSetValue}
                getValues={taskGetValues}
                clearErrors={taskClearErrors}
                setError={taskSetError}
                reset={taskReset}
                taskBeingEdited={taskBeingEdited}
                loadTask={loadTask}
                addTask={addTask}
                setState={setState}
                onDelete={onDeleteTask}
                onUpdate={onUpdateTask}
                task_labels={task_labels}
                organizer_id={organizer_id}
                organizer={organizer}
                organizer_email={organizer_email}
                handleSubmit={handleSubmit}
                onRefresh={() => setIsTaskModalOpen(false)}
                email={{body:"", subject:""}}
                isAllowedToModify={isTaskAllowedToModify}
                hiddenContactSelector
            />
            <AddEventModal
                open={isEventModalOpen}
                onClose={() => setIsEventModalOpen(false)}
                onSave={handleSubmitEvent(addEvent)}
                selected_users={[]}
                isAllowedToModify={isAllowedToModify}
                control={eventControl}
                watch={eventWatch}
                isValid={eventIsValid}
                isDirty={isDirty}
                trigger={eventTrigger}
                setValue={eventSetValue}
                getValues={getValues}
                reset={reset}
                resetField={resetField}
                eventBeingEdited={eventBeingEdited}
                setNotificationContactName={() => {}}
                accountContacts={accountContacts}
                currentContact={contact_lookup?.contact_id && {
                    text: contact_lookup.name, key: contact_lookup.contact_id, email: contact_lookup.contact_details?.email || ""
                }}
            />
        </div>
    )
}

export default OfficeView;
