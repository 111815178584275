import React from 'react';

import { Form, ListItem, Grid } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import UserSearchField from 'shared/forms/UserSearchField';
import ContactSearchField from 'shared/forms/ContactSearchField';
import ContactOptions from '../subcomponents/ContactOptions'

export default function AttendeesOptions(props) {
    const {
        control,
        trigger,
        contacts,
        isReadOnly,
        id,
        onContactsChange,
        usersSelected,
        contactsSelected,
        user_id,
        displayAllContacts = false
    } = props
    return <>
        <ListItem
            icon='users'
            className='Event__guests'
            description={(
                <Grid>
                    <Grid.Row columns='equal'>
                        <Grid.Column>
                            <Controller
                                name='contacts'
                                control={control}
                                render={({ field: { onChange, value, name } }) => displayAllContacts ? (
                                    <Form.Field
                                        control={ContactSearchField}
                                        excluded_contacts={[...value]}
                                        label='Guests'
                                        onChange={(_, { name, value: newValue, contactName }) => {
                                            onChange({ target: { name, value: newValue } });
                                            onContactsChange(contactName);
                                        }}
                                        multiple
                                        value={value}
                                        placeholder="Select Contacts"
                                        selected={contactsSelected}
                                        selected_outside
                                        name={name}
                                        disabled={isReadOnly}
                                    />
                                ) : (
                                    <Form.Field
                                        control={ContactOptions}
                                        label='Guests'
                                        placeholder='Select Contacts'
                                        name={name}
                                        disabled={isReadOnly}
                                        onChange={(_, { name, value }) => {
                                            onChange({
                                                target: {
                                                    name, value
                                                }
                                            });
                                            trigger('users');
                                            onContactsChange();
                                        }}
                                        options={contacts || []}
                                        value={value}
                                    />
                                )}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Controller
                                name='users'
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <Form.Field
                                        key={id}
                                        className="Event__no-label--Error-fix"
                                        control={UserSearchField}
                                        excluded_users={[...value, user_id]}
                                        onChange={(_, { name, value: newValue }) => {
                                            onChange({ target: { name, value: newValue } });
                                        }}
                                        multiple
                                        value={value}
                                        placeholder="Select Users"
                                        selected={usersSelected || []}
                                        selected_outside
                                        name={name}
                                        disabled={isReadOnly}
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            )}
        />
    </>
}
