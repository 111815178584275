import React from 'react';

import './index.scss';
import { Icon} from 'semantic-ui-react';
import { ClippedContent } from '../ClippedContent';
import { Link } from 'react-router-dom';

const OfficeViewDestinationActiveCall = ({ call }) => {
    return (
        <div className='CompanyDestination__callContainer'>
            <div className='CompanyDestination__callLeft'>
                <Icon size={"large"} className='fa-solid fa-up-down-left-right CompanyDestination__callDnd' />
                <Icon size={"large"} className='fa-solid fa-phone-plus' style={{marginRight: 7}} />
                <div>
                    <ClippedContent>
                        <Link to={"_"}>
                            {call.name}
                        </Link>
                    </ClippedContent>
                </div>
            </div>
            <div className='CompanyDestination__callRight'>
                <Icon size={"large"} className='fa-solid fa-arrow-right-arrow-left CompanyDestination__callArrows' />
                <span style={{marginRight: 7}}>{call.duration}</span>
            </div>
        </div>
    )
}

export default OfficeViewDestinationActiveCall;

