import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function quoteReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.QUOTE, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreating: true };
        case getNamedAction(ACTION_NAMES.QUOTE, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreating: false };
        case getNamedAction(ACTION_NAMES.QUOTE, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreating: false };

        case getNamedAction(ACTION_NAMES.QUOTE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, quoteBeingRead: action.id, quoteError: false };
        case getNamedAction(ACTION_NAMES.QUOTE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, quoteBeingRead: null, quote: action.data };
        case getNamedAction(ACTION_NAMES.QUOTE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, quoteBeingRead: null, quote: null, quoteError: true };

        case getNamedAction(ACTION_NAMES.QUOTE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, quoteBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.QUOTE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, quoteBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.QUOTE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, quoteBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.OPPORTUNITY_RECENT_QUOTE, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, recentQuoteBeingRead: action.id, recentQuote: null };
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_RECENT_QUOTE, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, recentQuoteBeingRead: null, recentQuote: action.data };
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_RECENT_QUOTE, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, recentQuoteBeingRead: null, recentQuote: null };

        case getNamedAction(ACTION_NAMES.OPPORTUNITY_TAB_VIEW_QUOTES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, opportunityQuotes: { ...state.opportunityQuotes, [action.id]: { isReading: true, quotes: [], count: 0 } } };
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_TAB_VIEW_QUOTES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, opportunityQuotes: { ...state.opportunityQuotes, [action.id]: { isReading: false, quotes: action.data?.data || [], count: action.data?.count || 0 } } };
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_TAB_VIEW_QUOTES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, opportunityQuotes: { ...state.opportunityQuotes, [action.id]: { ...state.opportunityQuotes[action.id], isReading: false } } };

        case getNamedAction(ACTION_NAMES.QUOTE_VERSION, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingVersion: true };
        case getNamedAction(ACTION_NAMES.QUOTE_VERSION, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingVersion: false };
        case getNamedAction(ACTION_NAMES.QUOTE_VERSION, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingVersion: false };

        case getNamedAction(ACTION_NAMES.QUOTE_VERSION, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, quoteVersionBeingRead: action.id, quoteVersionError: false };
        case getNamedAction(ACTION_NAMES.QUOTE_VERSION, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, quoteVersionBeingRead: null, quoteVersion: action.data };
        case getNamedAction(ACTION_NAMES.QUOTE_VERSION, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, quoteVersionBeingRead: null, quoteVersion: null, quoteVersionError: true };

        case getNamedAction(ACTION_NAMES.QUOTE_VERSIONS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, quoteVersionsBeingRead: action.id, quoteVersions: [] };
        case getNamedAction(ACTION_NAMES.QUOTE_VERSIONS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, quoteVersionsBeingRead: null, quoteVersions: action.data?.data || [] };
        case getNamedAction(ACTION_NAMES.QUOTE_VERSIONS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, quoteVersionsBeingRead: null, quoteVersions: [] };

        case getNamedAction(ACTION_NAMES.QUOTE_VERSIONS_SUMMARY, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, quoteVersionsSummary: { ...state.quoteVersionsSummary, [action.id]: { isReading: true, versions: [], count: 0 } } };
        case getNamedAction(ACTION_NAMES.QUOTE_VERSIONS_SUMMARY, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, quoteVersionsSummary: { ...state.quoteVersionsSummary, [action.id]: { isReading: false, versions: action.data?.data || [], count: (action.data?.data || []).count || 0 } } };
        case getNamedAction(ACTION_NAMES.QUOTE_VERSIONS_SUMMARY, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, quoteVersionsSummary: { ...state.quoteVersionsSummary, [action.id]: { ...state.quoteVersionsSummary[action.id], isReading: false } } };

        case getNamedAction(ACTION_NAMES.QUOTE_CONTACT_INFORMATION, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, quoteContactInfoBeingRead: action.id };
        case getNamedAction(ACTION_NAMES.QUOTE_CONTACT_INFORMATION, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, quoteContactInfoBeingRead: null };
        case getNamedAction(ACTION_NAMES.QUOTE_CONTACT_INFORMATION, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, quoteContactInfoBeingRead: null };

        case getNamedAction(ACTION_NAMES.QUOTE_STATUS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, quoteBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.QUOTE_STATUS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, quoteBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.QUOTE_STATUS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, quoteBeingUpdated: null };
    
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_RECENT_QUOTE, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, opportunityBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_RECENT_QUOTE, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, opportunityBeingUpdated: null };
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_RECENT_QUOTE, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, opportunityBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.QUOTE_ADDRESS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, quoteBeingUpdated: action.id };
        case getNamedAction(ACTION_NAMES.QUOTE_ADDRESS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, quoteBeingUpdated: null, };
        case getNamedAction(ACTION_NAMES.QUOTE_ADDRESS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, quoteBeingUpdated: null };

        case getNamedAction(ACTION_NAMES.QUOTE_ORDER, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, opportunityBeingUpsold: action.id, orderQuote: null };
        case getNamedAction(ACTION_NAMES.QUOTE_ORDER, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, opportunityBeingUpsold: null, orderQuote: action.data };
        case getNamedAction(ACTION_NAMES.QUOTE_ORDER, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, opportunityBeingUpsold: null, orderQuote: null };

        case getNamedAction(ACTION_NAMES.OPPORTUNITY_QUOTE_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingOpportunityQuoteOptions: true, opportunityQuoteOptions: [] };
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_QUOTE_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingOpportunityQuoteOptions: false, opportunityQuoteOptions: action.data || [] };
        case getNamedAction(ACTION_NAMES.OPPORTUNITY_QUOTE_OPTIONS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingOpportunityQuoteOptions: false, opportunityQuoteOptions: [] };

        default:
            return state;
    }
}


export default quoteReducer;
