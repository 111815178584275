import initialState from "./initialState";
import CRUD_ACTION_TYPES, { getNamedAction } from "actions/CRUD_ACTION_TYPES";
import ACTION_NAMES from "actions/ACTION_NAMES";

function pbxConfigReducer(state = initialState, action) {
    switch (action.type) {
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingRecordings: true, recordings: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingRecordings: false, recordings: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingRecordings: false, recordings: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingDylNumbers: true, dylNumbers: [], dylNumbersCount: 0 };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingDylNumbers: false, dylNumbers: action.data.data, dylNumbersCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingDylNumbers: false, dylNumbers: [], dylNumbersCount: 0 };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingDestinations: true, destinations: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingDestinations: false, destinations: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingDestinations: false, destinations: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingDestinationGroups: true, destinationGroups: [], destinationGroupsCount: 0 };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingDestinationGroups: false, destinationGroups: action.data.data, destinationGroupsCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingDestinationGroups: false, destinationGroups: [], destinationGroupsCount: 0 };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCallRoutingRules: true, callRoutingRules: [], callRoutingRulesCount: 0 };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCallRoutingRules: false, callRoutingRules: action.data.data, callRoutingRulesCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCallRoutingRules: false, callRoutingRules: [], callRoutingRulesCount: 0 };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingDestinationGroup: true, destinationGroup: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingDestinationGroup: false, destinationGroup: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingDestinationGroup: false, destinationGroup: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isSavingDestinationGroups: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isSavingDestinationGroups: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUPS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isSavingDestinationGroups: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isEditingDestinationGroup: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isEditingDestinationGroup: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isEditingDestinationGroup: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingDestinationGroup: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingDestinationGroup: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_GROUP, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingDestinationGroup: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingDylNumber: true, dylNumber: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingDylNumber: false, dylNumber: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingDylNumber: false, dylNumber: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_EXTENSION, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCompanyExtensions: true }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_EXTENSION, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCompanyExtensions: false, companyExtensions: action.data }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_EXTENSION, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCompanyExtensions: false, companyExtensions: {} }

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_LIST_EXTENSIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingExtensionsList: true }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_LIST_EXTENSIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingExtensionsList: false, extensionsList: action.data?.data, count: action.data?.count }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_LIST_EXTENSIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingExtensionsList: false, extensionsList: [], count:0 }

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_UNUSED_EXTENSIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUnusedExtensions: true }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_UNUSED_EXTENSIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUnusedExtensions: false, unusedExtensions: action.data }
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_UNUSED_EXTENSIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUnusedExtensions: false, unusedExtensions: [] }

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingRecordings: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingRecordings: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_RECORDINGS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingRecordings: false  };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingPreferences: true, preferences: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingPreferences: false, preferences: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingPreferences: false, preferences: null };
            
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingPreferences: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingPreferences: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PREFERENCES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingPreferences: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingDylNumber: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingDylNumber: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DYLNUMBERS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingDylNumber: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingCallRoutingRules: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingCallRoutingRules: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingCallRoutingRules: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCallRoutingRule: true, callRoutingRule: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCallRoutingRule: false, callRoutingRule: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCallRoutingRule: false, callRoutingRule: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_VOICEMAIL, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingCompanyVoicemail: true, companyVoicemail: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_VOICEMAIL, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingCompanyVoicemail: false, companyVoicemail: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_COMPANY_VOICEMAIL, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingCompanyVoicemail: false, companyVoicemail: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_CONFERENCE_ROOM, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingConferenceRoom: true, conferenceRoom: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_CONFERENCE_ROOM, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingConferenceRoom: false, conferenceRoom: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_CONFERENCE_ROOM, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingConferenceRoom: false, conferenceRoom: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PARK, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingParkingLot: true, parkingLot: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PARK, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingParkingLot: false, parkingLot: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_PARK, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingParkingLot: false, parkingLot: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingCallRoutingRule: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingCallRoutingRule: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_ROUTING_RULES, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingCallRoutingRule: false };

        case getNamedAction(ACTION_NAMES.PBX_LIST_PHONES, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingListPhones: true, pbxListPhones: [] };
        case getNamedAction(ACTION_NAMES.PBX_LIST_PHONES, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingListPhones: false, pbxListPhones: action.data };
        case getNamedAction(ACTION_NAMES.PBX_LIST_PHONES, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingListPhones: false, pbxListPhones: [] };
        
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUNDS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSounds: true, sounds: [], soundsCount: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUNDS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingSounds: false, sounds: action.data.data, soundsCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUNDS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSounds: false, sounds: [], soundsCount: null  };
        
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUNDS, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingSounds: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUNDS, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingSounds: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUNDS, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingSounds: false };
    
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUND, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingSound: true, sound: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUND, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingSound: false, sound: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUND, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingSound: false, sound: null };
    
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUND, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingSound: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUND, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingSound: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUND, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingSound: false };
        
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_SOUND, CRUD_ACTION_TYPES.DELETE_REQUEST):
            return { ...state, isDeletingSound: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_SOUND, CRUD_ACTION_TYPES.DELETE_SUCCESS):
            return { ...state, isDeletingSound: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_DESTINATION_SOUND, CRUD_ACTION_TYPES.DELETE_FAILURE):
            return { ...state, isDeletingSound: false };
        
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUND_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingSoundOptions: true, soundOptions: [] };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUND_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingSoundOptions: false, soundOptions: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_SOUND_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingSoundOptions: false, soundOptions: [] };
        
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_EXTENSION, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUserExtensions: true, userExtensions: [] };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_EXTENSION, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUserExtensions: false, userExtensions: action.data?.data || [], userExtensionsCount: action.data?.count || 0 };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_EXTENSION, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUserExtensions: false, userExtensions: [] };
        
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_EXTENSION, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingUserExtension: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_EXTENSION, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingUserExtension: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_EXTENSION, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingUserExtension: false };
        
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_GROUPS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUserGroups: true, userGroups: {...state.userGroups} };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_GROUPS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUserGroups: false, userGroups: {...state.userGroups, [action.data.extension]: action.data?.data}};
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_GROUPS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUserGroups: false, userGroups: {...state.userGroups} };
        
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_GROUPS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingUserGrups: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_GROUPS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingUserGrups: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USER_GROUPS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingUserGrups: false };

            case getNamedAction(ACTION_NAMES.PBX_CONFIG_WITH_EXTENSIONS, CRUD_ACTION_TYPES.READ_REQUEST):
                return { ...state, isReadingUsersWithExtensions: true, usersWithExtensions: [] };
            case getNamedAction(ACTION_NAMES.PBX_CONFIG_WITH_EXTENSIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
                return { ...state, isReadingUsersWithExtensions: false, usersWithExtensions: action.data.data };
            case getNamedAction(ACTION_NAMES.PBX_CONFIG_WITH_EXTENSIONS, CRUD_ACTION_TYPES.READ_FAILURE):
                return { ...state, isReadingUsersWithExtensions: false, usersWithExtensions: [] };

        case 'REMOVE_SOUND':
            return { ...state, sound: null };
        
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_EXTENSION, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingUserExtension: true,  userExtension: null};
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_EXTENSION, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingUserExtension: false,  userExtension: action.data};
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_EXTENSION, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingUserExtension: false,  userExtension: null};

        case getNamedAction(ACTION_NAMES.CID_OPTIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCidOptions: true, cidOptions: [] };
        case getNamedAction(ACTION_NAMES.CID_OPTIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCidOptions: false, cidOptions: action.data };
        case getNamedAction(ACTION_NAMES.CID_OPTIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCidOptions: false, cidOptions: [] };

        case getNamedAction(ACTION_NAMES.CID_PREFS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingCidPrefs: true, cidPrefs: null };
        case getNamedAction(ACTION_NAMES.CID_PREFS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingCidPrefs: false, cidPrefs: action.data };
        case getNamedAction(ACTION_NAMES.CID_PREFS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingCidPrefs: false, cidPrefs: null };

        case getNamedAction(ACTION_NAMES.UPDATE_CID_PREFS, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingCidPrefs: true };
        case getNamedAction(ACTION_NAMES.UPDATE_CID_PREFS, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingCidPrefs: false };
        case getNamedAction(ACTION_NAMES.UPDATE_CID_PREFS, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingCidPrefs: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USERS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingUsers: true, users: [] };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USERS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingUsers: false, users: action.data.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_USERS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingUsers: false, users: [] };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingIvrs: true, ivrs: [], ivrCount: 0 };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingIvrs: false, ivrs: action.data.data, ivrCount: action.data.count };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingIvrs: false, ivrs: [], ivrCount: 0 };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.READ_ONE_REQUEST):
            return { ...state, isReadingIvr: true, ivr: null };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.READ_ONE_SUCCESS):
            return { ...state, isReadingIvr: false, ivr: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.READ_ONE_FAILURE):
            return { ...state, isReadingIvr: false, ivr: null };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.CREATE_REQUEST):
            return { ...state, isCreatingIvrPrompt: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.CREATE_SUCCESS):
            return { ...state, isCreatingIvrPrompt: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.CREATE_FAILURE):
            return { ...state, isCreatingIvrPrompt: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.UPDATE_REQUEST):
            return { ...state, isUpdatingIvrPrompt: true };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.UPDATE_SUCCESS):
            return { ...state, isUpdatingIvrPrompt: false };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_IVR, CRUD_ACTION_TYPES.UPDATE_FAILURE):
            return { ...state, isUpdatingIvrPrompt: false };

        case getNamedAction(ACTION_NAMES.PBX_CONFIG_VIEW_DESTINATIONS, CRUD_ACTION_TYPES.READ_REQUEST):
            return { ...state, isReadingViewDestinations: true, viewDestinations: [] };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_VIEW_DESTINATIONS, CRUD_ACTION_TYPES.READ_SUCCESS):
            return { ...state, isReadingViewDestinations: false, viewDestinations: action.data };
        case getNamedAction(ACTION_NAMES.PBX_CONFIG_VIEW_DESTINATIONS, CRUD_ACTION_TYPES.READ_FAILURE):
            return { ...state, isReadingViewDestinations: false, viewDestinations: [] };
        
        default:
            return state;
    }
}


export default pbxConfigReducer;
