import React, { useEffect, useRef, useState } from 'react';

import './index.scss';
import { Header, Icon, Popup } from 'semantic-ui-react';
import { PhoneUtil } from 'utils';
import { ClippedContent } from '../ClippedContent';
import OfficeViewDestinationActiveCall from '../OfficeViewDestinationActiveCall';

const OfficeViewCompanyDestination = ({ destination, togglePinned, pinButtonDisabled }) => {
    const [isCallsOpen, setIsCallsOpen] = useState(false);
    const [sectionWidth, setSectionWidth] = useState(0);
    const sectionRef = useRef(null);

    const handleOpen = () => {
        if (destination.activeCalls?.length > 0) {
            setIsCallsOpen(true)
        }
    }

    const handleClose = () => {
        setIsCallsOpen(false)
    }

    useEffect(() => {
        if (sectionRef?.current) {
            const sectionWidthAux = sectionRef.current.getBoundingClientRect().width;
            setSectionWidth(sectionWidthAux);
        }
    }, [])

    return (
        <Popup
            on={'click'}
            onOpen={handleOpen}
            onClose={handleClose}
            open={destination.activeCalls?.length > 0 && isCallsOpen}
            className='CompanyDestination__popup'
            offset={[0, -55]}
            position='bottom left'
            basic
            trigger={(
                <div ref={sectionRef} className={`CompanyDestination__container CompanyDestination__container${destination.activeCalls?.length || !pinButtonDisabled ? "--pointer" : "--noPointer"}`}>
                    <div className='CompanyDestination__content'>
                        <div onClick={(event) => {
                            if (!pinButtonDisabled) {
                                togglePinned(destination.destination_id, !destination.pinned);
                            }
                            event.stopPropagation();
                        }}>
                            <Icon
                                className={`fa-solid fa-thumbtack CompanyDestination__pin ${destination.pinned && "CompanyDestination__pin--pinned"}`} 
                                size='large'
                                disabled={pinButtonDisabled}
                            />
                        </div>
                        <Icon className={PhoneUtil.DESTINATION_TYPES_ICONS[destination.type]} size='large' style={{ marginRight: 15 }} />
                        <div className='CompanyDestination__header'>
                            <ClippedContent>
                                <Header 
                                    as={'h3'} 
                                    style={{ 
                                        margin: 0, 
                                        display: 'inline'
                                    }}
                                >
                                    {destination.label}
                                </Header>
                            </ClippedContent>
                        </div>
                    </div>
                    {destination.activeCalls?.length > 0 && (
                        <div className='CompanyDestination__number'>
                            { destination.activeCalls.length }
                        </div>
                    )}
                </div>
            )}
            content={(
                <div style={{ width: sectionWidth }}>
                    <div className='CompanyDestination__openContainer'>
                        <div className='CompanyDestination__content'>
                            <div onClick={(event) => {
                                togglePinned(destination.destination_id, !destination.pinned);
                                event.stopPropagation()
                            }}>
                                <Icon
                                    className={`fa-solid fa-thumbtack CompanyDestination__pin ${destination.pinned && "CompanyDestination__pin--pinned"}`} 
                                    size='large'
                                />
                            </div>
                            <Icon className={PhoneUtil.DESTINATION_TYPES_ICONS[destination.type]} size='large' style={{ marginRight: 15 }} />
                            <div className='CompanyDestination__header'>
                                <ClippedContent>
                                    <Header 
                                        as={'h3'} 
                                        style={{ 
                                            margin: 0, 
                                            display: 'inline'
                                        }}
                                    >
                                        {destination.label}
                                    </Header>
                                </ClippedContent>
                            </div>
                        </div>
                        {destination.activeCalls?.length > 0 && (
                            <div className='CompanyDestination__number'>
                                { destination.activeCalls.length }
                            </div>
                        )}
                    </div>
                    <div className='CompanyDestination__callsOuterContainer'>
                        <div className='CompanyDestination__callsContainer'>
                            {destination.activeCalls?.map((call) => <OfficeViewDestinationActiveCall call={call} />)}
                        </div>
                    </div>
                </div>
            )}
        />
    )
}

export default OfficeViewCompanyDestination;

