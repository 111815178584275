import React from "react";
import { Table, TimestampFilters } from "dyl-components";
import { Icon, Table as SemanticTable } from "semantic-ui-react";
import SelectAllCheckbox from "shared/SelectAllCheckbox";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getData, onToggleTimeUnit, useRangeCalendar } from "shared/CustomDateRange/customDateRange";

const SortActions = ({ direction = false, onToggleSort = () => {} }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const Header = ({ count, IVRs, listViewUrl }) => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        userAuthTimezone,
        dateRange,
        setDateRange
    } = useRangeCalendar(params);

    const lastModifiedOrder = params.get("order") === "asc";

    const lastModifiedFilter = () => {
        const query = new URLSearchParams(params);
        const order = !lastModifiedOrder ? "asc" : "desc";
        query.set("order", order);
        const query_string = query.toString();
        navigate(`${listViewUrl}${query_string ? `?${query_string}` : ""}`);
    };

    const onDateRangeChange = (_, { value }) => {
        setDateRange(value);
    };

    return (
        <Table.Row>
            <SemanticTable.HeaderCell
                width={1}
            >
                <SelectAllCheckbox 
                    count={count}
                    data={IVRs || []}
                    type={"IVRs"}
                    idProperty={"id"}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={3}
            >
                <span style={{ marginRight: 10 }}>Last Modified</span>
                <TimestampFilters
                    timestamp={timestamp}
                    onToggleTimeUnit={(timestamp) =>
                        onToggleTimeUnit(
                            timestamp,
                            setTimestamp,
                            params,
                            navigate,
                            listViewUrl
                        )
                    }
                    isCustomDateRangeOpen={isCustomDateRangeOpen}
                    onOpenCustomDateRange={() => {
                        setCustomDateRangeOpen(true);
                    }}
                    onCloseCustomDateRange={() => {
                        setCustomDateRangeOpen(false);
                    }}
                    dateRange={dateRange}
                    onDateRangeChange={onDateRangeChange}
                    getData={() =>
                        getData({
                            setCustomDateRangeOpen,
                            dateRange,
                            userAuthTimezone,
                            params,
                            navigate,
                            url: listViewUrl
                        })
                    }
                />
                <SortActions
                    direction={lastModifiedOrder}
                    onToggleSort={lastModifiedFilter}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={3}
            >
                Prompt Name
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={2}
            >
                Internal Extension
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell
                width={3}
            >
                Audio
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell 
                width={1}
            >
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};

export default Header;
