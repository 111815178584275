import React, { useCallback, useEffect, useState } from 'react';
import { DateInput, ControlledPopup, DropdownTimeInput, PriorityIcon, FileInput, TEXT_EDITOR_MODES, Notification, STATUS_TYPES, SearchableOptions } from "dyl-components";
import { Button, Form, Icon, List, ListItem, Segment, Grid, Divider, Checkbox, Header, Input } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CallTaskForm from "../../forms/TaskForm/TaskCallConfirmationForm";
import { DateTimeUtils, Person } from 'dyl-components';
import { callResultOptions } from "../../forms/TaskForm/optionsData";
import './TaskForm.scss';

import taskActions from 'actions/task';
import templateCategoryActions from 'actions/template_category';
import UserSearchField from 'shared/forms/UserSearchField';
import TaskFields from './TaskFields';
import DirectionField from './DirectionField';
import textTemplatesActions from 'actions/text_templates';
import emailTemplatesActions from 'actions/email_templates';
import relationshipsActions from 'actions/relationships';
import oauthActions from 'actions/oauth';
import contactActions from 'actions/contact';

import TaskEmailConfirmationModal from './TaskEmailConfirmationModal';
import userActions from 'actions/user';
import companyActions from 'actions/company';
import { disabledSignature, determineSignature } from 'utils/EmailFormUtils';
import { PhoneUtil } from 'utils';
import FileUtils, { FILE_CATEGORIES } from 'utils/FileUtils';

const TaskForm = ({
    dropzoneRef = React.createRef(),
    task_labels,

    isSaving,
    assignee,
    assignee_email,
    assignee_id,
    id,
    name,
    date,
    label,
    label_name,
    content,
    priority,
    contact,
    contact_ids,
    task_type,
    note,
    shareable,
    direction,
    complete,
    completion_time,
    subject,
    message,
    attachments,
    signature,
    body,
    description,
    hours = 0,
    minutes = 1,
    call_result = 'reached',
    assigner,
    assigner_email,
    relatedTo,
    recordType, // 'account' || 'contact'

    organizer,
    organizer_email,
    organizer_id,

    isAllowedToModify,
    isTaskTypeDisabled,

    onSave,
    onUpdate,
    onDelete,
    isDeleting,
    isReading,
    taskTypes,
    time,
    isReadingLabels,

    control,
    watch,
    isValid,
    isDirty,
    trigger,
    setValue,
    getValues,
    clearErrors,
    setError,
    reset,
    isModal = false,
    email,
    hiddenContactSelector = false
}) => {

    const LIMIT = 15;
    const type = watch('task_type', task_type || 'To-Do');
    const watchedBody = watch('body', body);
    const watchedSignature = watch('signature', signature);
    const watchedSubject = watch('subject', subject);
    const watchedContact = watch('contact');
    const dispatch = useDispatch();

    const tz = useSelector(state => state.auth.timezone);
    DateTimeUtils.setTimezone(tz);

    const task_types_with_ids = useSelector(state => state.task_types.taskTypes);
    const isComplete = watch('complete', complete);
    const isReadingTemplate = useSelector(state =>
        state.text_template.isReadingTemplate || state.email_template.isReadingTemplate
    )

    const isValidDateTime = (date, time) => {
        if (isComplete) {
            return true;
        }

        if (DateTimeUtils.isBeforeOrOnCurrentDate(date + ' ' + time, DateTimeUtils.WORD_DATETIME_FORMAT)) {
            setError('time', { type: 'invalid date/time', message: 'Future date/time only' });
            return false;
        }

        return true;
    }

    const onSubmit = () => {
        if (onSave && isValidDateTime(getValues().date, getValues().time)) {
            onSave(getValues());
        }
    }

    const onSaveEdit = () => {
        if (onUpdate && isValidDateTime(getValues().date, getValues().time)) {
            onUpdate(getValues());
        }
    }

    const onDeleteTask = () => {
        onDelete(id);
    }

    const [isCompleteConfirmationOpen, setCompleteConfirmationOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        contact_options: [],
        count: 0,
        page: 1,
        search: '',
        open: false,
    });

    const onReadContacts = (queryParameters) => {
        return dispatch(contactActions.getContactOptions(queryParameters));
    }

    const searchFunction = async () => {
        try {
            setLoading(true);
            const { data, count } = await onReadContacts({ page: 1, search: state.search, limit: LIMIT });
            setState({ ...state, contact_options: data, count, open: true, page: 1, search: state.search });
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    const cancelFunction = async () => {
        setState({ ...state, contact_options: [], count: 0, open: false, page: 1, search: '' });
    }

    const onChangeSearch = (_, { value }) => {
        setState({ ...state, search: value, open: (value?.trim().length >= 2 && state.count > 0)});
    }

    // get Primary OAuth Email Integration
    const [emailIntegration, setEmailIntegration] = useState(null)
    useEffect(() => {
        dispatch(oauthActions.getPrimary({ scope: 'email' }))
            .then((integration) => { setEmailIntegration(integration) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getTaskTypeId = useCallback((name) => {
        return task_types_with_ids.find(task_type => task_type.name === name)?.id;
    }, [task_types_with_ids]);

    useEffect(() => {
        if (type === 'Text' || type === 'Email') {
            dispatch(templateCategoryActions.readCategorizedTemplates({ category: type.toLowerCase() }));
        }
    }, [type, dispatch])

    const [relationOptions, setRelationOptions] = useState([])

    useEffect(() => {
        if (id) {
            // reset(defaultValues);
            dispatch(taskActions.readTaskTypeLabels(getTaskTypeId(task_type)));
            trigger('time');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch, getTaskTypeId, task_type, trigger, reset]);

    const [contactIdsLength, setContactIdsLength] = useState(0);

    const formatRelatedToOptions = (relations) => {
        let options = relations.map(
            (r, key) => {
                const [first,] = r.related_name.split("-");
                const isLead = first === "Lead "
                const className_name = {
                    customer: 'fas fa-user-crown',
                    customer_pipeline: 'fas fa-crown',
                    pipeline_stage: isLead ? 'fas fa-address-card' : 'fas fa-filter-circle-dollar'
                }[r.related_type] || '';
                const value = `${r.related_type}-${r.related_id}`;
                const text = <><Icon className={className_name} />{r.related_name || r.contact_name}</>
                return { key, value, text }
            }
        )
        setRelationOptions(options || []);
    }
    
    useEffect(() => {
        if (contact_ids?.length !== contactIdsLength && !isModal) {
            dispatch(relationshipsActions.getContactRelations({ person_id: contact_ids }))
                .then((relations) => formatRelatedToOptions(relations))
                .catch((err) => console.log(err));
            setContactIdsLength(contact_ids?.length);
        }
    }, [dispatch, contact_ids, contactIdsLength, isModal])

    useEffect(() => {
        if (isModal && !!watchedContact) {
            dispatch(relationshipsActions.getContactRelations({ person_id: [watchedContact] }))
                .then((relations) => formatRelatedToOptions(relations))
                .catch((err) => console.log(err));
        }
    }, [dispatch, isModal, watchedContact])

    const [template, setTemplate] = useState("");
    const [editorMode, setEditorMode] = useState(TEXT_EDITOR_MODES.RICH_TEXT);

    const onSelectTemplate = (templateId) => {
        switch (type) {
            case 'Text':
                if (templateId) {
                    dispatch(textTemplatesActions.getTemplate(templateId)).then(({ attachments, message }) => {
                        setValue('message', message);
                        setValue('attachments', (attachments?.data || []).map(attachment => ({
                            path: attachment.name,
                            id: attachment.id,
                            file_id: attachment.file_id,
                            name: attachment.name,
                            size: attachment.size,
                        })));
                    });
                } else {
                    setValue('message', '');
                    setValue('attachments', []);
                }
                break;
            case 'Email':
                if (templateId) {
                    dispatch(emailTemplatesActions.getTemplate(templateId)).then(({ content, subject, attachments }) => {
                        setValue("subject", subject);
                        trigger("subject");
                        setValue("description", content);
                        setValue('attachments', (attachments?.data || []).map(attachment => ({
                            path: attachment.name,
                            id: attachment.id,
                            file_id: attachment.file_id,
                            name: attachment.name,
                            size: attachment.size,
                        })));
                    });
                } else {
                    setValue("subject", '');
                    setValue("description", "");
                    setValue("attachments", []);
                }
                break;
            default:
                break;
        }
    }

    const resetValues = () => {
        const timeDropdownDefaults = DateTimeUtils.getTimeDropdownDefaults();
        const initialValues = {
            label: null,
            name: '',
            relatedTo: '',
            date: timeDropdownDefaults.startDate,
            time: timeDropdownDefaults.startTime,
            subject: '',
            description: '',
            message: '',
            note: '',
            priority: 'High',
            shareable: true,
            direction: "outbound",
            attachments: []
        };
        Object.keys(initialValues).forEach(key => {
            setValue(key, initialValues[key], { shouldValidate: key !== 'name' });
        });

        setTemplate("");
        setEditorMode(TEXT_EDITOR_MODES.RICH_TEXT)
        clearErrors();
    }

    const renderTaskLabelOptions = () => {
        if (label && isComplete && task_labels.findIndex(({ value }) => label === value) === -1) {
            return [...task_labels, { key: label, value: label, text: label_name }];
        }
        return task_labels;
    }

    const getAssigner = () => {
        if (id) {
            return {
                username: assigner,
                email: assigner_email
            }
        }
        return {
            username: organizer,
            email: organizer_email
        }
    }

    const assigner_details = getAssigner();

    const getSelectedUser = () => {
        if (id) {
            return {
                username: assignee,
                email: assignee_email
            }
        }
        return {
            username: organizer,
            email: organizer_email
        };
    }

    const user_id = useSelector(state => state.auth.user_id);
    const userSignaturePreference = useSelector(state => state.user.signature_preferences.new_email);
    const userSignatures = useSelector(state => state.user.signatures);
    const companySignaturePreference = useSelector(state => state.company.signature_preferences.new_email);
    const companySignatures = useSelector(state => state.company.signatures);

    useEffect(() => {
        dispatch(companyActions.readSignatures());
        dispatch(templateCategoryActions.readCategorizedTemplates({ category: 'email' }));
        dispatch(userActions.readSignaturePreferences({ user_id }));
        dispatch(companyActions.readSignaturePreferences());
        dispatch(userActions.viewUserProfile(user_id));
        dispatch(userActions.readSignatures({ user_id }));
    }, [user_id, dispatch]);

    const [sendSignature, setSendSignature] = useState(null);
    const [userSignature, setUserSignature] = useState(null);
    const [companySignature, setCompanySignature] = useState(null);
    const [isDisabledSignature, setDisabledSignature] = useState([]);

    useEffect(() => {
        let hasUserSignature = false;
        let hasCompanySignature = false;

        let userSignature;
        if (userSignaturePreference) {
            userSignature = userSignatures.find(signature => signature.id === userSignaturePreference);
            setUserSignature(userSignature?.content);
            hasUserSignature = true;
        }
        let companySignature;
        if (companySignaturePreference) {
            companySignature = companySignatures.find(signature => signature.id === companySignaturePreference);
            setCompanySignature(companySignature?.content);
            hasCompanySignature = true;
        }
        const signature = watchedSignature || determineSignature(userSignature, companySignature)
        if (signature === "none") {
            setSendSignature({ content: "" })
        } else if (signature === "my_signature") {
            setSendSignature(userSignature)
        } else if (signature === "company_signature") {
            setSendSignature(companySignature)
        }
        setDisabledSignature(disabledSignature(hasUserSignature, hasCompanySignature));
        if(getValues("contact_id")){
            onReadContacts({search: getValues("contact_id")}).then((contact)=>{
                setState({...state, search: contact.count === 1 ? `${contact.data[0]?.first_name} ${contact.data[0]?.last_name}` : ""})
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSignaturePreference, userSignatures, companySignaturePreference, companySignatures, setValue, getValues, setDisabledSignature, watchedSignature]);
    return (
        <Grid className='TaskFormBody' stackable>
            <Grid.Row columns='equal' className='TaskFormBody__Row'>
                <Grid.Column>
                    <Form loading={isSaving || isReading || isReadingTemplate} as={List} >
                        {isModal && !hiddenContactSelector && (
                            <ListItem
                                icon={
                                    <Icon className='fa-solid fa-user-group' color='black' />
                                }
                                description={(
                                    <Grid className='TaskFormSearch'>
                                        <Grid.Row>
                                            <Grid.Column width='equal'>
                                                <Controller
                                                    name='contact'
                                                    control={control}
                                                    render={({ field: { onChange } }) => (
                                                        <Form.Field
                                                            required
                                                            control={SearchableOptions}
                                                            disabled={!isAllowedToModify}
                                                            label="Contact"
                                                            loading={loading}
                                                            search={state.search}
                                                            searchFunction={searchFunction}
                                                            cancelSearchFunction={cancelFunction}
                                                            searchPlaceholder={'Search for a contact'}
                                                            onChangeSearch={onChangeSearch}
                                                            isSearchResultsVisible={state.open}
                                                            renderSearchResults={
                                                                <React.Fragment>
                                                                    {state.search.trim().length > 1 && state.count === 0 && (
                                                                        <Header as='h3' style={{margin: 5}}>No Results Found</Header>
                                                                    )}
                                                                    {state.search.trim().length > 1 && state.count > 0 && (
                                                                    <Segment loading={loading}>
                                                                        <Grid>
                                                                            {state.contact_options.map(contactOption => {
                                                                                const { first_name, last_name, id, account, email, phone } = contactOption;
                                                                                const name = `${first_name} ${last_name}`;
                                                                                return (
                                                                                    <Grid.Row verticalAlign='middle' key={id} columns='equal' stretched>
                                                                                        <Grid.Column width={4}>
                                                                                            <Person
                                                                                                username={name}
                                                                                                email={email.email}
                                                                                            />
                                                                                        </Grid.Column>
                                                                                        <Grid.Column width={2}>
                                                                                            ID: {id}
                                                                                        </Grid.Column>
                                                                                        <Grid.Column>
                                                                                            {PhoneUtil.formatPhoneNumber(phone.phone)}
                                                                                        </Grid.Column>
                                                                                        <Grid.Column>
                                                                                            {account && account.id && (
                                                                                                <span><Icon className={`fas fa-${account.account_type === 'business' ? 'building' : 'house'}`} />{account.name}</span>
                                                                                            )}
                                                                                        </Grid.Column>
                                                                                        <Grid.Column width={1}>
                                                                                            <Icon target="_blank" as={Link} to={`/contact/${id}`} className='fas fa-external-link' />
                                                                                        </Grid.Column>
                                                                                        <Grid.Column width={3}>
                                                                                            <Button color='primary' onClick={() => { 
                                                                                                    onChange({ target: { name:'contact', value: id } });
                                                                                                    setState({ ...state, contact_options: [], count: 0, open: false, page: 1, search: name });
                                                                                             }} >
                                                                                                Add
                                                                                            </Button>
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                );
                                                                            })}
                                                                        </Grid>
                                                                    </Segment>  
                                                                )}                                                              </React.Fragment>
                                                            }
                                                            isPaginationVisible={state.contact_options.length > 0}
                                                            paginationProps={{
                                                                boundaryRange: 0,
                                                                activePage: state.page,
                                                                ellipsisItem: null,
                                                                siblingRange: 2,
                                                                totalPages: Math.ceil(state.count / LIMIT),
                                                                onPageChange: async (_, { activePage }) => {
                                                                    setState({ ...state, loading: true, open: true, page: activePage });
                                                                    const { data, count } = await onReadContacts({ page: activePage, search: state.search, limit: LIMIT });
                                                                    setState({ ...state, page: activePage, contact_options: data, count, loading: false, open: true });
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                )}
                            />
                        )}
                        <ListItem
                            icon='clipboard check'
                            description={(
                                <Grid>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column>
                                            <Controller
                                                name='task_type'
                                                control={control}
                                                render={({ field: { onChange, value, name } }) => (
                                                    <Form.Select
                                                        label='Task Type'
                                                        value={value}
                                                        name={name}
                                                        onChange={(_, { name, value }) => {
                                                            resetValues();
                                                            onChange({ target: { name, value } });
                                                            dispatch(taskActions.readTaskTypeLabels(getTaskTypeId(value)));
                                                        }}
                                                        options={taskTypes}
                                                        selectOnBlur={false}
                                                        disabled={isTaskTypeDisabled}
                                                        required
                                                        {...(id && isAllowedToModify ? ({ tabIndex: -1, open: false }) : {})}
                                                        {...(!isAllowedToModify ? { open: false } : {})}
                                                    />
                                                )}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Controller
                                                name='label'
                                                control={control}
                                                render={({ field: { onChange, value, name } }) => (
                                                    <Form.Select
                                                        label={<label><Icon className='folder' /> Task Label</label>}
                                                        value={value}
                                                        name={name}
                                                        onChange={(_, { name, value }) => {
                                                            onChange({ target: { name, value } })
                                                        }}
                                                        options={renderTaskLabelOptions()}
                                                        placeholder="Select label"
                                                        selectOnBlur={false}
                                                        clearable
                                                        loading={isReadingLabels}
                                                        noResultsMessage="There are no labels"
                                                        disabled={isReadingLabels}
                                                        {...(!isAllowedToModify ? { open: false, clearable: false } : {})}
                                                    />
                                                )}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            )}
                        />
                        <ListItem
                            icon='clipboard check'
                            description={(
                                <Grid>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column>
                                            <Controller
                                                name='name'
                                                control={control}
                                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                    <Form.Input
                                                        label='Task Name'
                                                        onChange={onChange}
                                                        name={name}
                                                        value={value}
                                                        error={isAllowedToModify && error && error.message && {
                                                            content: error.message,
                                                        }}
                                                        required
                                                        placeholder="Type task name"
                                                        readOnly={!isAllowedToModify}
                                                    />
                                                )}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Controller
                                                name='relatedTo'
                                                control={control}
                                                render={({ field: { onChange, value, name } }) => (
                                                    <Form.Select
                                                        label={<label><Icon className='fa-solid fa-diagram-nested' /> Task Related To</label>}
                                                        value={value}
                                                        name={name}
                                                        onChange={(_, { name, value }) => {
                                                            onChange({ target: { name, value } })
                                                        }}
                                                        placeholder="Task Related To"
                                                        selectOnBlur={false}
                                                        clearable
                                                        options={relationOptions}
                                                        disabled={relationOptions.length === 0}
                                                        {...(!isAllowedToModify ? { open: false, clearable: false } : {})}
                                                    />
                                                )}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            )}
                        />
                        <ListItem
                            icon={
                                <Icon name='clock' color='black' />
                            }
                            description={(
                                <Grid>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column>
                                            <Controller
                                                name='date'
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => {
                                                    const minDate = !id ?
                                                        DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT, false, false) :
                                                        DateTimeUtils.isBeforeOrOnCurrentDate(date, DateTimeUtils.WORD_DATE_FORMAT) ? date : (
                                                            DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT, false, false)
                                                        )
                                                        ;
                                                    return (
                                                        <Form.Field
                                                            onBlur={() => {
                                                                if (!DateTimeUtils.isValid(value, DateTimeUtils.WORD_DATE_FORMAT)) {
                                                                    const timeDropdownDefaults = DateTimeUtils.getTimeDropdownDefaults();
                                                                    onChange({ target: { name: 'date', value: timeDropdownDefaults.startDate } });
                                                                    trigger('time');
                                                                } else if (DateTimeUtils.dateIsBeforeAnotherDate(value, minDate, 'day', DateTimeUtils.WORD_DATE_FORMAT)) {
                                                                    onChange({ target: { name: 'date', value: minDate } });
                                                                    trigger('time');
                                                                }
                                                            }}
                                                            control={DateInput}
                                                            label='Date &amp; Time'
                                                            onChange={(_, { value: new_value }) => {
                                                                onChange({ target: { name: 'date', value: new_value } });
                                                                trigger('time');
                                                            }}
                                                            value={value}
                                                            error={isAllowedToModify && error && error.message && {
                                                                content: error.message,
                                                                pointing: 'below'
                                                            }}
                                                            required
                                                            icon='calendar'
                                                            dateFormat={DateTimeUtils.WORD_DATE_FORMAT}
                                                            iconPosition='left'
                                                            readOnly={!isAllowedToModify}
                                                            minDate={minDate} />
                                                    );
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Controller
                                                name='time'
                                                control={control}
                                                shouldUnregister
                                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                                    <Form.Field
                                                        control={DropdownTimeInput}
                                                        className="Task__no-label--Error-fix"
                                                        onChange={(_, { value: new_value }) => {
                                                            onChange({ target: { name, value: new_value } });
                                                        }}
                                                        value={value}
                                                        error={isAllowedToModify && error && error.message && {
                                                            content: error.message,
                                                            pointing: 'below'
                                                        }}
                                                        icon='clock'
                                                        disabled={!isAllowedToModify}
                                                        {...(id ? { minTime: time } : {})}
                                                    />
                                                )}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            )}
                        />
                        <Controller
                            name='priority'
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                                <ListItem
                                    icon={
                                        <Icon name='level up' color='black' />
                                    }
                                    description={(
                                        <Form.Select
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            options={[
                                                { key: 1, value: 'High', text: <div><PriorityIcon type='high' /> <span> High </span> </div> },
                                                { key: 2, value: 'Medium', text: <div><PriorityIcon type='medium' /> <span> Medium </span> </div> },
                                                { key: 3, value: 'Low', text: <div><PriorityIcon type='low' /> <span> Low </span> </div> }
                                            ]}
                                            label='Priority'
                                            value={value}
                                            {...(!isAllowedToModify ? { open: false } : {})}
                                        />
                                    )}
                                />
                            )}
                        />
                        <ListItem
                            icon='user'
                            className='Task__guests'
                            description={(
                                <Grid>
                                    <Grid.Row columns='equal'>
                                        <Grid.Column>
                                            <Form.Field control={Segment} style={{ marginTop: 0 }} label="Assigned By" size='tiny' required>
                                                <Person
                                                    username={assigner_details.username}
                                                    email={assigner_details.email}
                                                    unsetFontSize={isModal}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            {!isReading && (
                                                <Controller
                                                    key={'assigned_user'}
                                                    name='user'
                                                    control={control}
                                                    render={({ field: { onChange, value, name } }) => (
                                                        <Form.Field
                                                            label='Assigned To'
                                                            control={UserSearchField}
                                                            excluded_users={[]}
                                                            onChange={(_, { name, value: newValue }) => { onChange({ target: { name, value: newValue } }) }}
                                                            placeholder="Select User"
                                                            value={value}
                                                            name={name}
                                                            required
                                                            display_selected_user
                                                            displayed_selected_user={getSelectedUser()}
                                                            disabled={!isAllowedToModify}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            )}
                        />
                        {(type === 'Email' || type === 'Text' || type === 'Call') && <Divider />}
                        {(type === 'Email' || type === 'Text') && (
                            <DirectionField
                                control={control}
                                id={id}
                                isAllowedToModify={isAllowedToModify}
                            />
                        )}
                        {(type === 'Call' && !isComplete) && (
                            <DirectionField
                                control={control}
                                id={id}
                                isAllowedToModify={isAllowedToModify}
                            />
                        )}
                        <TaskFields
                            type={type}
                            control={control}
                            description={description}
                            subject={subject}
                            template={template}
                            setTemplate={setTemplate}
                            body={body}
                            signature={signature}
                            onSelectTemplate={onSelectTemplate}
                            editorMode={editorMode}
                            setEditorMode={setEditorMode}
                            isAllowedToModify={isAllowedToModify}
                            setValue={setValue}
                            trigger={trigger}

                            userSignature={userSignature}
                            companySignature={companySignature}
                            isDisabledSignature={isDisabledSignature}
                        />
                        <Divider />
                        <Controller
                            name='note'
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <ListItem
                                    icon={
                                        <Icon name='sticky note' color='black' />
                                    }
                                    description={(
                                        <Form.TextArea
                                            label='Note'
                                            onChange={onChange}
                                            name={name}
                                            value={value}
                                            placeholder="Notes will go here..."
                                            readOnly={!isAllowedToModify}
                                            error={error?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                        {(type === 'Text') &&
                            <React.Fragment>
                                <Divider />
                                <Controller
                                    name="attachments"
                                    defaultValue={[]}
                                    control={control}
                                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                        <Form.Field
                                            control={Input}
                                            error={error && error.message && {
                                                pointing: 'below'
                                            }}
                                            className='Task__attachments-section'
                                        >
                                            <Segment style={{width: '100%', padding: 0}} basic>
                                            <div>
                                            <Icon name='attach' color='black' /> <Icon onClick={() => { dropzoneRef.current.open() }} color='blue' name='plus circle' link /> <b>Attach {value.length > 0 && 'more '}files</b>
                                            </div>
                                            <div className='Template__attachments' style={{marginBottom: 10}}>
                                                <FileInput
                                                    onChange={(_, { value: new_value }) => {
                                                        onChange({
                                                            target: {
                                                                name, value: [
                                                                    ...value,
                                                                    ...new_value.filter(new_file => (
                                                                        value.findIndex(file => file.path === new_file.path) === -1
                                                                    ))
                                                                ]
                                                            }
                                                        })
                                                    }}
                                                    onRemove={(_, { value }) => { 
                                                        onChange({ target: { name, value } }); 
                                                    }}
                                                    onReject={(rejected) => {
                                                        if (rejected.length > 0) {
                                                            Notification.alert("File must be an .png, .jpg, .jpeg, or .gif", STATUS_TYPES.ERROR, true);
                                                        }
                                                    }}
                                                    files={value}
                                                    name="files"
                                                    accept="image/*"
                                                    icon='image'
                                                    size="mini"
                                                    dropzoneRef={dropzoneRef}
                                                    showIcon
                                                    hasBrowse
                                                />
                                            </div>
                                            <i>Remaining: {FileUtils.getRemainingSize(value, FILE_CATEGORIES.TEXT)} MB </i> {error?.message && `(${error.message})`}
                                            </Segment>
                                        </Form.Field>
                                    )}
                                /> 
                            </React.Fragment>
                        }
                        <Divider />
                        <Controller
                            name='shareable'
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                                <ListItem>
                                    <List.Content>
                                        <List.Header>
                                            <Checkbox
                                                toggle
                                                className='Task__no-label'
                                                onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }) }}
                                                checked={value}
                                                label='Private'
                                                readOnly={!isAllowedToModify}
                                                disabled={!isAllowedToModify}
                                            />
                                        </List.Header>
                                        <List.Description className='Task__checkbox-description'>
                                            Toggling this won't let other users see this task
                                        </List.Description>
                                    </List.Content>
                                </ListItem>

                            )}
                        />
                        {id && (
                            <Controller
                                name='complete'
                                control={control}
                                render={({ field: { onChange, value, name } }) => (
                                    <ListItem>
                                        <List.Content>
                                            <List.Header>
                                                <Checkbox
                                                    className='large Task__no-label Task__complete'
                                                    onChange={async (_, { checked }) => {
                                                        if (task_type === 'Email' && emailIntegration != null && checked) {
                                                            setCompleteConfirmationOpen(checked)
                                                            return
                                                        }
                                                        await onChange({ target: { name, value: checked } });
                                                        if (checked) {
                                                            clearErrors(['date', 'time']);
                                                        } else {
                                                            trigger('time');
                                                        }
                                                    }}
                                                    checked={value}
                                                    label={isAllowedToModify ? 'Complete' : complete ? 'Marked Complete' : 'Complete'}
                                                    readOnly={!isAllowedToModify}
                                                    disabled={!isAllowedToModify}
                                                    style={{ paddingLeft: '1em' }}
                                                />
                                            </List.Header>
                                            <List.Description className='Task__checkbox-description'>
                                                {isAllowedToModify ? 'Check to mark as complete' : completion_time || 'Check to mark as complete'}
                                            </List.Description>
                                        </List.Content>
                                        <TaskEmailConfirmationModal
                                            open={isCompleteConfirmationOpen}
                                            onClose={() => { setCompleteConfirmationOpen(false) }}
                                            emailIntegration={emailIntegration}
                                            onConfirm={
                                                async () => {
                                                    await onChange({ target: { name, value: true } })
                                                    clearErrors(['date', 'time']);
                                                    setCompleteConfirmationOpen(false);
                                                    onSaveEdit();
                                                }
                                            }
                                            sendSignature={sendSignature}
                                            email={{ subject: watchedSubject, body: watchedBody, contactDetails: email?.contactDetails, attachments }}
                                            recordType={recordType}
                                        />
                                    </ListItem>
                                )}
                            />
                        )}
                        {(task_type === 'Email') && (emailIntegration === null) && (
                            <>
                                <p><b>Go to <Link to={`/settings/users/${user_id}/integration`}><Icon name={'setting'} />Settings</Link> to add an email integration</b></p>
                            </>
                        )}
                        {id && type === 'Call' && isComplete && (
                            <CallTaskForm
                                isAllowedToModify={isAllowedToModify}
                                complete={complete}
                                isReadingLabels={isReadingLabels}
                                control={control}
                                callResultOptions={callResultOptions}
                                id={id}
                            />
                        )}
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal' className='FormTab__Row'>
                {!isModal && (
                    <Grid.Column textAlign='left' className='TaskFormFooter'>
                        {(!isDeleting && !(id && !isAllowedToModify)) && (<Button loading={isSaving} disabled={!isValid || !isDirty || isSaving || isReadingLabels} onClick={id ? onSaveEdit : onSubmit} content='Save' primary />)}
                        {(id && !(id && !isAllowedToModify) && !isSaving) && (
                            <ControlledPopup
                                trigger={<Button loading={isDeleting} disabled={isDeleting} negative>Delete</Button>}
                                onConfirm={onDeleteTask}
                            />
                        )}
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    )
};

export default TaskForm;
