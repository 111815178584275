import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./index.scss";

import ToolBar from "./subcomponents/CalendarToolBar";
import LeftPanel from "./subcomponents/LeftPanel";
import AddEventModal from "shared/modals/AddEventModal";
import {
    DateTimeUtils,
    CalendarGridView,
    CalendarListView,
    Notification,
    STATUS_TYPES,
} from "dyl-components";
import {
    formatEvents,
    formatSequenceTasks,
    formatTasks,
    formatEventsCalendar,
    formatTasksCalendar,
} from "./utils";
import useEventForm from "utils/useEventForm";
import contactsActions from 'actions/contacts';
import sequenceTaskActions from "actions/sequence_tasks";
import tasksActions from "actions/tasks";
import eventsActions from "actions/events";
import eventActions from "actions/event";
import eventAttachmentActions from "actions/event_attachment";
import uploadActions from "actions/upload";
import eventAttendeeActions from "actions/event_attendee";
import AddTaskModal from "shared/modals/AddTaskModal";
import useTaskForm from "utils/useTaskForm";
import userActions from "actions/user";
import taskActions from "actions/task";
import contactActions from "actions/contact" 
import SendEmailModal from "shared/modals/SendEmailModal";
import RescheduleSequenceModal from "shared/modals/RescheduleSequenceModal";
import { GetTimezone } from 'utils/TZDisplay';
import TaskEmailConfirmationModal from '../../shared/NewTasksTab/subcomponents/TaskEmailConfirmationModal';
import TaskCallConfirmationModal from "../../shared/modals/TaskCallConfirmationModal";
import oauthActions from 'actions/oauth';
import { determineSignature } from 'utils/EmailFormUtils';
import companyActions from 'actions/company';
import contactEmailActions from 'actions/contact_email';
import Utils from 'shared/EventForm/Utils'
import { FILE_CATEGORIES } from "utils/FileUtils";
import NewActivityForm from "shared/forms/NewActivity";

const LIMIT = 100; //defined by CER-3573

export default function CalendarTab() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sequenceTasks = useSelector(
        (state) => state.sequence_tasks.sequenceTasks
    );
    const isReadingSequenceTasks = useSelector(
        (state) => state.sequence_tasks.isReadingSequenceTasks
    );

    const tasks = useSelector((state) => state.tasks.tasks);
    const isReadingTasks = useSelector((state) => state.tasks.isReadingTasks);
    const task_labels = useSelector((state) =>
        state.task.task_type_labels.map(({ name, id }) => ({
            key: id,
            value: id,
            text: name,
        }))
    );
    const events = useSelector((state) => state.events.events);

    const isReadingEvents = useSelector(
        (state) => state.events.isReadingEvents
    );

    // EVENT RELATED FUNCTIONS
    const onReadEvent = (id) => {
        return dispatch(eventActions.getEvent(id));
    };
    const onAddEvent = async (event) => {
        const id = await dispatch(eventActions.addEvent(Utils.toPayload(event)));
        return id;
    };
    const onDeleteEvent = (id) => {
        return dispatch(eventActions.deleteEvent(id));
    };
    const onUpdateEvent = (event_id, body) => {
        dispatch(eventActions.updateEvent(event_id, body));
    };
    const onAddAttachments = (attachments, user_id, event_id) => {
        return dispatch(
            eventAttachmentActions.addAttachments(
                attachments,
                { user_id },
                event_id
            )
        );
    };
    const onRemoveAttachment = (attachment_id, event_id, user_id) => {
        return dispatch(
            eventAttachmentActions.removeAttachment(attachment_id, {
                user_id,
                event_id,
            })
        );
    };
    const onAddAttendees = (event_id, params) => {
        return dispatch(
            eventAttendeeActions.addAttendees(params, null, event_id)
        );
    };
    const onRemoveAttendee = (attendee_id, params) => {
        return dispatch(
            eventAttendeeActions.removeAttendee(attendee_id, params)
        );
    };
    const onUpload = (file, file_type) => {
        return dispatch(uploadActions.uploadFiles(file, file_type));
    };

    const onReadTask = (id) => {
        return dispatch(taskActions.readTask(id));
    };

    const onReadContact = (id) => {
        return dispatch(contactActions.getContactOptions({ search: id }));
    }

    const current_user = useSelector((state) => state.auth);
    const event_labels = useSelector((state) =>
        state.events.event_labels.map(({ id, name }) => ({
            key: id,
            value: id,
            text: name,
        }))
    );
    const { organizer_id, organizer, organizer_email } = useSelector(
        (state) => {
            const {
                first_name = "",
                last_name = "",
                email: organizer_email,
            } = state.users.userProfile;
            return {
                organizer_id: state.auth.user_id,
                organizer: `${first_name} ${last_name}`,
                organizer_email,
            };
        }
    );

    const [addActivityAction, setAddActivityAction] = useState(null);
    const calendarRef = useRef(null);
    const [calendarItems, setCalendarItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [timeUnits, setTimeUnits] = useState("week"); // day, week, month, year
    const [offset, setOffset] = useState(0); // used for calendar pagination]
    const [viewType, setViewType] = useState("list"); // list, grid
    const [miniDate, setMiniDate] = useState(DateTimeUtils.getCurrentDate());
    const [calendarDate, setCalendarDate] = useState(
        DateTimeUtils.getUnixTime(
            DateTimeUtils.getCurrentDate(),
            DateTimeUtils.DATE_FORMAT,
            GetTimezone()
        )
    );

    const [sectionOptions, setSectionOptions] = useState({
        sequences: false,
        tasks: true,
        events: true,
    });

    const [options, setOptions] = useState({
        sequences: {
            type: {
                type_all: true,
                type_email: true,
                type_call: true,
                type_text: true,
            },
            outcome: {
                outcome_all: true,
                outcome_unconfirmed: true,
            },
        },
        tasks: {
            type: {
                type_all: true,
                type_email: true,
                type_call: true,
                type_text: true,
                type_todo: true,
            },
            outcome: {
                outcome_all: true,
                outcome_unconfirmed: true,
            },
        },
        events: {
            outcome: {
                outcome_all: true,
                outcome_unconfirmed: true,
            },
        },
    });
    const [completeConfirmationOpen,setCompleteConfirmationOpen] = useState(false);
    const [callCompleteConfirmationOpen,setCallCompleteConfirmationOpen] = useState(false);
    const [sendSignature, setSendSignature] = useState(null);
    const [emailData, setEmailData] = useState({body:"", subject:""});
    const [taskCompleteInfo, setTaskCompleteInfo] = useState({});
    const [signatureType, setSignatureType] = useState("");
    const [notificationContactName, setNotificationContactName] = useState("");

    const isAddActivityModalOpen = (action) => {
        return addActivityAction === action;
    };

    const {
        control,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        getValues,
        reset,
        isAllowedToModify,
        eventBeingEdited,
        loadEvent,
        handleSubmit: handleSubmitEvent,
        resetField,
    } = useEventForm({});

    const {
        control: taskControl,
        watch: taskWatch,
        isValid: taskIsValid,
        isDirty: taskIsDirty,
        trigger: taskTrigger,
        setValue: taskSetValue,
        getValues: taskGetValues,
        clearErrors: taskClearErrors,
        setError: taskSetError,
        reset: taskReset,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDelete: onDeleteTask,
        onUpdate: onUpdateTask,
        handleSubmit,
        formatTaskBeingEdited,
        onCompleteTask,
        getTaskTypeSpecificPayload,
        getCorrectedTaskTypeName,
        getCallCompletionDetails,
        isAllowedToModify: isTaskAllowedToModify
    } = useTaskForm({
        isContact: false,
        isModal: true,
        open: isAddActivityModalOpen("task"),
    });

    const userSignaturePreference = useSelector(state => state.user.signature_preferences.new_email);
    const userSignatures = useSelector(state => state.user.signatures);
    const companySignaturePreference = useSelector(state => state.company.signature_preferences.new_email);
    const companySignatures = useSelector(state => state.company.signatures);

       const [emailIntegration, setEmailIntegration] = useState(null)
       useEffect(()=>{
           dispatch(oauthActions.getPrimary({scope: 'email'}))
               .then((integration) => {setEmailIntegration(integration) })
           // eslint-disable-next-line react-hooks/exhaustive-deps

       },[dispatch])

    useEffect(() => {        
		let userSignature;
        if (userSignaturePreference) {
            userSignature = userSignatures.find(signature => signature.id === userSignaturePreference);
        }
        let companySignature;
        if (companySignaturePreference) {
            companySignature = companySignatures.find(signature => signature.id === companySignaturePreference);
        }
        const signature = signatureType || determineSignature(userSignature, companySignature);
		if (signature === "none") {
			setSendSignature({content : ""})
		} else if ( signature === "my_signature") {
			setSendSignature(userSignature)
		} else if ( signature === "company_signature") {
			setSendSignature(companySignature)
		}
    },[userSignaturePreference, userSignatures, companySignaturePreference, companySignatures,dispatch, setValue, getValues, signatureType])

    const refreshCalendarDate = () => {
        const date = fullCalendarApiFunction("getDate");
        const unixDate = DateTimeUtils.getUnixTime(
            `${
                date.getUTCMonth() + 1
            }-${date.getUTCDate()}-${date.getUTCFullYear()}`,
            DateTimeUtils.DATE_FORMAT
        );
        setCalendarDate(unixDate);
    };

    const fullCalendarApiFunction = (name, params = []) => {
        const api = calendarRef.current.getApi();
        return api[name](...params);
    };

    const handleMiniChange = (event, { value }) => {
        setMiniDate(value);
        const unixDate = DateTimeUtils.getUnixTime(value);
        const date = DateTimeUtils.convertUnixTimeToDate(unixDate).toDate();

        setCalendarDate(unixDate);
        if (viewType === "grid") {
            fullCalendarApiFunction("gotoDate", [date]);
        }
    };

    const goToDay = (date, jsEvent) => {
        const unixDate = DateTimeUtils.getUnixTime(
            `${
                date.getUTCMonth() + 1
            }-${date.getUTCDate()}-${date.getUTCFullYear()}`,
            DateTimeUtils.DATE_FORMAT
        );
        setCalendarDate(unixDate);
        fullCalendarApiFunction("gotoDate", [date]);
        setTimeUnits("day");
        fullCalendarApiFunction("changeView", ["timeGridDay"]);
    };

    const onChangeAllOptions = (label) => {
        if(label === "sequences"){
            setSectionOptions((prevState) => {
                return {
                    ...prevState,
                    [label]: !prevState[label],
                    "tasks": false,
                    "events": false
                };
            });
        } else {
            setSectionOptions((prevState) => {
                return {
                    ...prevState,
                    [label]: !prevState[label],
                    "sequences": false
                };
            });
        }
    };

    const turnAll = (sectionName) => {
        const modifiedOptions = { ...options[sectionName] };
        Object.keys(options[sectionName]).forEach((category) =>
            Object.keys(options[sectionName][category]).forEach(
                (option) =>
                    (modifiedOptions[category][option] =
                        sectionOptions[sectionName])
            )
        );
        setOptions({ ...options, [sectionName]: modifiedOptions });
    };

    useEffect(() => {
        turnAll("sequences");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionOptions.sequences]);

    useEffect(() => {
        turnAll("events");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionOptions.events]);

    useEffect(() => {
        turnAll("tasks");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionOptions.tasks]);

    const areFiltersOn = (filters, allName) => {
        let nonAllFilters = Object.entries(filters);
        nonAllFilters = nonAllFilters.filter((entry) => entry[0] !== allName);
        nonAllFilters = Object.fromEntries(nonAllFilters);
        return Object.values(nonAllFilters).every((filterValue) => {
            if (filterValue !== true) {
                return false;
            }
            return true;
        });
    };

    const onChangeOptions = (division, label, isAll, section, allName) => {
        const lowerCaseSection = section.toLowerCase();
        setOptions((prevState) => {
            const section = prevState[division][lowerCaseSection];
            if (isAll) {
                const modifiedSection = { ...section };
                Object.keys(section).forEach(
                    (option) => (modifiedSection[option] = !section[label])
                );
                return {
                    ...prevState,
                    [division]: {
                        ...prevState[division],
                        [lowerCaseSection]: modifiedSection,
                    },
                };
            }
            let modifiedSection = {
                ...prevState[division][lowerCaseSection],
                [label]: !prevState[division][lowerCaseSection][label],
            };
            return {
                ...prevState,
                [division]: {
                    ...prevState[division],
                    [lowerCaseSection]: {
                        ...modifiedSection,
                        [allName]: areFiltersOn(modifiedSection, allName),
                    },
                },
            };
        });
    };

    const openAddActivityModal = (action) => {
        loadEvent(null);
        loadTask(null);
        setAddActivityAction(action);
    };

    const closeAddActivityModal = async (isTask) => {
        if (isTask) {
            resetTaskFormValues();
        } else {
            resetEventFormValues();
        }
        setAddActivityAction(null);
    };

    const addAttachments = async (files, event_id) => {
        if (files && files.length > 0) {
            const toUpload = files.filter((file) => file.id === undefined);
            const toCopy = files.filter(file => file.id).map(file => ({
                file_id: file.file_id,
                name: file.name,
                size: file.size
            }));
            const uploadedFiles = await onUpload(toUpload, FILE_CATEGORIES.EVENT);
            const newFiles = toUpload.map((_, idx) => {    
                return {
                    name: files[idx].name,
                    file_id: uploadedFiles[idx],
                    size: files[idx].size
                }})
            return onAddAttachments(
                [
                    ...newFiles,
                    ...toCopy,
                ],
                current_user.user_id,
                event_id
            );
        }
        return Promise.resolve();
    };

    const removeAttachments = (files, event_id) => {
        if (!files) {
            files = [];
        }
        const toRemove = eventBeingEdited.attachments.filter(
            (attachment) =>
                files.findIndex((file) => file.id === attachment.id) === -1
        );
        if (toRemove.length <= 0) {
            return Promise.resolve();
        }
        return Promise.all(
            toRemove.map((file) =>
                onRemoveAttachment(file.id, event_id, current_user.user_id)
            )
        );
    };

    const addAttendees = (event_id, usersToAdd) => {
        let params = {
            payload: usersToAdd,
            integration_id: null,
            access_token: null,
        };
        if (usersToAdd.length > 0) {
            return onAddAttendees(event_id, params);
        }
        return Promise.resolve();
    };

    const removeAttendees = (event_id, usersToRemove) => {
        let params = { event_id, integration_id: null, access_token: null };
        if (usersToRemove.length > 0) {
            return Promise.all(
                usersToRemove.map((user) => onRemoveAttendee(user.id, params))
            );
        }
        return Promise.resolve();
    };

    const updateAttendees = async (updatedAttendees, event_id) => {

        const attending_users = eventBeingEdited?.attendees?.users || [];
        const attending_contacts = eventBeingEdited?.attendees?.contacts || [];

        const updatedUsers = updatedAttendees.filter(
            (attendee) => attendee.user_id
        );

        const updatedContacts = updatedAttendees.filter(
            (attendee) => attendee.contact_id
        );

        let usersToAdd = updatedUsers.filter(({user_id}) => {
            return !attending_users.includes(user_id)
        })

        const usersToRemove = attending_users.filter((user) => (
            usersToAdd.findIndex(({ user_id }) => user_id === user) === -1 &&
            updatedUsers.findIndex(({ user_id }) => user_id === user) === -1
        ));

        let contactsToAdd = updatedContacts.filter(({contact_id}) => {
            return !attending_contacts.includes(contact_id)
        })

        const contactsToRemove = attending_contacts.filter((contact) => (
            contactsToAdd.findIndex(({ contact_id }) => contact_id === contact) === -1 &&
            updatedContacts.findIndex(({ contact_id }) => contact_id === contact) === -1
        ));

        await Promise.all([
            addAttendees(event_id, [...usersToAdd, ...contactsToAdd]),
            removeAttendees(event_id, [...usersToRemove, ...contactsToRemove]),
        ]);
    };

    const addEvent = async () => {
        const values = getValues();
        const {
            all_day,
            start_date,
            start_time,
            end_date,
            end_time,
            users,
            attachments,
            contacts,
            timezone,
            related_to
        } = values;

        const attendees = [
            ...contacts.map((contact_id) => ({
                accepted: "no",
                contact_id,
                emailed: false
            })),
            ...users.map((user_id) => ({
                accepted: "no",
                user_id,
                emailed: false
            })),
        ];

        // TODO: add recurring event properties
        const payload = {
            complete: false,
            conference_phone: values.phone_number,
            conference_pin: values.pin,
            conference_url: values.conference_line,
            content: values.content,
            content_type: "text/html",
            label: values.label ? values.label : 0,
            location: values.location,
            name: values.name,
            all_day,
            start_date,
            start_time,
            end_date,
            end_time,
            // "recurring_event_id": 0,
            organizer_id,
            organizer,
            organizer_email,
            users: values.users,
            contact_id: values.contacts,
            contact_name: notificationContactName,
            timezone,
            related_to
        };

        try {
            const { id } = await onAddEvent(payload);
            await addAttendees(id, attendees);
            await addAttachments(attachments, id, "pdf");
            closeAddActivityModal(false);
            readCalendarItems();
            Notification.alert(
                "Successfully created event!",
                STATUS_TYPES.SUCCESS
            );
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to create event", STATUS_TYPES.ERROR);
        }
    };

    const onUpdate = async () => {
        const event = getValues();
        const {
            all_day,
            start_date,
            start_time,
            end_date,
            end_time,
            attachments,
            timezone,
            contacts,
            users,
            related_to
        } = event;
        const { id } = eventBeingEdited;

        const start = DateTimeUtils.getUnixTime(
            `${start_date}${all_day ? "" : ` ${start_time}`}`,
            all_day
                ? DateTimeUtils.WORD_DATE_FORMAT
                : DateTimeUtils.WORD_DATETIME_FORMAT,
            timezone
        );
        let event_label = event.label ? event.label : 0;

        if (
            event_label !== 0 &&
            event_labels.findIndex(({ value }) => value === event_label) ===
                -1 &&
            !DateTimeUtils.isBeforeOrOnCurrentDate(
                DateTimeUtils.formatEpoch(start, DateTimeUtils.DATETIME_FORMAT),
                DateTimeUtils.DATETIME_FORMAT
            )
        ) {
            event_label = 0;
        }
        const attendees = [
            ...contacts.map((contact_id) => ({
                accepted: "no",
                contact_id,
                emailed: false,
            })),
            ...users.map((user_id) => ({
                accepted: "no",
                user_id,
                emailed: false,
            })),
        ];
        // TODO: add recurring event properties
        const payload = {
            complete: false,
            conference_phone: event.phone_number,
            conference_pin: event.pin,
            conference_url: event.conference_line,
            content: event.content,
            content_type: "text/html",
            event_label_id: event_label,
            location: event.location,
            name: event.name,
            all_day,
            start_date,
            start_time,
            end_date,
            end_time,
            // "recurring_event_id": 0,
            organizer_id,
            organizer,
            organizer_email,
            users: users,
            contact_id: contacts,
            contact_name: notificationContactName,
            timezone,
            related_to,
        };

        try {
            await onUpdateEvent(id, Utils.toPayload(payload));
            const files = attachments;
            const filesToAdd = files.filter((file) => file.id === undefined);
            await Promise.all([
                addAttachments(filesToAdd, id, "pdf"),
                removeAttachments(
                    files.filter((file) => file.id),
                    id
                ),
                updateAttendees(attendees, id),
            ]);
            Notification.alert(
                "Successfully updated the event!",
                STATUS_TYPES.SUCCESS
            );
            closeAddActivityModal(false);
            readCalendarItems();
        } catch (e) {
            console.log(e);
            Notification.alert(
                "Failed to update the event",
                STATUS_TYPES.ERROR
            );
        }
    };

    const resetEventFormValues = (info) => {
        if (info) {
            const { date, isMonth } = info;
            if (isMonth) {
                const dateUnix = DateTimeUtils.getUnixTime(
                    `${
                        date.getUTCMonth() + 1
                    }-${date.getUTCDate()}-${date.getUTCFullYear()}`,
                    DateTimeUtils.DATE_FORMAT
                );
                const endUnix = DateTimeUtils.addOneHour(dateUnix);
                loadEvent({
                    all_day: false,
                    start: {
                        date_time: dateUnix,
                    },
                    end: {
                        date_time: endUnix,
                    },
                });
            } else {
                const dateUnix = DateTimeUtils.getUnixTime(
                    `${
                        date.getUTCMonth() + 1
                    }-${date.getUTCDate()}-${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}`,
                    DateTimeUtils.DATETIME_FORMAT
                );
                const endUnix = DateTimeUtils.addOneHour(dateUnix);
                loadEvent({
                    all_day: false,
                    start: {
                        date_time: dateUnix,
                    },
                    end: {
                        date_time: endUnix,
                    },
                });
            }
        } else {
            loadEvent(null);
        }
    };

    const resetTaskFormValues = (info) => {
        if (info) {
            const { date, isMonth } = info;
            if (isMonth) {
                const dateUnix = DateTimeUtils.getUnixTime(
                    `${
                        date.getUTCMonth() + 1
                    }-${date.getUTCDate()}-${date.getUTCFullYear()}`,
                    DateTimeUtils.DATE_FORMAT
                );
                const dateFormat = DateTimeUtils.formatEpoch(
                    dateUnix,
                    DateTimeUtils.WORD_DATE_FORMAT
                );
                const timeFormat = DateTimeUtils.formatEpoch(dateUnix);
                loadTask({ date: dateFormat, time: timeFormat });
            } else {
                const dateUnix = DateTimeUtils.getUnixTime(
                    `${
                        date.getUTCMonth() + 1
                    }-${date.getUTCDate()}-${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}`,
                    DateTimeUtils.DATETIME_FORMAT
                );
                const dateFormat = DateTimeUtils.formatEpoch(
                    dateUnix,
                    DateTimeUtils.WORD_DATE_FORMAT
                );
                const timeFormat = DateTimeUtils.formatEpoch(dateUnix);
                loadTask({ date: dateFormat, time: timeFormat });
            }
        } else {
            loadTask(null);
        }
    };

    const findEvent = (event_id) => {
        return calendarItems.find(
            (calendarItem) => `${calendarItem.id}` === `${event_id}`
        );
    };

    const onEventClick = (event_id) => {
        try {
            getTaskEmailData(event_id);
            const item = findEvent(event_id);
            if (item) {
                if (
                    item?.extendedProps?.activity_type === "event" ||
                    item?.activity_type === "Event"
                ) {
                    onReadEvent(event_id).then((event) => {
                        if (event) {
                            event.id = event_id; // TODO: return id from the backend
                            loadEvent(event);
                            setAddActivityAction("event");
                        }
                    });
                }
                if (item?.extendedProps?.activity_type === "task") {
                    onReadTask(event_id).then((task) => {
                        const taskBeingEdited = formatTaskBeingEdited(
                            event_id,
                            task
                        );
                        loadTask(taskBeingEdited);
                        setAddActivityAction("task");
                    });
                }
            }
        } catch (error) {
            console.log(error);
            Notification.alert("Failed to get event", STATUS_TYPES.ERROR);
        }
    };

    const onContactClick = (contact) => {
        const {id, contact_id} = contact
        navigate(`/contact/${contact_id ? contact_id : id}`);
    };

    const onDelete = async () => {
        try {
            await onDeleteEvent(eventBeingEdited.id);
            Notification.alert(
                "Successfully deleted event!",
                STATUS_TYPES.SUCCESS
            );
            closeAddActivityModal(false);
            readCalendarItems();
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to delete event", STATUS_TYPES.ERROR);
        }
    };

    const changeCalendarView = (option) => {
        const views = {
            day: "timeGridDay",
            week: "timeGridWeek",
            month: "dayGridMonth",
            year: "multiMonthYear",
        };
        fullCalendarApiFunction("changeView", [views[option]]);
    };

    const readCalendarItems = async () => {
        const promises = [];
        setCalendarItems([]);

        const {
            sequences: show_sequences,
            tasks: show_tasks,
            events: show_events,
        } = sectionOptions;

        if (!(show_sequences || show_tasks || show_events)) {
            setIsLoading(false);
            return;
        }

        let { start, end } = DateTimeUtils.getDateRange(
            "specify",
            DateTimeUtils.formatEpoch(calendarDate, DateTimeUtils.DATE_FORMAT),
            DateTimeUtils.DATE_FORMAT,
            timeUnits,
            true
        );

        if (show_sequences && viewType === "list") {
            const query_params = { start, end, limit: LIMIT, status: "upcoming,overdue" };
            if (!options.sequences.type.type_all) {
                const {
                    sequences: {
                        type: { type_email, type_call, type_text },
                    },
                } = options;
                const action_filter = [];
                type_email && action_filter.push("Email");
                type_call && action_filter.push("Call");
                type_text && action_filter.push("Text");
                query_params.action = action_filter.join(",");
            }
            promises.push(dispatch(sequenceTaskActions.read(query_params)));
        }

        if (show_tasks) {
            const query_params = { start, end, deleted: false, limit: LIMIT };
            if (!options.tasks.type.type_all) {
                const {
                    tasks: {
                        type: { type_email, type_call, type_todo, type_text },
                    },
                } = options;
                const types = [];
                type_email && types.push("Email");
                type_call && types.push("Call");
                type_todo && types.push("To-Do");
                type_text && types.push("Text");
                query_params.task_type = types.join(",");
            }
            promises.push(dispatch(tasksActions.readTasks(query_params)));
        }

        if (show_events) {
            promises.push(
                dispatch(
                    eventsActions.readEvents({
                        start_time: start,
                        end_time: end,
                        limit: LIMIT
                    })
                )
            );
        }

        await Promise.all(promises);
    };

    const areAllFiltersOff = (filters) => {
        for (const filter in filters) {
            if (filters[filter]) {
                return false;
            }
        }
        return true;
    };

    const formatEventsAll = (events) => {
        if (viewType === "grid") {
            return formatEventsCalendar(events);
        }
        return formatEvents(events);
    };

    const formatTasksAll = (events) => {
        if (viewType === "grid") {
            return formatTasksCalendar(events);
        }
        return formatTasks(events);
    };

    const onRefreshTask = () => {
        closeAddActivityModal(true);
        readCalendarItems();
    };

    useEffect(() => {
        if (viewType === "grid") {
            changeCalendarView(timeUnits);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewType, timeUnits]);

    useEffect(() => {
        const isDoneReading = !(
            isReadingTasks ||
            isReadingEvents ||
            isReadingSequenceTasks
        );
        if (isDoneReading) {
            const sqcs =
                sectionOptions.sequences &&
                viewType === "list" &&
                !areAllFiltersOff(options.sequences.type)
                    ? formatSequenceTasks(sequenceTasks)
                    : [];
            const tsks =
                sectionOptions.tasks && !areAllFiltersOff(options.tasks.type)
                    ? formatTasksAll(tasks)
                    : [];
            const evts = sectionOptions.events ? formatEventsAll(events) : [];
            setCalendarItems([...evts, ...tsks, ...sqcs]);
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events, tasks, sequenceTasks]);

    useEffect(() => {
        readCalendarItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionOptions, timeUnits, calendarDate, viewType, options]);

    useEffect(() => {
        dispatch(userActions.viewUserProfile(current_user.user_id));
        dispatch(companyActions.readSignaturePreferences());
        dispatch(companyActions.readSignatures());
    }, [current_user.user_id, dispatch]);

const getTaskEmailData = async (id) => {
    let email;
    await onReadTask(id).then((task) => {
        const taskBeingEdited = formatTaskBeingEdited(
            id,
            task
        );

        setSignatureType(taskBeingEdited?.signature);

        email = {
            subject: taskBeingEdited?.subject,
            body: taskBeingEdited?.body || "",
            contact_id: taskBeingEdited.contact_id,
            attachments:taskBeingEdited?.attachments
        }
        setEmailData(email); 
    });

    await onReadContact(email.contact_id).then((contact)=>{
        setEmailData((prevState) => {
            return {
                ...prevState,
                contactDetails: contact.count === 1 ? contact.data[0] : {}
            };
        });
    })
      
}

    const onMarkTaskAsComplete = async (id, task_type) => {
        setTaskCompleteInfo({id, task_type});
        if(task_type?.toLowerCase()==='email'){
        setCompleteConfirmationOpen(true);
        getTaskEmailData(id)
    }else if(task_type?.toLowerCase()==='call'){
        getTaskEmailData(id)
        setCallCompleteConfirmationOpen(true);
    }else{
        completeTask(id, task_type);
    }
    };

    const completeTask = async (id, task_type)=>{
         if(isNaN(id)) id = taskCompleteInfo.id;
         if(typeof task_type !== 'string') task_type=taskCompleteInfo.task_type;
         const values = taskGetValues();
        const { direction } = getTaskTypeSpecificPayload(
            getCorrectedTaskTypeName(task_type),
            values
        );

        await onCompleteTask(id, {
            direction,
            ...(task_type === "Call"
                ? { task_call_meta: getCallCompletionDetails(values) }
                : {}),
        });
        onRefreshTask();
        setCompleteConfirmationOpen(false);
    }

    const emailIntegrations = useSelector(
        (state) => state.oauth_integrations.integrations
    );

    const hasEmailIntegrations = emailIntegrations.length > 0;
    const [modal, setModal] = useState("");
    const [contactId, setContactId] = useState("");

    useEffect(()=>{
        dispatch(contactEmailActions.getContactEmails(contactId));      
        dispatch(contactsActions.readContacts({limit: 200})) 
       },[contactId,dispatch]);

    const getModal = () => {
        switch (modal) {
            case "email":
                return (
                    <SendEmailModal
                        open={modal === "email"}
                        onClose={() => {
                            setModal("");
                        }}
                        contact_id={contactId}
                    />
                );
            default:
                <React.Fragment />;
        }
    };

    const onTaskEmailNoIntegrationsContent = (contact, isContentOpen) => {
        return (
            <NewActivityForm
                readTimeline={() => {}}
                emailOnly={true}
                contact={contact}
                onClose={() => isContentOpen(false)}
            />
        );
    };

    const onSequenceTaskComplete = async (id) => {
        try {
            await dispatch(sequenceTaskActions.complete(id, { status: "completed" }));
            Notification.alert("Successfully completed sequence task!", STATUS_TYPES.SUCCESS);
            readCalendarItems();
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to complete sequence task", STATUS_TYPES.ERROR);
        }
    };

    const onCancelSequenceTask = async (id) => {
        try {
            await dispatch(sequenceTaskActions.complete(id, { status: "canceled" }));
            Notification.alert('Successfully canceled sequence task!', STATUS_TYPES.SUCCESS);
            readCalendarItems();
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to cancel sequence task!', STATUS_TYPES.ERROR);
        }
    }

    const [sequenceTaskBeingRescheduled, setSequenceTaskBeingRescheduled] = useState(null);
    return (
        <>
            <div className="CalendarView__Container">
                <LeftPanel
                    miniDate={miniDate}
                    handleMiniChange={handleMiniChange}
                    options={options}
                    allOptions={sectionOptions}
                    openAddActivityModal={openAddActivityModal}
                    addActivityAction={addActivityAction}
                    onChangeAllOptions={onChangeAllOptions}
                    onChangeOptions={onChangeOptions}
                    user_id={organizer_id}
                />
                <div>
                    <ToolBar
                        timeUnits={timeUnits}
                        setTimeUnits={setTimeUnits}
                        offset={offset}
                        setOffset={setOffset}
                        viewType={viewType}
                        setViewType={setViewType}
                        refreshCalendarDate={refreshCalendarDate}
                        fullCalendarApiFunction={fullCalendarApiFunction}
                        calendarDate={calendarDate}
                        setCalendarDate={setCalendarDate}
                        changeCalendarView={changeCalendarView}
                    />
                    {viewType === "grid" ? (
                        <CalendarGridView
                            calendarRef={calendarRef}
                            goToDay={goToDay}
                            viewMode={timeUnits}
                            events={calendarItems}
                            setAddActivityAction={setAddActivityAction}
                            resetEventFormValues={resetEventFormValues}
                            resetTaskFormValues={resetTaskFormValues}
                            onEventClick={(info) =>
                                onEventClick(info?.event?.id)
                            }
                        />
                    ) : (
                        <div
                            style={{
                                overflowY: "auto",
                                maxHeight: 750,
                                marginTop: 14,
                            }}
                        >
                            <CalendarListView
                                onEventClick={onEventClick}
                                onContactClick={onContactClick}
                                calendarDate={calendarDate}
                                setCalendarDate={setCalendarDate}
                                events={calendarItems}
                                timeUnits={timeUnits}
                                setTimeUnits={setTimeUnits}
                                viewType={viewType}
                                isReadingEvents={isLoading}
                                onTaskComplete={onMarkTaskAsComplete}
                                onTaskRemove={onDeleteTask}
                                hasEmailIntegrations={hasEmailIntegrations}
                                onTaskEmailNoIntegrationsContent={
                                    onTaskEmailNoIntegrationsContent
                                }
                                onRefresh={onRefreshTask}
                                setModal={setModal}
                                setContactId={setContactId}
                                onSequenceTaskComplete={onSequenceTaskComplete}
                                onSequenceTaskRemove={onCancelSequenceTask}
                                onSequenceTaskReschedule={(id) => {setSequenceTaskBeingRescheduled(id)}}
                            />
                        </div>
                    )}
                </div>
            </div>
            <RescheduleSequenceModal
                open={sequenceTaskBeingRescheduled}
                sequenceTaskId={sequenceTaskBeingRescheduled}
                onClose={() => { setSequenceTaskBeingRescheduled(null) }}
                refresh={readCalendarItems}
            />
            <AddEventModal
                open={isAddActivityModalOpen("event")}
                onClose={() => closeAddActivityModal(false)}
                onSave={handleSubmitEvent(addEvent)}
                onEdit={handleSubmitEvent(onUpdate)}
                onDeleteEvent={onDelete}
                eventBeingEdited={eventBeingEdited}
                selected_users={[current_user]}
                isAllowedToModify={isAllowedToModify}
                control={control}
                watch={watch}
                isValid={isValid}
                isDirty={isDirty}
                trigger={trigger}
                setValue={setValue}
                getValues={getValues}
                reset={reset}
                resetField={resetField}
                setNotificationContactName={setNotificationContactName}
                displayAllContacts
            />
            <AddTaskModal
                open={isAddActivityModalOpen("task")}
                onClose={() => closeAddActivityModal(true)}
                state={state}
                control={taskControl}
                watch={taskWatch}
                isValid={taskIsValid}
                isDirty={taskIsDirty}
                trigger={taskTrigger}
                setValue={taskSetValue}
                getValues={taskGetValues}
                clearErrors={taskClearErrors}
                setError={taskSetError}
                reset={taskReset}
                taskBeingEdited={taskBeingEdited}
                loadTask={loadTask}
                addTask={addTask}
                setState={setState}
                onDelete={onDeleteTask}
                onUpdate={onUpdateTask}
                task_labels={task_labels}
                organizer_id={organizer_id}
                organizer={organizer}
                organizer_email={organizer_email}
                handleSubmit={handleSubmit}
                onRefresh={onRefreshTask}
                email={emailData}
                isAllowedToModify={isTaskAllowedToModify}
            />
            {getModal()}

            <TaskEmailConfirmationModal
                 open={completeConfirmationOpen}
                 onClose={()=>{setCompleteConfirmationOpen(false)}}
                 emailIntegration={emailIntegration}
                 onConfirm={completeTask}
				sendSignature={sendSignature}
                email={emailData}
                recordType={"account"}
                                        />

            <TaskCallConfirmationModal
                open={callCompleteConfirmationOpen}
                onClose={()=>{setCallCompleteConfirmationOpen(false)}}
                control={taskControl}
                {...taskBeingEdited}
                isAllowedToModify={true}
                getValues={getValues}
                taskCompleteInfo={taskCompleteInfo}
                onRefreshTask={onRefreshTask}
                onCompleteTask={onCompleteTask}
                id={taskCompleteInfo.id}
            />

        </>
    );
}
