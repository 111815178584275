import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Header } from 'semantic-ui-react';
import { TableWithHeader, STATUS_TYPES, Notification, DateTimeUtils } from 'dyl-components';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import NotificationsToolbar from './subcomponents/NotificationsToolbar';
import NotifcationsTable from './subcomponents/NotificationsTable';
import { useSelector, useDispatch } from 'react-redux';
import notificationActions from 'actions/notifications';
import scheduleActions from 'actions/schedule';

import BulkActionsProvider, { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import NotificationsTabs from './subcomponents/Tabs';
import NotificationPopupModals from 'shared/modals/NotificationPopUpModals';
import SendEmailModal from 'shared/modals/SendEmailModal';
import AddTaskModal from 'shared/modals/AddTaskModal';
import AddEventModal from 'shared/modals/AddEventModal';
import NotificationRescheduleSnoozeModal from 'shared/modals/NotificationRescheduleSnoozeModal';
import { StringUtils } from 'utils';


import './index.scss';

const Notfications = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const { pathname } = useLocation();

    const tab = pathname.split("/")[1];
    const subRoute = tab !== "notifications" ? `/${tab}` : "";
    
    const searchQuery = params.get('search') || '';
    const [search, setSearch] = useState(searchQuery);
    const [isSnoozeModalOpen, setIsSnoozeModalOpen] = useState(false);
    const [notificationId, setNotificationId] = useState(null);

    const { user_id, schedule } = useSelector(state => ({
        user_id: state.auth.user_id,
        schedule: state.schedule,
    }));

    const onChangeSearch = (_, { value }) => {
        setSearch(value);
    }
    const cancelFunction = () => {
        setSearch("");
        const query = new URLSearchParams(params);
        query.delete('search');
        const query_string = query.toString();
        navigate(`${subRoute}/notifications${query_string ? `?${query_string}` : ''}`,);
    }

    const onToggleSnoozeModal = (notificationId) => {
        setNotificationId(notificationId);
        setIsSnoozeModalOpen(prevState => (!prevState));
    }

    const [, setSelectedNotifications, , setAreNotificationsInAllPagesSelected] = useContext(BulkActionsContext);

    const [filters, setFilters] = useState({
        type: params.get('type')?.split(',')
    })

    const onFilter = async (_, { name, value }) => {
        setFilters({
            ...filters,
            [name]: value
        });
    }

    const readNotifications = (params) => {
        const queryParameters = {
            page: 1,
            limit: 25,
            status: tab === 'notifications' ? 'all' : tab,
            ...Object.fromEntries(params)
        }
        dispatch(notificationActions.onReadNotificationHub(queryParameters));
    }

    const onPageChange = (_, { activePage }) => {
        const query = new URLSearchParams(params);
        query.set('page', activePage);
        const query_string = query.toString();
        navigate(`${subRoute}/notifications${query_string ? `?${query_string}` : ''}`);
    }

    const onSearchSubmit = (value) => {
        const query = new URLSearchParams(params);
        query.set('search', value.trim());
        query.set('page', 1);
        const query_string = query.toString();
        navigate(`${subRoute}/notifications${query_string ? `?${query_string}` : ''}`);
    }

    const remove = async (notification_id) => {
        try {
            await dispatch(notificationActions.onDeleteNotification(notification_id))
            Notification.alert('Deleted successfully', STATUS_TYPES.SUCCESS);
            readNotifications(params); 
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to delete notification', STATUS_TYPES.ERROR);
        }
    }

    const archive = async (notification_id, archive) => {
        try {
            await dispatch(notificationActions.onArchiveNotification(notification_id, {}, { archive }))
            Notification.alert(`${archive ? "Archived" : "Unarchived"}  successfully`, STATUS_TYPES.SUCCESS);
            readNotifications(params); 
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to ${archive ? "archive" : "unarchive"} notification`, STATUS_TYPES.ERROR);
        }
    }

    const markRead = async (notification_id, read) => {
        try {
            await dispatch(notificationActions.markReadNotification(notification_id, {}, { read }))
            Notification.alert(`Marked successfully ${read ? "read" : "unread"}`, STATUS_TYPES.SUCCESS);
            readNotifications(params); 
        } catch (e) {
            console.log(e);
            Notification.alert(`Failed to mark notification ${read ? "read" : "unread"}`, STATUS_TYPES.ERROR);
        }
    }

    const readSchedule = useCallback(() => {
        return dispatch(scheduleActions.readSchedule());
    }, [dispatch]);

    const generateSnooseTs = (ts) => {
        if(ts === 0){
            for (let x = 1; schedule?.days.length !== 0; x++) {
                if (x === 7) { x = -1; continue; }
                const day = schedule?.days[x];
                    if((StringUtils.capitalize(DateTimeUtils.DAYS[day.day]) ===  DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DAY_LONG_FORMAT)) && day.active){
                        const endDate = DateTimeUtils.convertSecondsToTime(day.seconds_end, DateTimeUtils.TIME_FORMAT);
                        if((DateTimeUtils.getDifference(DateTimeUtils.getCurrentDate(DateTimeUtils.TIME_FORMAT), endDate, 'minutes', DateTimeUtils.TIME_FORMAT) < 0)){
                            const formatEndDate = DateTimeUtils.convertSecondsToTime(day.seconds_end, DateTimeUtils.TIME_FORMAT);
                            return DateTimeUtils.getUnixTime(`${DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT)} ${formatEndDate}`, DateTimeUtils.WORD_DATETIME_FORMAT);
                        }
                    }
                if (x === 0) break;
            }
            return DateTimeUtils.getUnixTime(`${DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATE_FORMAT)} 11:59 pm`, DateTimeUtils.WORD_DATETIME_FORMAT);
        } 
        return DateTimeUtils.getTimeUnitsFromToday(DateTimeUtils.getCurrentDate(DateTimeUtils.WORD_DATETIME_FORMAT), ts, 'hour', 'addition', DateTimeUtils.WORD_DATETIME_FORMAT, true);
    }

    const SNOOZED_OPTIONS = [
        {
            id: 1,
            text: '1 hour from now',
            ts: generateSnooseTs(1)
        },
        {
            id: 2,
            text: 'End of day',
            ts: generateSnooseTs(0)
        },
        {
            id: 3,
            text: 'Tomorrow',
            ts: generateSnooseTs(24)
        },
        {
            id: 4,
            text: 'Next week',
            ts: generateSnooseTs(168)
        }
    ];

    const snooze = async (notification_id, ts) => {
        try {
            await dispatch(notificationActions.snoozeNotification(notification_id, { snooze_ts: ts }));
            Notification.alert('Snoozed successfully', STATUS_TYPES.SUCCESS);
            readNotifications(params); 
        } catch (e) {
            console.log(e);
            Notification.alert('Failed to snooze notification', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        readNotifications(params);
        setSelectedNotifications([]);
        setAreNotificationsInAllPagesSelected(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, tab]);

    useEffect(() => {
        const query = new URLSearchParams(params);
        query.set('page', 1);
        if (search.trim()) {
            query.set('search', search);
        } else {
            query.delete('search');
        }
        const { notification_type } = filters;
        if (notification_type?.length > 0) {
            query.set('notification_type', notification_type.join(','));
        } else {
            query.delete('notification_type');
        }
        const query_string = query.toString();
        navigate(`${subRoute}/notifications${query_string ? `?${query_string}` : ''}`,);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const {
        onToggleModal,
        taskModalOpen,
        setTaskModalOpen,
        control,
        watch,
        isValid,
        isDirty,
        trigger,
        setValue,
        getValues,
        reset,
        isAllowedToModify,
        eventBeingEdited,
        handleSubmitEvent,
        resetField,
        taskControl,
        taskWatch,
        taskIsValid,
        taskIsDirty,
        taskTrigger,
        taskSetValue,
        taskGetValues,
        taskClearErrors,
        taskSetError,
        taskReset,
        taskBeingEdited,
        loadTask,
        addTask,
        state,
        setState,
        onDeleteTask,
        onUpdateTask,
        handleSubmit,
        task_labels,
        organizer_id,
        organizer,
        organizer_email,
        eventModalOpen,
        emailModalOpen,
        emailData,
        person_id,
        onDelete,
        onUpdate,
        setEventModalOpen,
        onOpenNotification,
        current_user,
        setNotificationContactName
    } = NotificationPopupModals();

    return (
        <>
            <div className='NotificationsPage'>
                <Header className='NotificationsPage__PageHeader'>
                    <Header.Content>Notifications</Header.Content>
                </Header>
                <TableWithHeader
                    header={(
                        <>
                            <NotificationsTabs />
                            <NotificationsToolbar
                                search={searchQuery}
                                onChangeSearch={onChangeSearch}
                                onSearchSubmit={onSearchSubmit}
                                cancelFunction={cancelFunction}
                                user_id={user_id}
                            />
                        </>
                    )}
                    table={(
                        <NotifcationsTable
                            onFilter={onFilter}
                            onPageChange={onPageChange}
                            onOpenNotification={onOpenNotification}
                            subRoute={subRoute}
                            tab={tab}
                            onToggleSnoozeModal={onToggleSnoozeModal}
                            snoozedOptions={SNOOZED_OPTIONS}
                            remove={remove}
                            archive={archive}
                            markRead={markRead}
                            snooze={snooze}
                            readSchedule={readSchedule}
                            isReadingSchedule={schedule.isReadingSchedule}
                        />
                    )}
                />
            </div>
            <SendEmailModal
                open={emailModalOpen}
                onClose={() => { if(emailModalOpen){onToggleModal("email", 0)} }}
                contact_id={person_id}
            />
            <AddTaskModal
                open={taskModalOpen}
                onClose={() => setTaskModalOpen(false)}
                state={state}
                control={taskControl}
                watch={taskWatch}
                isValid={taskIsValid}
                isDirty={taskIsDirty}
                trigger={taskTrigger}
                setValue={taskSetValue}
                getValues={taskGetValues}
                clearErrors={taskClearErrors}
                setError={taskSetError}
                reset={taskReset}
                taskBeingEdited={taskBeingEdited}
                loadTask={loadTask}
                addTask={addTask}
                setState={setState}
                onDelete={onDeleteTask}
                onUpdate={onUpdateTask}
                task_labels={task_labels}
                organizer_id={organizer_id}
                organizer={organizer}
                organizer_email={organizer_email}
                handleSubmit={handleSubmit}
                onRefresh={() => {
                    setTaskModalOpen(false);
                    readNotifications(params);
                }}
                email={emailData} 
            />
            <AddEventModal
                open={eventModalOpen}
                onClose={() => setEventModalOpen(false)}
                onEdit={handleSubmitEvent(onUpdate)}
                onDeleteEvent={onDelete}
                eventBeingEdited={eventBeingEdited}
                selected_users={[current_user]}
                isAllowedToModify={isAllowedToModify}
                control={control}
                watch={watch}
                isValid={isValid}
                isDirty={isDirty}
                trigger={trigger}
                setValue={setValue}
                getValues={getValues}
                reset={reset}
                resetField={resetField}
                setNotificationContactName={setNotificationContactName}
                displayAllContacts
            />
            <NotificationRescheduleSnoozeModal 
                notification_id={notificationId}
                readNotifications={readNotifications}
                open={isSnoozeModalOpen}
                onClose={onToggleSnoozeModal}
            />
        </>
    );
}

export default function ContactsContainer() {
    return (
        <BulkActionsProvider>
            <Notfications />
        </BulkActionsProvider>
    )
};
