import React from 'react';
import { Form, Header, Segment } from 'semantic-ui-react';
import { ActiveFields } from 'dyl-components';
import { STATES } from 'shared/constants/STATES';


const WebformPreview = ({
    form_background_color,
    use_logo,
    logo,
    form_display_name,
    activeFields
}) => (
    <Segment style={{ backgroundColor: form_background_color }} padded='very'>
        <React.Fragment>
            {use_logo && (
                <span
                dangerouslySetInnerHTML={{ __html: `<img width="150px" class="WebformPreview__logo WebformPreview__logo--company" src="${logo}" onerror="this.style.display='none'" />`}}
                ></span>
            )}
            <Header>{form_display_name || 'Contact Us'}</Header>
            <Form>
                <ActiveFields
                    activeFields={activeFields}
                    preview
                    states={STATES}
                />
                <Segment basic textAlign='center'>
                    <Form.Button primary>Submit</Form.Button>
                </Segment>
            </Form>
            <Segment textAlign='center' basic>
                <Header as='h6'> This form is powered by <span style={{ color: '#2979ff' }}>DYL</span>.</Header>
            </Segment>
        </React.Fragment>
    </Segment>
);

export default WebformPreview;
