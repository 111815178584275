import React from 'react';
import { Grid } from 'semantic-ui-react';

const TaskTypeHeader = () => (
    <Grid verticalAlign='middle' columns='equal'>
        <Grid.Column width={2}>
            Task Types
        </Grid.Column>
    </Grid>
)

export default TaskTypeHeader;
