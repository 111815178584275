import React from "react";
import { ClearableCheckboxDropdown, Table } from "dyl-components";
import { Table as SemanticTable } from "semantic-ui-react";

import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SelectAllCheckbox from "shared/SelectAllCheckbox";

export const ContactsHeader = () => {
    const { count, contacts = [] } = useSelector((state) => {
        return {
            count: state.campaign_contacts.count,
            contacts: state.campaign_contacts.contacts.map(
                (campaign_contact) => campaign_contact.contact
            ),
        };
    });

    const [params] = useSearchParams();
    const navigate = useNavigate();
    const { id } = useParams();
    const routeRoot = `/campaigns/${id}/contacts`;

    const onFilterByConverted = (filters) => {
        const query = new URLSearchParams(params);
        const noFiltersInPlace = filters.length === 0;
        query.set("page", 1);
        if (noFiltersInPlace) {
            query.delete("converted");
            const query_string = query.toString();
            navigate(`${routeRoot}${query_string ? `?${query_string}` : ""}`);
        } else {
            query.set("converted", filters);
            const query_string = query.toString();
            navigate(`${routeRoot}${query_string ? `?${query_string}` : ""}`);
        }
    };

    const onFilterByStatus = (filters) => {
        const query = new URLSearchParams(params);
        const noFiltersInPlace = filters.length === 0;
        query.set("page", 1);
        if (noFiltersInPlace) {
            query.delete("status");
            const query_string = query.toString();
            navigate(`${routeRoot}${query_string ? `?${query_string}` : ""}`);
        } else {
            query.set("status", filters);
            const query_string = query.toString();
            navigate(`${routeRoot}${query_string ? `?${query_string}` : ""}`);
        }
    }

    return (
        <Table.Row>
            <SemanticTable.HeaderCell>
                <SelectAllCheckbox
                    count={count}
                    data={contacts}
                    type={"contacts"}
                    idProperty={"id"}
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>
                Contact Name
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>
                <ClearableCheckboxDropdown
                    text="Campaign Converted"
                    name="selectedStatus"
                    onChange={(_, { value }) => {
                        onFilterByConverted(value);
                    }}
                    onRead={() => {}}
                    value={params.get("converted")?.split(",")}
                    options={[
                        { key: "yes", value: "yes", text: "Yes" },
                        { key: "no", value: "no", text: "No" },
                    ]}
                    closeOnLoad
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>
                Call Result
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>
                Text Result
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>
                Opt Out
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>
                <ClearableCheckboxDropdown
                    text="Status"
                    name="status"
                    onChange={(_, { value }) => {
                        onFilterByStatus(value);
                    }}
                    onRead={() => {}}
                    value={params.get("status")?.split(",")}
                    options={[
                        { key: "active", value: "active", text: "Active" },
                        { key: "inactive", value: "inactive", text: "Inactive" },
                    ]}
                    closeOnLoad
                />
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>
                Attributed Revenue
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>
                Actions
            </SemanticTable.HeaderCell>
        </Table.Row>
    );
};
