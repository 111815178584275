import ACTION_NAMES from "actions/ACTION_NAMES";
import { generateCreateActionCreator, generateReadOneActionCreator } from "actions/crudActionCreators";
import routes from "actions/routes";

export default {
    getAccountTerm: generateReadOneActionCreator(routes.API_SUBSCRIPTION, ACTION_NAMES.ACCOUNT_TERM),
    getAllProducts: generateReadOneActionCreator(routes.API_SUBSCRIPTION, ACTION_NAMES.SUBSCRIPTION, 'products'),
    modifySubscription: generateCreateActionCreator(routes.API_SUBSCRIPTION, ACTION_NAMES.SUBSCRIPTION, () => {}, () => {}, 'products'),
    getNextInvoice: generateReadOneActionCreator(routes.API_SUBSCRIPTION, ACTION_NAMES.NEXT_INVOICE, 'next_invoice'),
    getProducts: generateReadOneActionCreator(routes.API_SUBSCRIPTION, ACTION_NAMES.CURRENT_SUBSCRIPTION, 'current_products')
}
