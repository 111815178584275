import { yup } from "dyl-components";

export const checkoutSchema = {
    invoice_name: yup.string().nullable(true),
    payment_method: yup.string().required("This field is required"),
    custom_ach: yup.string().when("payment_method", {
        is: "custom-ach",
        then: (schema) => schema.required("This field is required"),
    }),
    term: yup.string().required("This field is required")
}
