import React from "react";
import {
    Notification,
    STATUS_TYPES,
    DateTimeInput,
    yup,
    generateResolver,
    VALIDATORS
} from "dyl-components";
import {
    Button,
    Form,
    Segment,
    Grid,
    TextArea,
    Header,
    Icon
} from "semantic-ui-react";

import { useDispatch, useSelector } from "react-redux";
import { DateTimeUtils } from "dyl-components";

import selfReportActions from "actions/self_report";
import "./index.scss";
import { Controller, useForm } from "react-hook-form";
import { Link } from 'react-router-dom';

const TYPE_OPTIONS = [
    {
        key: "call",
        value: "call",
        text: "Call",
    },
    {
        key: "text",
        value: "text",
        text: "Text",
    },
    {
        key: "email",
        value: "email",
        text: "Email",
    },
];

const DIRECTION_OPTIONS = [
    {
        key: "outbound",
        value: "outbound",
        text: "Outbound",
    },
    {
        key: "inbound",
        value: "inbound",
        text: "Inbound",
    },
];

const STATUS_OPTIONS = [
    {
        key: "reached",
        value: "reached",
        text: "Reached",
    },
    {
        key: "not available",
        value: "not available",
        text: "Not Available",
    },
    {
        key: "missed",
        value: "missed",
        text: "Missed",
    },
    {
        key: "voicemail",
        value: "voicemail",
        text: "Voicemail",
    },
    {
        key: "do not call",
        value: "do not call",
        text: "Do not Call",
    },
];

const NewActivityForm = ({onClose, isCreatingEvent, emailOnly, contact, fromEmail, isContact}) => {
    const { control, watch, reset, handleSubmit, formState: { isValid, isDirty } } = useForm({
        mode: 'onChange',
        defaultValues: {
            activityType: emailOnly || fromEmail ? "email" : "call",
            date: "",
            time: "",
            numberOfHours: "",
            numberOfMinutes: "",
            direction: "outbound",
            result: "reached",
            subject: "",
            body: "",
            note: ""
        },
        resolver: generateResolver({
            date: yup.string().required("This field is required"),
            time: yup.string().required("This field is required"), 
            numberOfHours: yup.string().only_numbers(),
            numberOfMinutes: yup.string().only_numbers(),
            note: VALIDATORS.NOTE()
        })
    })

    const activityTypeWatch = watch("activityType");

    const dispatch = useDispatch();

    const {
        isReading,
        contact_id,
        from,
        assigned_user,
        mainEmail,
        mainPhone,
        account_id 
    } = useSelector((state) => {
        const { first_name = "", last_name = "" } = state.users.userProfile;
        let mainPhone = { phone: "No main phone" };

        if (state.contact.phone) {
            mainPhone = state.contact.phone.find((item) => item.main);

            if (!mainPhone) {
                mainPhone = state.contact.phone[0] || { phone: "No main phone" };
            }
        }

        let mainEmail = state.contact.email.find((item) => item.main);

        if (!mainEmail) {
            mainEmail = state.contact.email[0] || { email: "No main email" };
        }
        return {
            isCreatingActivity:
                state.self_report.isLoggingCall ||
                state.self_report.isLoggingText ||
                state.self_report.isLoggingEmail,
            isReading: state.users.isReadingUsers,
            contact_id: Number(state.contact.contact_id),
            from: `${first_name} ${last_name}`,
            assigned_user: state.auth.user_id,
            mainEmail: mainEmail.email,
            mainPhone: mainPhone.phone,
            account_id: state.contact.account_id
        };
    });

    const addActivity = (payload, type) => {
        const { user_id, occurred, direction, notes, contact_id, from, to } = payload;
        const commonActivityProps = {
            user_id,
            occurred,
            direction,
            notes,
            contact_id,
            ...(account_id !== 0 ? {account_id} : {}),
            source: from,
            destination: to,
        };

        switch (type) {
            case "call":
                const { result, length } = payload;
                return dispatch(
                    selfReportActions.addCallActivity({
                        ...commonActivityProps,
                        result,
                        length,
                    })
                );
            case "text":
                const { notes } = payload;
                return dispatch(
                    selfReportActions.addTextActivity({
                        ...commonActivityProps,
                        messages: notes,
                    })
                );
            case "email":
                const { subject, body } = payload;
                return dispatch(
                    selfReportActions.addEmailActivity({
                        ...commonActivityProps,
                        subject,
                        body,
                    })
                );
            default:
                Notification.alert(
                    `Failed to add activity of type ${type}`,
                    STATUS_TYPES.ERROR,
                    true
                );
                return Promise.reject();
        }
    }

    const readActivityLog = (contact_id, account_id, isContact) => {
        dispatch(
            selfReportActions.readActivity({
                ...(isContact ? {contact_id} : {}),
                ...(!isContact ? {account_id} : {}),
                occurred: DateTimeUtils.getCurrentDate(null, true, false),
            })
        );
    }

    const getMainEmail = (contact) => {
        if (contact.email && contact.email.email) {
            return contact.email.email;
        }
        else if (contact.email){
            return contact.email;
        }
        return "No main email"
    }

    const onAdd = ({ activityType, date, time, numberOfHours, numberOfMinutes, direction, result, subject, body, note }) => {
        const email = emailOnly ? getMainEmail(contact) : mainEmail
        const occurred = DateTimeUtils.getUnixTime(
            `${date} ${time}`,
            DateTimeUtils.DATETIME_FORMAT
        );
        const payload = {
            user_id: assigned_user,
            occurred,
            direction,
            notes: note,
            contact_id: emailOnly ? contact.id : contact_id,
            from,
            to: activityType === "email" ? email : mainPhone,
            result,
            length: DateTimeUtils.convertTimeToSeconds(
                `${numberOfHours || 0}:${numberOfMinutes || 0}`,
                "HH:mm"
            ),
            subject,
            body,
        };
        addActivity(payload, activityType)
            .then(() => {
                Notification.alert(
                    "Successfully added activity!",
                    STATUS_TYPES.SUCCESS,
                    true
                );
                onClear();
                readActivityLog(emailOnly ? contact.id : contact_id, account_id, isContact);
                onClose();
            })
            .catch(() => {
                Notification.alert(
                    "Failed to add activity",
                    STATUS_TYPES.ERROR,
                    true
                );
            });
    };

    const onClear = () => {
        reset();
    };

    const getCurrentDate = () => {
        return DateTimeUtils.getCurrentDate();
    }

    const handleClose = () => {
        onClear();
        onClose();
    };
    return (
        <Segment
            className="ActivityFormContainer"
            loading={isCreatingEvent || isReading}
        >
            <Form>
                <Grid stackable className="ActivityFormBody">
                    <Grid.Row className="ActivityFormBody__RowHeader">
                        <Grid.Column>
                            <Header className="ActivityForm__Header">
                                Log Activity
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="ActivityFormBody__Row">
                        <Grid.Column>
                            <Controller
                                name="activityType"
                                control={control}
                                render={({ field: { value, name, onChange } }) => (
                                    <Form.Select
                                        className="ActivityForm__typeField"
                                        label='Activity Type'
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                        placeholder='Type'
                                        selectOnBlur={false}
                                        options={TYPE_OPTIONS}
                                        disabled={emailOnly}
                                        required
                                        fluid
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="equal" className="ActivityFormBody__Row">
                        <Grid.Column>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field: { value: dateValue, onChange: onChangeDate } }) => (
                                    <Controller
                                        name="time"
                                        control={control}
                                        render={({ field: { value: timeValue, onChange: onChangeTime } }) => (
                                            <Form.Field
                                                control={DateTimeInput}
                                                dateFormat={"MM-DD-YYYY"}
                                                dateName="date"
                                                minDate="01-01-1970"
                                                maxDate={getCurrentDate()}
                                                date={dateValue}
                                                timeName="time"
                                                time={timeValue}
                                                onChange={(_, { name, value }) => {
                                                    if (name === "date") {
                                                        onChangeDate(value);
                                                    } else {
                                                        onChangeTime(value);
                                                    }
                                                }}
                                                style={{ left: 80 }}
                                                label="Date"
                                                required
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {activityTypeWatch === "call" && (
                        <Grid.Row columns="equal" className="ActivityFormBody__Row">
                            <Grid.Column>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            width={8}
                                            className="ActivityFormBody__LeftCol"
                                        >
                                            <Controller
                                                name="numberOfHours"
                                                control={control}
                                                render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                                                    <Form.Input
                                                        label="Duration"
                                                        value={value}
                                                        name={name}
                                                        onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                                        placeholder="Hour"
                                                        style={{ width: "100%" }}
                                                        error={error?.message}
                                                    />
                                                )}
                                            />
                                        </Grid.Column>
                                        <Grid.Column
                                            width={8}
                                            className="ActivityFormBody__RightCol--minDuration"
                                        >
                                            <Controller
                                                name="numberOfMinutes"
                                                control={control}
                                                render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                                                    <Form.Input
                                                        value={value}
                                                        name={name}
                                                        onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                                        placeholder="Min"
                                                        style={{ width: "100%" }}
                                                        error={error?.message}
                                                    />
                                                )}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {activityTypeWatch !== "call" && (
                        <Grid.Row columns="equal" className="ActivityFormBody__Row">
                            <Grid.Column>
                                <Controller
                                    name="direction"
                                    control={control}
                                    render={({ field: { value, name, onChange } }) => (
                                        <Form.Select
                                            label='Direction'
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                            placeholder='Activity'
                                            options={DIRECTION_OPTIONS}
                                            required
                                            fluid
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {activityTypeWatch === "call" && (
                        <Grid.Row className="ActivityFormBody__Row">
                            <Grid.Column
                                width={8}
                                className="ActivityFormBody__LeftCol"
                            >
                                <Controller
                                    name="direction"
                                    control={control}
                                    render={({ field: { value, name, onChange } }) => (
                                        <Form.Select
                                            label='Direction'
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                            placeholder='Activity'
                                            options={DIRECTION_OPTIONS}
                                            required
                                            fluid
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column
                                width={8}
                                className="ActivityFormBody__RightCol"
                            >
                                <Controller
                                    name="result"
                                    control={control}
                                    render={({ field: { value, name, onChange } }) => (
                                        <Form.Select
                                            label='Status'
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                            placeholder='Status'
                                            options={STATUS_OPTIONS}
                                            fluid
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {activityTypeWatch === "email" && (
                        <Grid.Row className="ActivityFormBody__Row">
                            <Grid.Column>
                                <Controller
                                    name="subject"
                                    control={control}
                                    render={({ field: { value, name, onChange } }) => (
                                        <Form.Input
                                            label="Subject"
                                            value={value}
                                            name={name}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                            placeholder="Type Subject"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {activityTypeWatch === "email" && (
                        <Grid.Row
                            columns="equal"
                            className="ActivityFormBody__TextAreaRow"
                        >
                            <Grid.Column>
                                <Controller
                                    name="body"
                                    control={control}
                                    render={({ field: { value, name, onChange } }) => (
                                        <Form.Field
                                            className="ActivityFormBody__textArea"
                                            control={TextArea}
                                            label="Body"
                                            value={value}
                                            name={name}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                            placeholder="Type Body"
                                            rows={4}
                                            size="tiny"
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row
                        columns="equal"
                        className="ActivityFormBody__TextAreaRow"
                    >
                        <Grid.Column>
                            <Controller
                                name="note"
                                control={control}
                                render={({ field: { value, name, onChange }, fieldState: { error } }) => (
                                    <Form.Field
                                        className="ActivityFormBody__textArea"
                                        control={TextArea}
                                        label="Note"
                                        value={value}
                                        name={name}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }); }}
                                        placeholder="Type Note"
                                        rows={4}
                                        size="tiny"
                                        error={error?.message}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {emailOnly && (
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Header as='h5'>
                                    Go to <Link to={`/settings/users/${assigned_user}/integration`}><Icon name='cog' style={{color: 'primary', fontSize: 15, margin: 0, verticalAlign:'center'}}/>Settings</Link> to add an email integration
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <Grid.Column
                            className="ActivityForm__Row"
                            floated="right"
                            width={10}
                        >
                            <div className="ActivityFormBody__Buttons">
                                <Button
                                    className="ActivityFormBody__CancelButton"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    disabled={!isValid || !isDirty}
                                    onClick={handleSubmit(onAdd)}
                                    className="ActivityFormBody__SaveButton"
                                >
                                    Save
                                </Button>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </Segment>
    );
};

export default NewActivityForm;
